import { makeStyles } from "@mui/styles";
import { Theme } from "tm-controls";

export const CheckboxStyles = makeStyles((theme: Theme) => ({
    labelRoot: {
        marginLeft: "-5px !important",
    },
    pointerEventsAuto: {
        pointerEvents: "auto"
    },
    pointerEventsNone: {
        pointerEvents: "none"
    },
    labelText: {
        paddingLeft: "8px",
    }
}));
