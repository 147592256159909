import { loadRecaptchaScripts, readGoogleRecaptchaToken, RecaptchaInfo } from "data";

export interface RecaptchaControllerInterface {
    initializeRecaptcha(): Promise<boolean>;    
    getToken(action: string): Promise<string>;
    isActive:boolean|undefined;
}

export class RecaptchaController implements RecaptchaControllerInterface {
    constructor(private recaptchaInfo: RecaptchaInfo) {
    }
    
    private _isActive: boolean|undefined = undefined;
    public get isActive(): boolean | undefined {
        return this._isActive;
    }

    private set isActive(active: boolean | undefined) {
        this._isActive = active;
    }

    private initializeRecaptchaPromise: Promise<boolean> | null = null;
    initializeRecaptcha(): Promise<boolean> {
        if (this.initializeRecaptchaPromise === null) {
            this.initializeRecaptchaPromise = new Promise<boolean>((resolve: (response: boolean) => void, reject: any) => {
                if (
                    this.recaptchaInfo &&
                    this.recaptchaInfo.javaScriptUri &&
                    this.recaptchaInfo.siteKey
                ) {
                    loadRecaptchaScripts(this.recaptchaInfo.javaScriptUri)
                        .then((scriptLoaded: boolean) => {
                            this.getRecaptchaToken = (action: string) => {
                                return readGoogleRecaptchaToken(this.recaptchaInfo.siteKey, action);
                            };
                            this.isActive = scriptLoaded;
                            resolve(scriptLoaded);
                        })
                        .catch((err: any) => {
                            reject();
                        })
                } else {
                    this.getRecaptchaToken = this.getDefaultToken;
                    this.isActive = false;
                    resolve(true);
                }
            })
        }
        return this.initializeRecaptchaPromise;
    }
    
    getToken(action: string): Promise<string> {
        return this.getRecaptchaToken(action);
    }
    
    private getDefaultToken = (action: string): Promise<string> => {
        return Promise.resolve("");
    }
    
    private getRecaptchaToken: (action: string) => Promise<string> = this.getDefaultToken;
}
