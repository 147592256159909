import React from 'react';

import { PreloaderProps } from "./preloader-props";
import { Delayer } from "components/controls";
import { ProgressCircle } from "tm-controls";

export const Preloader = ({delay, showPreloader, children}: PreloaderProps) => {
    if (showPreloader) {
        return (
            <Delayer
                delay={delay}
            >
                <ProgressCircle/>
            </Delayer>
        );
    } else {
        return (
            <>
                {children}
            </>
        );
    }
}
