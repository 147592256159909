import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";

export const LoadingContentStyles = makeStyles((theme: Theme) => ({
    root: {},
    progress: {
        position: "absolute",
        top: '0',
        left: '0',
        right: '0',
        bottom: '0',
        margin: 'auto',
    },
    text: {
        color: "transparent",
    },
}));
