import { Button, Typography } from '@mui/material';
import { Spacer } from "tm-controls";
import { InformationDialog } from "components/dialogs/information-dialog";
import React from 'react';

import { FormattedMessage } from "react-intl";

import { PasswordChangedConfirmationDialogProps } from "./password-changed-confirmation-dialog-props";
import { DesktopOnly } from 'components/controls';

export const PasswordChangedConfirmationDialog = ({ onClickProceed }: PasswordChangedConfirmationDialogProps) => {
	const dialogHeaderElement = (): JSX.Element => (
		<Typography data-testid="password-changed-confirmation-dialog-page-title" variant="h5">
			<FormattedMessage id="password.changed.confirmation.dialog.page.title"/>
		</Typography>
	);
	const dialogContentElement = (): JSX.Element => (
		<Typography data-testid="password-changed-confirmation-dialog-page-description" variant="subtitle2">
			<FormattedMessage
				id="password.changed.confirmation.dialog.page.message"
				values={{ nbsp: <>&nbsp;</> }}
			/>
		</Typography>
	);
	const dialogFooterElement = (): JSX.Element => (
		<>
			<DesktopOnly>
				<Spacer />
			</DesktopOnly>
			<Button
				data-testid="proceed-button"
				onClick={onClickProceed}
				variant="contained"
				color="primary"
			>
				<FormattedMessage
					id="password.changed.confirmation.dialog.page.proceed.button"
				/>
			</Button>
		</>
	);
	
	return (
		<InformationDialog
			informationTitleContainer={dialogHeaderElement()}
			informationContentContainer={dialogContentElement()}
			informationButtonContainer={dialogFooterElement()}
		/>
	);
}
