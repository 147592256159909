import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";

export const PageHeaderContainerStyles = makeStyles((theme: Theme) => ({
    logoContainer: {
        display: "flex",
        justifyContent: "center"
    },
    headerContainer: {
        padding: "0 0 32px 0"
    }
}));
