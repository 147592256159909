import axios, { AxiosError, AxiosResponse } from 'axios';
import { Action } from 'data';
import { HandledError } from 'data/handled-error';

export interface ServerAdapterInterface {
    doAction<T>(action: Action, params?: Object): Promise<AxiosResponse<T>>;
}

export class ServerAdapter implements ServerAdapterInterface {
    constructor(
        private onHttpError: (code?: number) => boolean = () => { return false; }
    ) {
    }

    async doAction<T>(action: Action,
        params?: Object
    ): Promise<AxiosResponse<T>> {
        let headers: any = {};

        if (action.accept) {
            headers["Accept"] = action.accept;
        }

        if (action.contentType) {
            headers["Content-Type"] = action.contentType;
        }

        if (action.recaptchaToken) {
            headers["GO-RC"] = action.recaptchaToken;
        }

        try {
            const response: AxiosResponse<T> = await axios(
                {
                    method: action.method,
                    url: action.href,
                    headers: headers,
                    data: params
                }
            );
            return response;
        } catch (error) {
            const err: AxiosError<T> = error as AxiosError<T>;
            if (this.onHttpError(err.response?.status)) {
                throw new HandledError(err);
            } else {
                throw error;
            }
        }
    }
}
