import { Checkbox as MuiCheckbox, FormControlLabel } from '@mui/material';
import { CheckboxProps } from "./checkbox-props";
import { CheckboxStyles } from "./checkbox-styles";

export const Checkbox = ({
                             checked,
                             color,
                             onChange,
                             disabled,
                             label,
                             labelNotClickable,
                             ...rest
                         }: CheckboxProps) => {
    const classes = CheckboxStyles();

    const checkBoxRoot = [disabled ? classes.pointerEventsNone : classes.pointerEventsAuto]
    const formControlLabelRoot = [classes.labelRoot, labelNotClickable ? classes.pointerEventsNone : classes.pointerEventsAuto];
    
    let oColor: "primary" | "secondary" | undefined = undefined;
    if (color === "primary") {
        oColor = "primary"
    } else if (color === "secondary") {
        oColor = "secondary"
    }
    
    let CheckboxItem = <MuiCheckbox
        {...rest}
        classes={{root: checkBoxRoot.join(' ')}}
        checked={checked}
        color={oColor}
        disabled={disabled}
        inputProps={{'aria-label': 'primary checkbox'}}
        size="small"
        onChange={onChange}
    />;
    
    let labelItem = <div className={classes.labelText}>{label}</div>
    
    if (label) {
        CheckboxItem =
            <FormControlLabel classes={{root: formControlLabelRoot.join(' ')}}
                              disabled={disabled}
                              control={CheckboxItem} label={labelItem}/>
    }
    
    return CheckboxItem;
}
