import { Typography } from "@mui/material";
import {
	GeneratedTokenConfirmationDialogProps
} from "components/dialogs/generated-token-confirmation-dialog/generated-token-confirmation-dialog-props";
import { InformationDialog } from "components/dialogs/information-dialog";
import React from "react";
import { FormattedMessage } from "react-intl";
import { Button, Spacer } from "tm-controls";
import { DesktopOnly } from 'components/controls';

export const GeneratedTokenConfirmationDialog = ({ onClickProceed }: GeneratedTokenConfirmationDialogProps) => {
	const dialogHeaderElement = (): JSX.Element => (
		<Typography data-testid="generated-token-confirmation-dialog-page-title" variant="h5">
			<FormattedMessage id="generated.token.confirmation.dialog.page.title"/>
		</Typography>
	);
	const dialogContentElement = (): JSX.Element => (
		<Typography data-testid="generated-token-confirmation-dialog-page-description" variant="subtitle2">
			<FormattedMessage id="generated.token.confirmation.dialog.page.message"
			                  values={{nbsp: <>&nbsp;</>}}/>
		</Typography>
	);
	const dialogFooterElement = (): JSX.Element => (
		<>
			<DesktopOnly>
				<Spacer />
			</DesktopOnly>
			<Button
				data-testid="proceed-button"
				onClick={onClickProceed}
				variant="Filled"
				color="primary"
			>
				<FormattedMessage
					id="generated.token.confirmation.dialog.page.proceed.button"
				/>
			</Button>
		</>
	);
	
	return (
		<InformationDialog
			informationTitleContainer={dialogHeaderElement()}
			informationContentContainer={dialogContentElement()}
			informationButtonContainer={dialogFooterElement()}
		/>
	);
}
