import React, { PropsWithChildren } from 'react';
import { PageContentContainerStyles } from "./page-content-container-styles";

export const PageContentContainer = ({children}: PropsWithChildren<{}>) => {
    const classes = PageContentContainerStyles();
    return (
        <div className={classes.pageContents}>
            {children}
        </div>
    );
}
