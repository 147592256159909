import React, { PropsWithChildren } from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';


export const DesktopOnly = ({ children }: PropsWithChildren<{}>) => {
    const theme = useTheme();
    const isDesktop = !useMediaQuery(theme.breakpoints.down('sm'));

    if (isDesktop)
        return (<>{children}</>);
    else
        return null;
}
