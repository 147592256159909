import React, { useEffect, useState } from 'react';

import { LoaderGuardProps } from './loader-guard-props';

import { Preloader } from "./preloader";

export const LoaderGuard = ({children, delay, loadingInitFunction}: LoaderGuardProps) => {
    const [error, setErrors] = useState(false);
    const [loading, setLoading] = useState(true);
    
    useEffect(() => {
        let isActive = true;
        loadingInitFunction
            .then(() => {
                if (isActive) {
                    setErrors(false);
                    setLoading(false);
                }
            })
            .catch(() => {
                if (isActive) {
                    setErrors(true);
                    setLoading(false);
                }
            });
        return () => {
            isActive = false;
        };
    }, [loadingInitFunction]);
    
    if (error) {
        return null;
    } else {
        return (
            <Preloader
                delay={delay}
                showPreloader={loading}>
                {
                    children
                }
            </Preloader>
        );
    }
}
