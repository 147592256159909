import { makeStyles } from "@mui/styles";
import { Theme } from "tm-controls";

export const SecondFactorAuthenticationFormStyles = makeStyles((theme: Theme) => ({
    form: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        alignSelf: "stretch",
        flexGrow: 0,
        gap: 16
    },
    textField: {
        flexBasis: "49%",
        flexShrink: 1
    },
    input: {
        textTransform: "uppercase"
    }
}));
