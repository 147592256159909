import {
	GeneratedTokenConfirmationDialog,
	PasswordChangedConfirmationDialog,
	WrongTokenDialog
} from "components/dialogs";
import { WrongTokenVariant } from "components/dialogs/wrong-token-dialog/wrong-token-dialog-props";
import { LoaderGuard } from "components/loader-guard";
import { UserForgotPasswordForm } from "components/pages/user-forgot-password-form";
import { DeviceUserListContext, RecaptchaContext, RecaptchaContextInterface } from "contexts";
import {
	ForgotPasswordRequestStatus,
	Reasons,
	ResetPasswordStatus,
	ServerAdapter,
	ServerAdapterInterface,
	TokenController,
	TokenControllerInterface,
	TokenInfoData,
	TokenInfoResponse,
	TokenStatus,
	UserController,
	UserControllerInterface,
	UserForgotPasswordData,
	UserForgotPasswordResponse,
	UserResetPasswordData,
	UserResetPasswordResponse
} from "data";
import { FormApi } from "final-form";
import React, { useContext, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Links } from "utils";
import { SetPasswordForm } from "components/pages/shared/set-password-form";
import { FatalErrorContext } from "contexts/fatal-error-context";
import { processUnhandledError } from "data/handled-error";

export const ResetPasswordPage = () => {
	const navigate = useNavigate();
	const intl = useIntl();
	const [searchParams] = useSearchParams();
	const fatalErrorContext = React.useContext(FatalErrorContext);
	const deviceUserListContext = React.useContext(DeviceUserListContext);

	const [redirectToHomePage, setRedirectToHomePage] = useState(false);
	const [showWrongTokenForm, setShowWrongTokenForm] = useState(false);
	const [showUserForgotPasswordForm, setShowUserForgotPasswordForm] = useState(false);
	const [showPasswordChangedConfirmationForm, setShowPasswordChangedConfirmationForm] = useState(false);
	const [showServerErrorNotification, setServerErrorNotification] = useState(false);
	const [showGeneratedTokenConfirmationForm, setShowGeneratedTokenConfirmationForm] = useState(false);
	const [token] = useState(searchParams.get("token"));
	
	let serverAdapter: ServerAdapterInterface = new ServerAdapter(fatalErrorContext.handleHttpError);
	const tokenController: TokenControllerInterface = new TokenController(serverAdapter);
	const userController: UserControllerInterface = new UserController(serverAdapter);
	const recaptchaContext: RecaptchaContextInterface = useContext(RecaptchaContext);

	const verifyTokenInfo = async (): Promise<void> => {
		try {
			const TokenInfo: string = "ApiTokenInfo";
			const recaptchaToken = await recaptchaContext.getToken(TokenInfo);
			const tokenInfoData: TokenInfoData = {
				token         : token,
				recaptchaToken: recaptchaToken
			}
			const response: TokenInfoResponse = await tokenController.getTokenInfo(tokenInfoData);
			if (response.status !== TokenStatus.Ok) {
				// display WrongTokenForm for MissingToken or WrongToken
				if (response.reasons.includes(Reasons.MissingToken) ||
					response.reasons.includes(Reasons.WrongToken))
				{
					setShowWrongTokenForm(true);
				} else {
					// display WrongTokenForm for other/unknown errors
					setShowWrongTokenForm(true);
				}
			}
		}
		catch (err: any) {
			processUnhandledError(() => {
				setShowWrongTokenForm(true);
			})(err);
		}
	}
	
	const initializeRecaptcha = async (): Promise<void> => {
		const response: boolean = await recaptchaContext.initializeRecaptcha();
		
		if (!response) {
			console.warn("Unable to initialize recaptcha");
		}
	}
	
	const initialize = async (): Promise<void> => {
		let promises: Promise<void>[] = [
			initializeRecaptcha().then(verifyTokenInfo)
		];
		await Promise.all(promises);
	}
	
	const [initializePromise] = useState<Promise<void>>(initialize)
	
	const WrongTokenForm = (): JSX.Element => {
		const handleClickClose = () => {
			setShowWrongTokenForm(false);
			navigate(Links.getBaseUrlLink());
		}
		
		const handleClickGenerateToken = () => {
			setShowWrongTokenForm(false);
			setShowUserForgotPasswordForm(true);
		}
		
		return (
			<WrongTokenDialog
				onClose={handleClickClose}
				onGenerateNewToken={handleClickGenerateToken}
				variant={WrongTokenVariant.ResetPassword}
			/>
		);
	}
	const PasswordChangedConfirmationForm = (): JSX.Element => {
		const handleClickProceed = () => {
			navigate(Links.getBaseUrlLink());
		}
		return (
			<PasswordChangedConfirmationDialog onClickProceed={handleClickProceed} />
		);
	}
	
	const keyPassword1: string = "password1";
	const keyPassword2: string = "password2";
	const keyUserEmail: string = "userEmail";
	const [disableActiveControls, setDisableActiveControls] = useState(false);
	const [showProgress, setShowProgress] = useState(false);
	
	const validationMessage = (messageId: string) => {
		return (
			<FormattedMessage
				id={messageId}
				values={{ nbsp: <>&nbsp;</> }}
			/>
		);
	}
	
	
	const submitForm = async (values: any): Promise<any> => {
		let password: string | undefined = values[keyPassword1];
		let password2: string | undefined = values[keyPassword2];
		
		setDisableActiveControls(true);
		setShowProgress(true);
		
		const enforceSavePassword = () => {
			window.history.replaceState(undefined, "");
		}

		let results: Reasons[] = [];
		try {
			const tokenInfo: string = "ApiUserResetPassword";
			const recaptchaToken = await recaptchaContext.getToken(tokenInfo);
			const resetData: UserResetPasswordData = {
				token         : token,
				password      : password,
				password2     : password2,
				recaptchaToken: recaptchaToken
			}
			const response: UserResetPasswordResponse = await userController.resetUserPassword(resetData);
			if (response.status === ResetPasswordStatus.Ok) {
				await deviceUserListContext.updateDeviceUserList();
				setDisableActiveControls(false);
				setShowProgress(false);
				enforceSavePassword();
				setShowPasswordChangedConfirmationForm(true);
			} else if (response.status === ResetPasswordStatus.Failed) {
				results = response.reasons;
				setDisableActiveControls(false);
				setShowProgress(false);
			}
		} catch (err: any) {
			processUnhandledError(() => setRedirectToHomePage(true))(err);
		}
		if (results.includes(Reasons.AppError)) {
			fatalErrorContext.setServerError();
		} else if (results.includes(Reasons.WrongToken) || results.includes(Reasons.MissingToken)) {
			setShowWrongTokenForm(true);
		} else {
			let pwd1: JSX.Element | undefined;
			let pwd2: JSX.Element | undefined;
			if (results.includes(Reasons.PasswordRequired)) {
				pwd1 = validationMessage("set.password.form.password1.required");
			} else if (results.includes(Reasons.WeakPassword)) {
				pwd1 = validationMessage("set.password.form.password1.weak");
			} else if (results.includes(Reasons.PasswordVerificationRequired)) {
				pwd2 = validationMessage("set.password.form.password2.required");
			} else if (results.includes(Reasons.PasswordsDoNotMatch)) {
				pwd2 = validationMessage("set.password.form.password2.match");
			}
			return {
				password1: pwd1,
				password2: pwd2
			}
		}
	}
	
	const ResetFormRoot = (): JSX.Element => {
		return (
			<LoaderGuard loadingInitFunction={initializePromise}>
				<SetPasswordForm
					handleSubmitForm={submitForm}
					disableActiveControls={disableActiveControls}
					showProgress={showProgress}
					keyPassword1={keyPassword1}
					keyPassword2={keyPassword2}
				/>
			</LoaderGuard>
		);
	}
	
	const handleForgotPasswordRequestSubmissionError = (err: any, formApi: FormApi) => {
		console.log("Failed to send user forgot password email with error: ", err);
		setServerErrorNotification(true);
		formApi.change(keyUserEmail, "");
	}
	const handleForgotPasswordSuccessRequest = () => {
		setDisableActiveControls(false);
		setShowProgress(false);
		setShowUserForgotPasswordForm(false);
		setShowGeneratedTokenConfirmationForm(true);
	}
	const handleForgotPasswordRequestFailed = () => {
		setDisableActiveControls(false);
		setShowProgress(false);
	}
	const submitUserForgotForm = async (values: any, formApi: FormApi): Promise<any> => {
		let userEmail: string | undefined = values[keyUserEmail];
		let results: Reasons[] = [];
		
		try {
			setDisableActiveControls(true);
			setShowProgress(true);
			
			const userForgotPassword = "ApiUserForgotPassword";
			const recaptchaToken: string = await recaptchaContext.getToken(userForgotPassword);
			const userData: UserForgotPasswordData = {
				email         : userEmail,
				recaptchaToken: recaptchaToken
			}
			const response: UserForgotPasswordResponse = await userController.processUserForgotPasswordRequest(userData)
			const status = response.status;
			if (status === ForgotPasswordRequestStatus.Ok) {
				handleForgotPasswordSuccessRequest();
			} else if (status === ForgotPasswordRequestStatus.Failed) {
				results = response.reasons;
				handleForgotPasswordRequestFailed();
			} else {
				handleForgotPasswordRequestSubmissionError(response.reasons, formApi);
			}
		}
		catch (err: any) {
			processUnhandledError((error) => { handleForgotPasswordRequestSubmissionError(error, formApi) })(err);
		}
		
		if (results.includes(Reasons.IncorrectEmail)) {
			return {
				userEmail: validationMessage("user.forgot.password.page.input.errorMessage.invalid.email")
			}
		} else if (results.includes(Reasons.MissingEmail)) {
			return {
				userEmail: validationMessage("user.forgot.password.page.input.errorMessage.validate.email")
			}
		}
	}
	const ForgotPasswordForm = (): JSX.Element => {
		const handleSignInButtonClick = () => {
			setShowUserForgotPasswordForm(false)
			navigate(Links.getBaseUrlLink());
		}
		
		const formTitleText: string = intl.formatMessage({id: "generated.token.forgot.password.page.title"});
		const formMessageText: string = intl.formatMessage({id: "generated.token.forgot.password.page.content"});
		const formCancelButtonText: string = intl.formatMessage({id: "generated.token.forgot.password.page.close.button"});
		const formProceedButtonText: string = intl.formatMessage({id: "generated.token.forgot.password.page.proceed.button"});
		
		return (
			<LoaderGuard loadingInitFunction={initializeRecaptcha()}>
				<UserForgotPasswordForm
					handleSignInButtonClick={handleSignInButtonClick}
					handleSubmitForm={submitUserForgotForm}
					keyUserEmail={keyUserEmail}
					disableActiveControls={disableActiveControls}
					showProgress={showProgress}
					showServerErrorNotification={showServerErrorNotification}
					formMessageText={formMessageText}
					formTitleText={formTitleText}
					cancelButtonText={formCancelButtonText}
					proceedButtonText={formProceedButtonText}
				/>
			</LoaderGuard>
		);
	}
	
	const GeneratedTokenConfirmationForm = (): JSX.Element => {
		const handleClickProceed = () => {
			setShowGeneratedTokenConfirmationForm(false);
			navigate(Links.getBaseUrlLink());
		}
		return (
			<GeneratedTokenConfirmationDialog onClickProceed={handleClickProceed}/>
		);
	}
	
	if (redirectToHomePage) {
		navigate(Links.getBaseUrlLink());
		return null;
	} else if (showWrongTokenForm) {
		return WrongTokenForm();
	} else if (showPasswordChangedConfirmationForm) {
		return PasswordChangedConfirmationForm();
	} else if (showUserForgotPasswordForm) {
		return ForgotPasswordForm();
	} else if (showGeneratedTokenConfirmationForm) {
		return GeneratedTokenConfirmationForm();
	} else {
		return ResetFormRoot();
	}
}
