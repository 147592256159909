export enum ActionMethod {
    Get = "GET",
    Post = "POST",
    Delete = "DELETE"
}

export enum ContentType {
    Json = "application/json"
}

export interface Action {
    href: string;
    accept?: ContentType;
    contentType?: ContentType;
    recaptchaToken?: string;
    method: ActionMethod;
}
