import { styled } from "@mui/material/styles";
import { useContext } from "react";
import { ellipses, ThemeContext } from "tm-controls";

export const usersListItemClasses = {
    userName: "userName",
    userEmail: "userEmail",
    root: "root",
    text: "text",
    pressed: "pressed"
}

export const UserItemIconContainer = styled('div')(({theme}) => {
    return {
        [`&.${usersListItemClasses.root}`]: {
            borderRadius: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "40px",
            height: "40px"
        },
        [`&.${usersListItemClasses.pressed}`]: {
            backgroundColor: theme.palette.outline?.main
        }
    }
});

export const UserItemRoot = styled('div')(() => ({
    [`&.${usersListItemClasses.root}`]: {
        display: "flex",
        flexDirection: "row",
        flexGrow: 1,
        alignItems: "center",
        height: "72px"
    }
}))

export const UserItemText = styled('div')(() => ({
    [`&.${usersListItemClasses.text}`]: {
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        paddingLeft: "16px",
        paddingRight: "16px"
    }
}))

export const UserNameRoot = styled('div')(() => {
    const { theme } = useContext(ThemeContext);
    return {
        [`&.${usersListItemClasses.userName}`]: {
            fontFamily: "Roboto",
            fontWeight: "400",
            fontSize: "16px",
            lineHeight: "24px",
            letterSpacing: "0.5px",
            color: theme.text?.primary,
            maxWidth: "358px",
            ...ellipses
        }
    }
});

export const UserEmailRoot = styled('div')(() => {
    const { theme } = useContext(ThemeContext);
    return {
        [`&.${usersListItemClasses.userEmail}`]: {
            fontFamily: "Roboto",
            fontWeight: "400",
            fontSize: "14px",
            lineHeight: "20px",
            letterSpacing: "0.25px",
            color: theme.text?.secondary,
            maxWidth: "358px",
            ...ellipses
        }
    }
});