import { PropsWithChildren } from "react";

export const defaultSnackbarAutohideDuration: number = 6000;

export interface SnackbarProps extends PropsWithChildren<{}> {
    id: string;
    message?: React.ReactNode;
    action?: React.ReactNode;
    autoHideDuration?: number | null;
    longAction?: boolean;
}