import React, { PropsWithChildren } from 'react';

import { CardContents, CardStyle, PageContainerStyles, contentClasses } from "./page-container-styles";

export interface PageContainerProps extends PropsWithChildren<{}> {
    className?: string;
}

export const PageContainer = ({children, className}: PageContainerProps) => {
    const classes = PageContainerStyles();
    return (
        <div className={classes.root}>
        <CardStyle className={className} variant="outlined">
            <CardContents
                className={[contentClasses.cardContents, contentClasses.cardPaddingHeight, contentClasses.cardPaddingWidth].join(" ")}
            >
                {children}
            </CardContents>
        </CardStyle>
        </div>
    );
}
