import React from 'react';

import { Typography } from '@mui/material';
import { FormattedMessage, useIntl } from "react-intl";

import { PageFooter } from "components/page-footer";
import { PageMetadata } from "components/page-metadata";
import {
    DesktopOnly,
    PageButtonsContainer,
    PageContainer,
    PageContentContainer,
    PageHeaderContainer,
    PageLogoContainer,
    PageNotificationContainer,
    PageTitleContainer
} from "components/controls";
import { Button, LoadingContent, Spacer } from "tm-controls";
import { Field, Form, FormProps, FormRenderProps } from "react-final-form";
import { CheckboxAdapter, FormErrorPlaceholder, InputAdapter } from "components/form-adapters";

import { SecondFactorAuthenticationFormProps } from "./second-factor-authentication-form-props";
import { SecondFactorAuthenticationFormStyles } from "./second-factor-authentication-form-styles";
import { Page } from 'components/page';

const CreateForm = Form as React.FC<FormProps>;

export const SecondFactorAuthenticationForm = ({ attemptsRemaining, handleSubmit, keyPinCode, keyCheckbox, disableActiveControls, periodicPinRecheck }: SecondFactorAuthenticationFormProps) => {
    const intl = useIntl();

    const classes = SecondFactorAuthenticationFormStyles();

    const submitFormID = "2FADialogForm";
    const logoPath: string = intl.formatMessage({
        id: "application.logo"
    });

    const idContent: string = periodicPinRecheck ? "2fa.page.periodic.recheck.content" : "2fa.page.content";
    const showCheckbox: boolean = periodicPinRecheck ? false : true;

    const renderForm = ({ handleSubmit }: FormRenderProps): JSX.Element => {
        return (
            <PageContentContainer>
                <form
                    autoComplete="off"
                    className={classes.form}
                    data-testid="form"
                    id={submitFormID}
                    onSubmit={handleSubmit}
                >
                    <Typography data-testid="2fa-page-description" variant="subtitle2">
                        <FormattedMessage id={idContent} values={{ nbsp: <>&nbsp;</> }} />
                    </Typography>
                    <Field
                        autoComplete="off"
                        data-testid="pincode-field"
                        component={InputAdapter}
                        defaultValue=""
                        disabled={disableActiveControls}
                        label={<FormattedMessage id="2fa.page.pincode.input.label" />}
                        helperText=""
                        name={keyPinCode}
                        InputProps={{
                            classes: { input: classes.input }
                        }}
                        classes={{
                            root: classes.textField
                        }}
                    />
                    {attemptsRemaining > 0 &&
                        <PageNotificationContainer>
                            <FormattedMessage
                                id="2fa.page.form.notification.attempts.remaining"
                                values={{
                                    attemptsRemaining: attemptsRemaining
                                }}
                            />
                        </PageNotificationContainer>
                    }
                    {showCheckbox &&
                        <Field
                            data-testid="2fa-page-checkbox-field"
                            component={CheckboxAdapter}
                            disabled={disableActiveControls}
                            label={<Typography data-testid="2fa-page-checkbox"
                                variant="subtitle2"><FormattedMessage
                                    id="2fa.page.checkbox.input.label" /></Typography>}
                            name={keyCheckbox}
                            type="checkbox"
                            format={v => v === "true"}
                            parse={v => (v ? "true" : null)}
                        />
                    }
                </form>
                <DesktopOnly>
                    <FormErrorPlaceholder name={keyPinCode} />
                </DesktopOnly>
            </PageContentContainer>
        );
    }

    return (
        <Page>
            <PageMetadata />
            <PageContainer>
                <PageHeaderContainer>
                    <PageLogoContainer>
                        <img data-testid="application-logo" height="28px" src={logoPath} alt="logo" />
                    </PageLogoContainer>
                    <PageTitleContainer>
                        <Typography data-testid="2fa-page-title" variant="h5">
                            <FormattedMessage id="2fa.page.title" />
                        </Typography>
                    </PageTitleContainer>
                </PageHeaderContainer>
                <CreateForm
                    onSubmit={handleSubmit}
                    render={renderForm}
                />
                <PageButtonsContainer>
                    <DesktopOnly>
                        <Spacer />
                    </DesktopOnly>
                    <Button
                        data-testid="submit-button"
                        disabled={disableActiveControls}
                        form={submitFormID}
                        type="submit"
                        variant="Filled"
                        color="primary"
                    >
                        <LoadingContent loading={disableActiveControls}>
                            <FormattedMessage
                                id={"2fa.page.submit.button.label"}
                            />
                        </LoadingContent>
                    </Button>
                </PageButtonsContainer>
            </PageContainer>
            <PageFooter />
        </Page>
    )
}
