import Czech from './cs.json';
import English from './en.json';
import Slovak from './sk.json';

import logo from './img/logo.png';
import darkIcon from './img/icon-dark.png';
import lightIcon from './img/icon-light.png';

export interface TranslationDictionary {
    [index: string]: Record<string, string>;
}

export const TranslationsList: TranslationDictionary = {
    'en': English,
    'sk': Slovak,
    'cs': Czech
}

function replaceImage(imageId: string, imageData: string) {
    Object.keys(TranslationsList).forEach((lang) => {
        TranslationsList[lang][imageId] = imageData;
    });
}

replaceImage("application.logo", logo);
replaceImage("application.icon.dark", darkIcon);
replaceImage("application.icon.light", lightIcon);