import * as React from 'react';

import { FieldRenderProps } from "react-final-form";

import { Checkbox, CheckboxProps } from "tm-controls";

export const CheckboxAdapter = ({
                                    input: {onChange, value, ...restInput},
                                    label,
                                    labelNotClickable,
                                    color,
                                    disabled,
                                    meta,
                                    ...rest
                                }: FieldRenderProps<any> & CheckboxProps) => {
    return (
        <Checkbox
            {...rest}
            inputProps={restInput}
            disabled={disabled}
            color={color}
            onChange={onChange}
            label={label}
            labelNotClickable={labelNotClickable}
        />
    )
}
