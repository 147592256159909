import React from "react";
import { CurrentUser } from "data/user";

export interface CurrentUserContextInterface {
    user: CurrentUser | null;
    setUser: (user: CurrentUser | null) => void;
}

export const CurrentUserContext: React.Context<CurrentUserContextInterface> =
    React.createContext<CurrentUserContextInterface>(
        {
            user: null,
            setUser: (user: CurrentUser | null) => null
        }
    );
