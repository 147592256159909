import { Button as MUIButton } from "@mui/material";
import { ButtonProps } from "./button-props";
import { SxProps } from "@mui/system";
import { Theme } from "@mui/material/styles";

export const Button = ({onClick, variant, color, disabled, roundCorners, children, ...rest}: ButtonProps) => {
    let oVariant: "text" | "outlined" | "contained" | undefined = undefined;
    if (variant === "Text") {
        oVariant = "text";
    } else if (variant === "Outlined") {
        oVariant = "outlined";
    } else if (variant === "Filled") {
        oVariant = "contained";
    }else if (variant === "Link"){
        oVariant = "text";
    }
    
    let oColor: "primary" | "inversePrimary" | "secondary" | "outline" | undefined = undefined;
    if (color === "primary") {
        oColor = "primary";
    } else if (color === "secondary") {
        oColor = "secondary";
    } else if (color === "outline") {
        oColor = "outline";
    } else if (color === "inversePrimary") {
        oColor = "inversePrimary";
    }
    
    let oSx: SxProps<Theme> | null = {};
    
    if (roundCorners) {
        oSx={
            ...oSx,
            borderRadius: "100px"
        }
    }

    if(variant === "Link"){
        oSx={
            ...oSx,
            textDecoration: "underline !important" 
        }
    }

    return (
        <MUIButton
            {...rest}
            sx={oSx}
            variant={oVariant}
            onClick={onClick}
            color={oColor}
            disabled={disabled}
        >
            {children}
        </MUIButton>
    )
}
