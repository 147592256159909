import React from "react";

export interface FatalErrorContextInterface {
    serverError: boolean;
    setServerError: () => void;
    handleHttpError: (code?: number) => boolean;
}

export const FatalErrorContext: React.Context<FatalErrorContextInterface> =
    React.createContext<FatalErrorContextInterface>(
        {
            serverError: false,
            setServerError: () => { },
            handleHttpError: () => { return false }
        }
    );
