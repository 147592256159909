import { Grid, Typography } from '@mui/material';

import {
	DesktopOnly,
	PageButtonsContainer,
	PageContainer,
	PageContentContainer,
	PageHeaderContainer,
	PageLogoContainer,
	PageTitleContainer
} from "components/controls";
import { FormErrorPlaceholder, InputAdapter } from "components/form-adapters";
import { PageFooter } from "components/page-footer";
import { PageMetadata } from "components/page-metadata";
import { RecaptchaBadge } from 'components/recaptcha-badge';
import React from "react";
import { Field, Form, FormProps, FormRenderProps } from "react-final-form";
import { FormattedMessage, useIntl } from "react-intl";
import { Button, LoadingContent, Spacer } from "tm-controls";

import { SetPasswordFormProps } from "components/pages/shared/set-password-form/set-password-form-props";
import { SetPasswordFormRoot, setPasswordFormClasses } from "components/pages/shared/set-password-form/set-password-form-styles";
import { Page } from 'components/page';

const CreateForm = Form as React.FC<FormProps>;
export const SetPasswordForm = ({
	                                  disableActiveControls,
	                                  showProgress,
	                                  keyPassword1,
	                                  keyPassword2,
	                                  handleSubmitForm
}: SetPasswordFormProps) => {
	const intl = useIntl();
	
	const submitFormID: string = "SetPasswordForm";
	const logoPath: string = intl.formatMessage({
		                                            id: "application.logo"
	                                            });
	
	const renderForm = ({handleSubmit}: FormRenderProps): JSX.Element => {
		return (
			<PageContentContainer>
				<SetPasswordFormRoot
					className={setPasswordFormClasses.form}
					data-testid="form"
					id={submitFormID}
					onSubmit={handleSubmit}
				>
					<Grid container rowSpacing={'16px'} direction={"column"}>
						<Grid item>
							<Typography data-testid="set-password-form-description" variant="subtitle2">
								<FormattedMessage
									id="set.password.form.content"
									values={{ nbsp: <>&nbsp;</> }}
								/>
							</Typography>
						</Grid>
						<Grid item>
							<Field
                                autoComplete="new-password"
								data-testid="password1-field"
								component={InputAdapter}
								defaultValue=""
								disabled={disableActiveControls}
								label={<FormattedMessage id="set.password.form.input.password1.label" />}
								helperText={<FormattedMessage
									id="set.password.form.input.password1.helperText.label"
									values={{ nbsp: <>&nbsp;</> }}
								/>}
								name={keyPassword1}
								variant="outlined"
								sx={{flexBasis: "49", flexShrink: 1}}
								type="password"
							/>
						</Grid>
						<Grid item>
							<Field
                                autoComplete="new-password"
								data-testid="password2-field"
								component={InputAdapter}
								defaultValue=""
								disabled={disableActiveControls}
								label={<FormattedMessage id="set.password.form.input.password2.label" />}
								helperText=""
								name={keyPassword2}
								variant="outlined"
								sx={{flexBasis: "49", flexShrink: 1}}
								type="password"
							/>
						</Grid>
					</Grid>
				</SetPasswordFormRoot>
				<DesktopOnly>
					<FormErrorPlaceholder name={keyPassword2} />
				</DesktopOnly>
			</PageContentContainer>
		);
	}
	
	return (
		<Page>
			<PageMetadata/>
				<PageContainer>
					<PageHeaderContainer>
						<PageLogoContainer>
							<img data-testid="application-logo" height="28px" src={logoPath} alt="logo"/>
						</PageLogoContainer>
						<PageTitleContainer>
							<Typography data-testid="set-password-form-title" variant="h5">
								<FormattedMessage id="set.password.form.title" />
							</Typography>
						</PageTitleContainer>
					</PageHeaderContainer>
					<CreateForm
						onSubmit={handleSubmitForm}
						render={renderForm}
					/>
					<PageButtonsContainer>
						<DesktopOnly>
							<Spacer />
						</DesktopOnly>
						<Button
							variant="Filled"
							data-testid="submit-button"
							disabled={disableActiveControls}
							form={submitFormID}
							type="submit"
							color="primary"
						>
							<LoadingContent loading={showProgress}>
								<FormattedMessage id="set.password.form.submit.button" />
							</LoadingContent>
						</Button>
					</PageButtonsContainer>
				</PageContainer>
            <RecaptchaBadge />
			<PageFooter/>
		</Page>
	);
}
