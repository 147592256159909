import React, { PropsWithChildren } from 'react';
import { IntlProvider } from "react-intl";
import { TranslationsList } from "./translations-list";

interface TranslationsProviderProps extends PropsWithChildren<{}> {
    locale?: string;
}

export const TranslationsProvider = ({ locale = 'sk', children }: TranslationsProviderProps) => {
    locale = TranslationsList[locale]? locale: 'sk';
    const messages = TranslationsList[locale];
    
    return (
        <IntlProvider locale={locale} messages={messages}>
            {children}
        </IntlProvider>
    )
}
