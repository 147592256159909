import { Helmet } from 'react-helmet-async';
import { useIntl } from "react-intl";
import { useTheme } from "@mui/material";

import { PageMetadataProps } from "./page-metadata-props";
import { Theme } from "tm-controls";

export const PageMetadata = ({title, iconPath}: PageMetadataProps) => {
    const intl = useIntl();
    const theme = useTheme<Theme>();
    
    if (title === undefined) {
        title = intl.formatMessage({
            id: "application.title"
        });
    }
    
    if (iconPath === undefined) {
        iconPath = intl.formatMessage(
            {
                id: theme.mode === 'dark' ?
                    "application.icon.dark" :
                    "application.icon.light"
            }
        );
    }
    
    return (
        <Helmet
            title={title}
            htmlAttributes={{lang: intl.locale}}
        >
            <link rel="icon" href={iconPath} data-rh={"true"}/>
            <link rel="apple-touch-icon" href={iconPath} data-rh={"true"}/>
            <link rel="mask-icon" href={iconPath} data-rh={"true"}/>
        </Helmet>
    )
}
