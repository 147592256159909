import * as React from 'react';

import { FieldRenderProps } from "react-final-form";

import { getFormError } from "./get-form-error";
import { Input, InputProps } from 'tm-controls';

export const InputAdapter = ({
                                 input: {onChange, value, autocomplete, type, ...restInput},
                                 meta,
                                 helperText,
                                 autoSelect,
                                 autoFocus,
                                 variant,
                                 label,
                                 disabled,
                                 ...rest
                             }: FieldRenderProps<string> & InputProps & { autoSelect?: boolean }) => {
    
    const [doAutoSelection, setAutoSelection] = React.useState(autoSelect);
    
    const onFocus = (event: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        if (event && doAutoSelection) {
            event.target.select();
            setAutoSelection(false);
        }
    };
    
    const error = getFormError(meta);
    
    //turn off autoComplete by default
    if (restInput.autoComplete === undefined) {
        restInput.autoComplete = "off"
    }
    
    return (
        <Input
            {...restInput}
            {...rest}
            onFocus={onFocus}
            variant={variant}
            type={type}
            disabled={disabled}
            onChange={onChange}
            value={value}
            label={label}
            error={!!error}
            helperText={error ? error : helperText}
            autoFocus={autoFocus || autoSelect}
        />
    );
};
