import { BiometricAuthenticationUnavailableDialogStyles } from "./biometric-authentication-unavailable-dialog-styles";
import { Button, Dialog, Spacer } from "tm-controls";
import { DesktopOnly } from 'components/controls';
import { Typography } from "@mui/material";
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import React from "react";
import { FormattedMessage } from "react-intl";
import { BiometricAuthenticationUnavailableDialogProps } from "./biometric-authentication-unavailable-dialog-props";

export const BiometricAuthenticationNotAvailableDialog = ({
                                                              isOpen,
                                                              onClose
                                                          }: BiometricAuthenticationUnavailableDialogProps) => {
    const classes = BiometricAuthenticationUnavailableDialogStyles();
    
    const handleClose = () => {
        if (onClose) {
            onClose();
        }
    }
    
    const dialogActions = <>
        <DesktopOnly>
            <Spacer/>
        </DesktopOnly>
        <Button
            classes={{root: classes.dialogButton}}
            onClick={handleClose}
            color="primary"
            variant={'Text'}
            data-testid="biometricAuthentication-dialog-button-confirm"
        >
            <FormattedMessage
                id={"biometricAuthentication.dialog.button.confirm"}
            />
        </Button>
    </>
    
    const dialogTitle = <Typography data-testid="biometricAuthentication-dialog-title"
                                    sx={{textAlign: "center"}}
                                    variant="h6">
        <FormattedMessage
            id={"biometricAuthentication.dialog.title"}
        />
    </Typography>;
    const dialogIcon = <WarningAmberIcon/>
    const dialogContent = <Typography data-testid="biometricAuthentication-dialog-content" variant="subtitle1">
        <FormattedMessage
            id={"biometricAuthentication.dialog.content"}
        />
    </Typography>
    
    if (isOpen) {
        return (
            <Dialog
                isOpen={isOpen}
                onClose={handleClose}
                dialogTitle={
                    dialogTitle
                }
                dialogIcon={dialogIcon}
                dialogContent={
                    dialogContent
                }
                dialogActions={
                    dialogActions
                }
            />
        );
    }
    
    return null;
}
