import { Theme } from "tm-controls";

export const theme: Theme = {
    primary: {
        main: "#005db7",
        contrastText: "#FFFFFF",
        light: "#FFFFFF",
        dark: "#121212",
    },
    secondary: {main: "#565F71"},
    text: {
        primary: "#202124",
        secondary: "rgba(0, 0, 0, 0.6)"
    },
    common: {
        borderColor: "#DADCE0"
    },
    error: {main: "#BA1B1B"},
    info: {
        main: "#FFECB3",
        contrastText: "rgba(200,164,24,0.47)"
    },
    outline: {
        main: "#DADCE052"
    },
    mode: "light"
};
