import { Action, ActionMethod, ContentType, RecaptchaInfo, ServerAdapterInterface } from "data";
import { AxiosResponse } from "axios";

export interface ServerInfoResponse {
    reCaptcha: RecaptchaInfo;
    status: string;
}

export interface ServerVersionResponse {
    copyright: string;
}

export interface ServerControllerInterface {
    readServerInfo(): Promise<ServerInfoResponse>;
    readServerVersion(): Promise<ServerVersionResponse>;
}

export class ServerController implements ServerControllerInterface {
    private serverInfoAction: Action = {
        href: "/api/server/info",
        method: ActionMethod.Get,
        accept: ContentType.Json
    };

    private serverVersionAction: Action = {
        href: "/api/server/version",
        method: ActionMethod.Get,
        accept: ContentType.Json
    };
    
    constructor(private serverAdapter: ServerAdapterInterface) {
    }
    
    readServerInfo(): Promise<ServerInfoResponse> {
        return new Promise<ServerInfoResponse>(
            (
                resolve: (response: ServerInfoResponse) => void,
                reject: (reason?: any) => void
            ) => {
                this.serverAdapter.doAction<ServerInfoResponse>(this.serverInfoAction)
                    .then((value: AxiosResponse<ServerInfoResponse>) => {
                        const serverInfo: ServerInfoResponse = value.data;
                        if (serverInfo.status === 'ok') {
                            resolve(serverInfo);
                        } else {
                            reject();
                        }
                    }).catch((err: any) => {
                    reject(err);
                })
            });
    }

    readServerVersion(): Promise<ServerVersionResponse> {
        return new Promise<ServerVersionResponse>(
            (
                resolve: (response: ServerVersionResponse) => void,
                reject: (reason?: any) => void
            ) => {
                this.serverAdapter.doAction<ServerVersionResponse>(this.serverVersionAction)
                    .then((value: AxiosResponse<ServerVersionResponse>) => {
                        const serverVersion: ServerVersionResponse = value.data;
                        resolve(serverVersion);
                    }).catch((err: any) => {
                        reject(err);
                    })
            });
    }
}
