import { PropsWithChildren } from "react";
import { ThemeProvider as Provider } from "@mui/material/styles";
import { CreateSystemTheme, ThemeContext, ThemeContextInterface } from "tm-controls";

export interface ThemeProviderProps extends PropsWithChildren<ThemeContextInterface> {
}

export const ThemeProvider = ({theme, children}: ThemeProviderProps) => {
    const muiTheme = CreateSystemTheme({
        palette: {
            ...theme,
            primary: {main: theme.primary.main},
            secondary: {main: theme.secondary.main},
            error: {main: theme.error.main},
            mode: theme.mode
        }
    });
    
    return (
        <ThemeContext.Provider value={{
            theme: theme
        }}>
            <Provider key="theme-provider" theme={muiTheme}>
                {children}
            </Provider>
        </ThemeContext.Provider>
    
    )
}
