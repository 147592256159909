import { Link, Typography } from '@mui/material';
import { InformationDialog } from "components/dialogs/information-dialog";
import React from 'react';
import { FormattedMessage } from "react-intl";
import { Button, LoadingContent, Spacer, ellipses } from "tm-controls";
import { DesktopOnly } from 'components/controls';
import { BlockedAccountDialogProps } from "./blocked-account-dialog-props";

export const BlockedAccountDialog = ({ onClickUseAnotherAccount, onClickResetPassword, userEmail, showProgress, disableActiveControls}: BlockedAccountDialogProps) => {
    const dialogMessageId: string = onClickResetPassword ? "blockedAccount.byInvalidPin.page.content" : "blockedAccount.byAdmin.page.content";
    const dialogHeaderMessageId: string = onClickResetPassword ? "blockedAccount.page.title.byInvalidPin" : "blockedAccount.page.title";
    const dialogHeaderElement = (): JSX.Element => (
        <Typography data-testid="blocked-account-page-title" variant="h5">
            <FormattedMessage id={dialogHeaderMessageId} />
        </Typography>
    );

    const dialogSubtitleElement = (): JSX.Element => (
        <div data-testid="blocked-account-page-subtitle"
            style={{ ...ellipses }}
        >
            {userEmail}
        </div>
    );

    const dialogContentElement = (): JSX.Element => (
        <Typography data-testid="blocked-account-page-description" variant="subtitle2">
            <FormattedMessage
                id={dialogMessageId}
                values={{
                    a: (chunks: React.ReactNode[]) =>
                        <Link
                            target="_blank"
                            href="https://tatramed.sk/kontakt/"
                            underline="always"
                            rel="noreferrer"
                        >
                            {chunks}
                        </Link>
                }}
            />
        </Typography>
    );
    const dialogFooterElementWithResetPassword = (): JSX.Element => (
        <>
            <Button
                data-testid="blocked-account-use-another-account-button"
                onClick={onClickUseAnotherAccount}
                color="primary"
                variant="Text"
                disabled={disableActiveControls}
            >
                <FormattedMessage
                    id={"blockedAccount.useAnotherAccount.page.button"}
                />
            </Button>
            <DesktopOnly>
                <Spacer />
            </DesktopOnly>
            <Button
                data-testid="block-account-use-reset-password-button"
                onClick={onClickResetPassword}
                variant="Filled"
                color="primary"
                disabled={disableActiveControls}
            >
                <LoadingContent loading={showProgress}>
                    <FormattedMessage
                        id={"blockedAccount.resetPassword.page.button"}
                    />
                </LoadingContent>
            </Button>
        </>
    );
    const dialogFooterElementUseAnotherAccount = (): JSX.Element => (
        <>
            <DesktopOnly>
                <Spacer />
            </DesktopOnly>
            <Button
                data-testid="blocked-account-use-another-account-button"
                onClick={onClickUseAnotherAccount}
                color="primary"
                variant="Filled"
                disabled={disableActiveControls}
            >
                <FormattedMessage
                    id={"blockedAccount.useAnotherAccount.page.button"}
                />
            </Button>
        </>
    );
    return (
        <InformationDialog
            marginLeft={true}
            informationTitleContainer={dialogHeaderElement()}
            informationSubtitleContainer={dialogSubtitleElement()}
            informationContentContainer={dialogContentElement()}
            informationButtonContainer={onClickResetPassword ?
                dialogFooterElementWithResetPassword() :
                dialogFooterElementUseAnotherAccount()}
        />
    );
}
