import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";

export const UsersListFormStyles = makeStyles((theme:Theme) => ({
    list: {
        height: "219px",
        [theme.breakpoints.down("sm")]: {
            height: "auto"
        }
    }
}));
