import { AxiosResponse } from "axios";

import {
    Action,
    ActionMethod,
    ContentType,
    CurrentUser,
    LoginStatus,
    Reasons,
    ResponseStatus,
    ServerAdapterInterface,
    UserForgotPasswordData,
    UserLoginData,
    UserResetPasswordData
} from "data";
import { UserRegisterData } from "data/user/user";

export interface CurrentUserResponse {
    guest: boolean;
    language?: string;
    status?: string;
    user?: {
        firstName: string;
        surname: string;
        id: number;
        email: string;
        isEnabled: boolean;
    }
}

export interface UserLoginResponse {
    status: LoginStatus;
    reasons: Reasons[];
    email?: string;
}

export interface UserResetPasswordResponse {
    status: ResponseStatus;
    reasons: Reasons[];
}

export interface UserPinCodeResponse {
    status: ResponseStatus;
    pin_code_remaining_attempts: number;
    pin_code_remaining_silent_attempts: number;
    reasons: Reasons[];
}

export interface RegisterUserDeviceResponse {
    status: ResponseStatus;
    reasons: Reasons[];
}

export interface RegisterUserResponse {
    status: ResponseStatus;
    reasons: Reasons[];
}

export interface UserForgotPasswordResponse {
    status: ResponseStatus;
    reasons: Reasons[];
}

export interface GetPinCodeAttemptsResponse {
    status: ResponseStatus;
    pin_code_remaining_attempts: number;
    pin_code_remaining_silent_attempts: number;
    reasons: Reasons[];
}

export interface SkipUserPinCodeResponse{
    status: ResponseStatus;
    reasons: Reasons[];
}

export interface UserControllerInterface {
    getCurrentUserInfo(): Promise<CurrentUser | null>;
    
    processUserLoginRequest(userData: UserLoginData): Promise<UserLoginResponse>;
    
    verifyUserPinCodeRequest(pinCode?: string): Promise<UserPinCodeResponse>;
    
    registerDevice(deviceName: string): Promise<RegisterUserDeviceResponse>;
    
    processUserForgotPasswordRequest(userData: UserForgotPasswordData): Promise<UserForgotPasswordResponse>;
    
    getPinCodeAttempts(): Promise<GetPinCodeAttemptsResponse>;

    skipUserPinCode(): Promise<SkipUserPinCodeResponse>;
    
    resetUserPassword(resetData: UserResetPasswordData): Promise<UserResetPasswordResponse>;
    
    registerUser(registerData: UserRegisterData): Promise<RegisterUserResponse>;
}

export class UserController implements UserControllerInterface {
    private currentUserInfoAction: Action = {
        href: "/api/user/info",
        method: ActionMethod.Get,
        accept: ContentType.Json
    };
    
    private userLoginAction: Action = {
        href: "/api/user/login",
        method: ActionMethod.Post,
        accept: ContentType.Json,
    };
    
    private userPinCodePostAction: Action = {
        href: "/api/user/pincode",
        method: ActionMethod.Post,
        accept: ContentType.Json,
    }
    
    private userPinCodeGetAction: Action = {
        href: "/api/user/pincode",
        method: ActionMethod.Get,
        accept: ContentType.Json,
    }

    private userSkipPinCodePostAction: Action = {
        href: "/api/user/pincode/skip",
        method: ActionMethod.Post,
        accept: ContentType.Json,
    }
    
    private registerUserDeviceAction: Action = {
        href: "/api/user/newdevice",
        method: ActionMethod.Post,
        accept: ContentType.Json,
    }
    
    private userForgotPasswordAction: Action = {
        href: "/api/user/forgotpassword",
        method: ActionMethod.Post,
        accept: ContentType.Json
    }
    
    private userResetPasswordAction: Action = {
        href: "/api/user/resetpassword",
        method: ActionMethod.Post,
        accept: ContentType.Json,
    };
    
    private RegisterUserAction: Action = {
        href: "/api/user/register",
        method: ActionMethod.Post,
        accept: ContentType.Json,
    }
    
    constructor(private serverAdapter: ServerAdapterInterface) {
    }
    
    public static getCurrentUserInfoFromJson = (json: CurrentUserResponse): CurrentUser | null => {
        try {
            let guestProperties = json.guest;
            let statusProperties = json.status;
            let languageProperties = json.language;

            if (guestProperties === undefined || statusProperties !== 'ok' || languageProperties === undefined) {
                return null;
            }
            return {
                authenticated: !guestProperties,
                language: languageProperties,
                id: (json.user !== undefined) ? json.user.id : undefined,
                email: (json.user !== undefined) ? json.user.email : undefined,
                firstName: (json.user !== undefined) ? json.user.firstName: undefined,
                surname: (json.user !== undefined) ? json.user.surname: undefined
            };
        } catch {
            return null;
        }
    }
    
    getCurrentUserInfo(): Promise<CurrentUser | null> {
        return new Promise<CurrentUser | null>((
            resolve: (value: CurrentUser | null) => void,
            reject: (reason?: any) => void
        ) => {
            this.serverAdapter.doAction<CurrentUserResponse>(this.currentUserInfoAction)
                .then((value: AxiosResponse<CurrentUserResponse>) => {
                    let userConfig: CurrentUser | null = UserController.getCurrentUserInfoFromJson(value.data);
                    if (userConfig) {
                        resolve(userConfig);
                    }
                    resolve(null);
                })
                .catch(reject);
        });
    }
    
    processUserLoginRequest(userData: UserLoginData): Promise<UserLoginResponse> {
        return new Promise<UserLoginResponse>((
            resolve: (status: UserLoginResponse) => void,
            reject: (reason?: any) => void
        ) => {
            this.userLoginAction.recaptchaToken = userData.recaptchaToken;
            const data = {
                username: userData.username,
                password: userData.password
            }
            this.serverAdapter.doAction<UserLoginResponse>(this.userLoginAction, data)
                .then((response: AxiosResponse<UserLoginResponse>) => {
                    if (response.data.status) {
                        resolve(response.data)
                    } else {
                        reject();
                    }
                })
                .catch(reject)
        })
    }
    
    processUserForgotPasswordRequest(userData: UserForgotPasswordData): Promise<UserForgotPasswordResponse> {
        return new Promise<UserForgotPasswordResponse>((
            resolve: (status: UserForgotPasswordResponse) => void,
            reject: (reason?: any) => void
            ) => {
            this.userForgotPasswordAction.recaptchaToken = userData.recaptchaToken;
    
            const data = {
                email: userData.email
            }
            
            this.serverAdapter.doAction<UserForgotPasswordResponse>(this.userForgotPasswordAction, data)
                .then((response: AxiosResponse<UserForgotPasswordResponse>) => {
                    if (response.data.status) {
                        resolve(response.data)
                    } else {
                        reject();
                    }
                })
                .catch(reject)
                                                       }
        )
    }
    
    verifyUserPinCodeRequest(pinCode?: string): Promise<UserPinCodeResponse> {
        return new Promise<UserPinCodeResponse>(
            (
                resolve: (status: UserPinCodeResponse) => void,
                reject: (reason?: any) => void
            ) => {
                const data = {
                    pin_code: pinCode
                }
                this.serverAdapter.doAction<UserPinCodeResponse>(this.userPinCodePostAction, data)
                    .then((response: AxiosResponse<UserPinCodeResponse>) => {
                        if (response.data.status) {
                            resolve(response.data)
                        } else {
                            reject();
                        }
                })
                .catch(reject)
        })
    }
    
    registerDevice(deviceName: string): Promise<RegisterUserDeviceResponse> {
        return new Promise<RegisterUserDeviceResponse>((
            resolve: (status: RegisterUserDeviceResponse) => void, reject: (reason?: any) => void
        ) => {
            const data = {
                device_name: deviceName
            }
            this.serverAdapter.doAction<RegisterUserDeviceResponse>(this.registerUserDeviceAction, data)
                .then((response: AxiosResponse<RegisterUserDeviceResponse>) => {
                    if (response.data.status) {
                        resolve(response.data)
                    } else {
                        reject();
                    }
                })
                .catch(reject)
        })
    }
    
    getPinCodeAttempts(): Promise<GetPinCodeAttemptsResponse> {
        return new Promise<GetPinCodeAttemptsResponse>((
            resolve: (status: GetPinCodeAttemptsResponse) => void,
            reject: (reason?: any) => void
        ) => {
            this.serverAdapter.doAction<GetPinCodeAttemptsResponse>(this.userPinCodeGetAction)
                .then( (response: AxiosResponse<GetPinCodeAttemptsResponse>) => {
                    if (response.data.status) {
                        resolve(response.data)
                    } else {
                        reject();
                    }
                })
                .catch(reject)
        })
    }
    
    skipUserPinCode(): Promise<SkipUserPinCodeResponse> {
        return new Promise<SkipUserPinCodeResponse>(
            (
                resolve: (response: SkipUserPinCodeResponse) => void,
                reject: (reason?: any) => void
            ) => {
                this.serverAdapter.doAction<SkipUserPinCodeResponse>(this.userSkipPinCodePostAction)
                    .then((response: AxiosResponse<SkipUserPinCodeResponse>) => {
                        if (response.data.status) {
                            resolve(response.data)
                        } else {
                            reject();
                        }
                    })
                    .catch(reject);
            }
        );
    }

    resetUserPassword(resetData: UserResetPasswordData): Promise<UserResetPasswordResponse> {
        return new Promise<UserResetPasswordResponse>(
            (
                resolve: (response : UserResetPasswordResponse) => void,
                reject: (err?: any) => void) => {
                this.userResetPasswordAction.recaptchaToken = resetData.recaptchaToken;
                const data: Pick<UserResetPasswordData, "token" | "password" | "password2"> = {
                    token    : resetData.token,
                    password : resetData.password,
                    password2: resetData.password2
                }
                this.serverAdapter.doAction<UserResetPasswordResponse>(this.userResetPasswordAction, data)
                    .then((response: AxiosResponse<UserResetPasswordResponse>) => {
                        if (response.data.status) {
                            resolve(response.data)
                        } else {
                            reject();
                        }
                    })
                    .catch(reject)
            })
    }
    
    registerUser(registerData: UserRegisterData): Promise<RegisterUserResponse> {
        return new Promise<RegisterUserResponse>(
            (
                resolve: (response: RegisterUserResponse) => void,
                reject: (err?: any) => void
            ) => {
                this.RegisterUserAction.recaptchaToken = registerData.recaptchaToken;
                const data: Pick<UserRegisterData, "token" | "password" | "password2"> = {
                    token    : registerData.token,
                    password : registerData.password,
                    password2: registerData.password2
                }
                this.serverAdapter.doAction<RegisterUserResponse>(this.RegisterUserAction, data)
                    .then((response: AxiosResponse<RegisterUserResponse>) => {
                        if (response.data.status) {
                            resolve(response.data)
                        } else {
                            reject();
                        }
                    })
                    .catch(reject)
            })
    }
}
