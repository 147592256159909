import { AxiosResponse } from "axios";
import {
	Action,
	ActionMethod,
	ContentType,
	DeviceUserListStatus,
	Reasons,
	ResponseStatus,
	ServerAdapterInterface,
	UserListItem
} from "data";

export interface DeviceUserListResponse {
	status: ResponseStatus;
	users: UserListItem[];
	reasons: Reasons[];
}

export interface DeviceControllerInterface {
	getDeviceUserList(): Promise<UserListItem[]>;
	removeDeviceUserFromList(user: UserListItem): Promise<UserListItem[]>;
}

export class DeviceController implements DeviceControllerInterface{
	constructor(private serverAdapter: ServerAdapterInterface) {}
	
	private deviceUserListAction: Action = {
		href: "/api/device/userlist",
		method: ActionMethod.Get,
		accept: ContentType.Json
	}

	private deviceUserDeleteAction: Action = {
		href: "/api/device/userlist",
		method: ActionMethod.Delete,
		accept: ContentType.Json
	}
	
	getDeviceUserList(): Promise<UserListItem[]> {
		return new Promise<UserListItem[]>((
			resolve: (value: UserListItem[]) => void,
			reject: (reason?: any) => void
		) => {
			this.serverAdapter.doAction<DeviceUserListResponse>(this.deviceUserListAction)
				.then((response: AxiosResponse<DeviceUserListResponse>) => {
					if (response.data.status === DeviceUserListStatus.Ok) {
						resolve(response.data.users)
					} else {
						reject();
					}
				})
				.catch(reject)
		});
	}

	removeDeviceUserFromList(user: UserListItem): Promise<UserListItem[]> {
		return new Promise<UserListItem[]>((
			resolve: (value: UserListItem[]) => void,
			reject: (reason?: any) => void
		) => {
			const action = Object.assign({}, this.deviceUserDeleteAction);
			action.href += "?userId=" + user.userId.toString();
			this.serverAdapter.doAction<DeviceUserListResponse>(action)
				.then((response: AxiosResponse<DeviceUserListResponse>) => {
					if (response.data.status === DeviceUserListStatus.Ok) {
						resolve(response.data.users)
					} else {
						reject();
					}
				})
				.catch(reject)
		});
	}
}
