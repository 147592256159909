export const GetConditionalMediationAvailableCheck = async (): Promise<boolean> => {
    if(!window.PublicKeyCredential){
        return false;
    }

    // check if function exist
    //TODO: 'hack' because of old typescript -> replace when new typescript will support `PublicKeyCredential.isConditionalMediationAvailable`
    if (!(window.PublicKeyCredential as any).isConditionalMediationAvailable) {
        return false;
    }
    //TODO: 'hack' because of old typescript -> replace when new typescript will support `PublicKeyCredential.isConditionalMediationAvailable`
    return await (window.PublicKeyCredential as any).isConditionalMediationAvailable();
}

const cmaCheck = GetConditionalMediationAvailableCheck();

export const ConditionalMediationAvailableCheck = (): Promise<boolean> => {    
    return cmaCheck;
}