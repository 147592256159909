import { FormattedMessage } from "react-intl";

import { defaultSnackbarAutohideDuration, Snackbar } from "tm-controls/snackbar";

export const WebauthnNotAvailableSnackbarID = "application.WebauthnNotAvailableSnackbar";
export const WebauthnNotAvailableSnackbar = () => {	
    return (<Snackbar
        id={WebauthnNotAvailableSnackbarID}
        message={<FormattedMessage id="signin.page.snackbar.inform.webauthn.notAvailable.title" />}
        autoHideDuration={defaultSnackbarAutohideDuration}
    />);
};