import React from "react";

import { Field, Form, FormProps, FormRenderProps } from "react-final-form";
import { FormattedMessage, useIntl } from "react-intl";
import { Grid, Typography } from "@mui/material";

import { FormErrorPlaceholder, InputAdapter } from "components/form-adapters";
import {
    DesktopOnly,
    PageButtonsContainer,
    PageContainer,
    PageContentContainer,
    PageHeaderContainer,
    PageLogoContainer,
    PageNotificationContainer,
    PageTitleContainer
} from "components/controls";
import { Button, LoadingContent, Spacer } from "tm-controls";
import { PageMetadata } from "components/page-metadata";
import { PageFooter } from "components/page-footer";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import { SignInFormStyles } from "./sign-in-form-styles";
import { SignInRememberedUserFormProps } from "./sign-in-remembered-user-form-props";
import { BiometricAuthenticationNotAvailableDialog } from "components/dialogs";
import { RecaptchaBadge } from "components/recaptcha-badge";
import { Page } from "components/page";

const CreateForm = Form as React.FC<FormProps>;

export const SignInRememberedUserForm = ({
    handleSubmit,
    onBackArrow,
    onUseBiometry,
    onResetPassword,
    handleCloseBiometricUnavailableDialog,
    userName,
    inputPasswordEl,
    disableActiveControls,
    showProgress,
    keyPassword,
    showIncorrectCredentialsNotification,
    showServerErrorNotification,
    showBiometricUnavailableDialog,
    onControlCreate,
    onControlDestroy
}: SignInRememberedUserFormProps): JSX.Element => {

    React.useEffect(() => {
        onControlCreate?.();
        return () => {
            onControlDestroy?.()
        };
    }, [onControlCreate, onControlDestroy]);

    const intl = useIntl();
    const classes = SignInFormStyles();
    const formRememberedUserId = "signInRememberedUserDialogForm";

    const logoPath: string = intl.formatMessage({
        id: "application.logo"
    });

    const formRootClass = [classes.form];

    const renderRememberedUser = ({ handleSubmit }: FormRenderProps): JSX.Element => {
        return (
            <PageContentContainer>
                <form
                    className={formRootClass.join(" ")}
                    data-testid="form-remembered-user"
                    id={formRememberedUserId}
                    onSubmit={handleSubmit}
                >
                    <Grid container direction={"column"} rowSpacing={'16px'} gap={'8px'}>
                        <Grid item >
                            <Field
                                autoComplete="current-password webauthn"
                                data-testid="password-field"
                                component={InputAdapter}
                                defaultValue=""
                                disabled={disableActiveControls}
                                helperText=""
                                label={<FormattedMessage id="signin.page.password.input.label" />}
                                type="password"
                                name={keyPassword}
                                variant="outlined"
                                classes={{
                                    root: classes.textField
                                }}
                                inputRef={(input?: any) => {
                                    inputPasswordEl.current = input;
                                }}
                            />
                        </Grid>

                        <div className={classes.forgotPasswordContainer}>
                            <Spacer />
                            <Button
                                data-testid="forgot-password-button"
                                disabled={disableActiveControls}
                                onClick={onResetPassword}
                                color="primary"
                                variant="Link"
                            >
                                <FormattedMessage id="signin.page.forgot.password.button.label" />
                            </Button>
                        </div>                           
                        {showServerErrorNotification &&
                            <Grid item>
                                <PageNotificationContainer>
                                    <FormattedMessage id="signin.page.form.notification.error.message.normal" />
                                </PageNotificationContainer>
                            </Grid>
                        }
                    </Grid>
                </form>
                {!showServerErrorNotification && !showIncorrectCredentialsNotification &&
                        <DesktopOnly>
                            <FormErrorPlaceholder name={keyPassword} />
                        </DesktopOnly>
                } 
            </PageContentContainer>
        );
    }
    return (
        <Page>
            <PageMetadata />
                <PageContainer>
                    <PageHeaderContainer>
                        <PageLogoContainer>
                            <img data-testid="application-logo" height="28px" src={logoPath} alt="logo" />
                        </PageLogoContainer>
                        <PageTitleContainer>
                            <Typography data-testid="sign-in-page-title" variant="h5">
                                <FormattedMessage id="signin.page.title" />
                            </Typography>
                        </PageTitleContainer>
                        <div className={classes.pageTitleButtonContainer}>
                            <Button
                                roundCorners={true}
                                startIcon={<ArrowBackIcon />}
                                variant={"Filled"}
                                color="outline"
                                disableElevation={true}
                                onClick={onBackArrow}
                                disabled={disableActiveControls}
                            >
                                <div className={classes.rememberedUserName}>
                                    {userName}
                                </div>
                            </Button>
                        </div>
                    </PageHeaderContainer>
                    <CreateForm
                        onSubmit={handleSubmit}
                        render={renderRememberedUser}
                    />
                    <PageButtonsContainer
                        marginLeft={true}
                    >
                        <Button
                            disabled={disableActiveControls}
                            data-testid="webauthn-button"
                            onClick={onUseBiometry}
                            color="primary"
                            variant="Text"
                        >
                            <FormattedMessage id="signin.page.webauthn.button.label" />
                        </Button>
                        <DesktopOnly>
                            <Spacer />
                        </DesktopOnly>
                        <Button
                            data-testid="submit-button"
                            disabled={disableActiveControls}
                            form={formRememberedUserId}
                            type="submit"
                            variant="Filled"
                            color="primary"
                        >
                            <LoadingContent loading={showProgress}>
                                <FormattedMessage
                                    id={"signin.page.submit.button.label"}
                                />
                            </LoadingContent>
                        </Button>
                    </PageButtonsContainer>
                </PageContainer>
            {showBiometricUnavailableDialog &&
                <BiometricAuthenticationNotAvailableDialog
                    isOpen={true}
                    onClose={handleCloseBiometricUnavailableDialog}
                />
            }
            
            <RecaptchaBadge />
            <PageFooter />
        </Page>
    );
}
