import { Typography } from '@mui/material';
import { Button, Spacer } from "tm-controls";
import { InformationDialog } from "components/dialogs/information-dialog";
import React from 'react';

import { DesktopOnly } from "components/controls";
import { FormattedMessage } from "react-intl";
import { ForgotPasswordConfirmationDialogProps } from "./forgot-password-confirmation-dialog-props";

export const ForgotPasswordConfirmationDialog = ({ onClickProceed }: ForgotPasswordConfirmationDialogProps) => {
    const dialogHeaderElement = (): JSX.Element => (
        <Typography data-testid="forgot-password-confirmation-page-title" variant="h5">
            <FormattedMessage id="forgotPassword.confirmation.page.title"/>
        </Typography>
    );
    const dialogContentElement = (): JSX.Element => (
        <Typography data-testid="forgot-password-confirmation-page-description" variant="subtitle2">
            <FormattedMessage id="forgotPassword.confirmation.page.message"
                values={{nbsp: <>&nbsp;</>}}
            />
        </Typography>
    );
    const dialogFooterElement = (): JSX.Element => (
        <>
            <Button
                data-testid="proceed-button"
                onClick={onClickProceed}
                variant="Link"
                color="primary"
            >
                <FormattedMessage
                    id="generic.action.signIn"
                />
            </Button>
            <DesktopOnly>
                <Spacer />
            </DesktopOnly>
        </>
    );

    return (
        <InformationDialog marginLeft={true}
            informationTitleContainer={dialogHeaderElement()}
            informationContentContainer={dialogContentElement()}
            informationButtonContainer={dialogFooterElement()}
        />
    );
}
