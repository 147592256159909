import React from 'react';
import { Navigate, Route, Routes as RouterRoutes } from "react-router-dom";

import { SignInPage, ResetPasswordPage, FinishRegistrationPage } from "components/pages";
import { Links } from 'utils';

import { ControlPage, SnackbarPage } from "tm-controls";

export const Routes = () => {
    const routes: Array<JSX.Element> = [];

    routes.push(
        <Route
            path={Links.getBaseUrlLink()}
            element={<SignInPage />}
            key="route-not-authentiated-sign-in"
        />,
        <Route
            path={Links.getBaseResetPasswordDefaultLink()}
            element={<ResetPasswordPage />}
            key="route-reset-password"
        />,
        <Route
            path={Links.getBaseFinishRegistrationDefaultLink()}
            element={<FinishRegistrationPage />}
            key="route-finish-registration"
        />,
        <Route
            path="*"
            element={<Navigate to={Links.getBaseUrlLink()} />}
            key="route-fallback"
        />
    );


    if (process.env.NODE_ENV !== 'production') {
        routes.push(
            <Route
                path={Links.getControlsPageDefaultLink()}
                element={<ControlPage />}
                key="route-not-authentiated-control-page"
            />
        );
        routes.push(
            <Route
                path={Links.getSnackbarPageDefaultLink()}
                element={<SnackbarPage />}
                key="route-not-authentiated-snackbar-page"
            />
        );
    }

    return (
        <RouterRoutes>
            {routes}
        </RouterRoutes>
    );
}
