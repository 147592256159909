import { FormattedMessage, useIntl } from "react-intl";
import { Typography } from "@mui/material";

import {
    PageButtonsContainer,
    PageContainer,
    PageHeaderContainer,
    PageLogoContainer,
    PageTitleContainer,
    PageContentContainer,
    DesktopOnly
} from "components/controls";

import { Button, Spacer } from "tm-controls";
import { PageMetadata } from "components/page-metadata";
import { PageFooter } from "components/page-footer";
import { WelcomeFormProps } from "./welcome-form-props";
import { Page } from "components/page";


export const WelcomeForm = ({
            handleClick
}: WelcomeFormProps) => {

    const intl = useIntl();
    const submitFormID: string = "WelcomeFormDialog";
    const logoPath: string = intl.formatMessage({
        id: "application.logo"
    });

    return (
        <Page>
            <PageMetadata />
            <PageContainer>
                <PageHeaderContainer>
                    <PageLogoContainer>
                        <img data-testid="application-logo" height="28px" src={logoPath} alt="logo" />
                    </PageLogoContainer>
                    <PageTitleContainer>
                        <Typography data-testid="welcome-form-title" variant="h5">
                            <FormattedMessage id="welcome.form.title"
                                values={{ nbsp: <>&nbsp;</> }}
                            />
                        </Typography>
                    </PageTitleContainer>
                </PageHeaderContainer>
                <PageContentContainer>
                    <Typography data-testid="welcome-form-message" variant="subtitle2">
                        <FormattedMessage id="welcome.form.message"
                            values={{ nbsp: <>&nbsp;</> }}
                        />
                    </Typography>
                </PageContentContainer>
                <PageButtonsContainer>
                    <DesktopOnly>
                        <Spacer />
                    </DesktopOnly>
                    <Button
                        data-testid="submit-button"  
                        variant="Filled"
                        form={submitFormID}
                        type="submit"
                        color="primary" 
                        onClick={handleClick}
                    > 
                        <FormattedMessage id="welcome.form.submit.button" />
                    </Button>
                </PageButtonsContainer>
            </PageContainer> 
             <PageFooter /> 
        </Page>
    )
}




















