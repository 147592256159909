import React from 'react';
import { Grid, CircularProgress } from '@mui/material';

import { ProgressCircleProps } from "./progress-circle-props";

export const ProgressCircle = ({ size, value}: ProgressCircleProps) => {
    let variant: "indeterminate" | "determinate" = value === undefined ? "indeterminate" : "determinate";
    
    return (
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            style={{ flexGrow: 1 }}
        >
            <CircularProgress
                size={size}
                value={value}
                variant={variant}
            />
        </Grid>
    );
}
