import React from "react";

export interface PinCodeAttemptsInfo {
    pin_code_remaining_attempts: number;
    pin_code_remaining_silent_attempts: number;
}

export interface PinCodeAttemptsInterface {
    pinCodeAttemptsInfo: PinCodeAttemptsInfo | null;
    setRemainingAttempts: (attempts: PinCodeAttemptsInfo | null) => void;
}

export const PinCodeAttemptsContext: React.Context<PinCodeAttemptsInterface> = React.createContext<PinCodeAttemptsInterface>({
    pinCodeAttemptsInfo: null,
    setRemainingAttempts: (attempts: PinCodeAttemptsInfo | null) => null
})
