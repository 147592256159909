import { styled } from "@mui/material/styles";

export const listClasses = {
    root: "root"
}

export const ListRoot = styled('ul')(() => ({
    [`&.${listClasses.root}`]: {
        listStyle: "none",
        margin: "0",
        padding: "0",
        position: "relative",
        width: "100%",
        backgroundColor: "#fff"
    }
}));
