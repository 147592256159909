import { styled } from "@mui/material/styles";
import { useContext } from "react";
import { ThemeContext } from "tm-controls";

const PREFIX = 'PageFooter';
export const footerClasses = {
    root: `${PREFIX}-root`,
    language: `${PREFIX}-language`,
    copyrightInfo: `${PREFIX}-copyrightInfo`,
}
export const FooterRoot = styled('div')(() => {
    const { theme : { common } } = useContext(ThemeContext);
    return {
        [`&.${footerClasses.root}`]: {
            display: "flex",
            paddingBottom: "7.5px",
            paddingLeft: "24px",
            paddingRight: "24px",
            paddingTop: "7.5px",
            borderTopWidth: "1px",
            borderTopStyle: "solid",
            borderTopColor: common?.borderColor
        }
    }
});

export const FooterLanguageInfo = styled('div')(() => ({
    [`&.${footerClasses.language}`]: {
        display: "flex",
        justifyContent: "flex-start",
        flexGrow: 0,
        flexShrink: 0,
        marginRight: "24px"
    }
}));

export const FooterCopyrightInfo = styled('div')(() => ({
    [`&.${footerClasses.copyrightInfo}`]: {
        display: "flex",
        flexGrow: 1,
        justifyContent: "flex-end",
        marginLeft: "24px"
    }
}));
