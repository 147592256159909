interface DeviceProps {
    screen: string;
    browser: string;
    browserVersion: string;
    browserMajorVersion: number;
    mobile: boolean;
    os: string;
    osVersion: string;
    cookies: boolean
}

function deviceInformation(): DeviceProps {
    let unknown = "-";
    let width = undefined;
    let height = undefined;
    
    // screen
    let screenSize = "";
    let screen: Screen = window.screen;
    
    if (screen.width) {
        width = (screen.width) ? screen.width : undefined;
        height = (screen.height) ? screen.height : undefined;
        screenSize += "" + width + " x " + height;
    }
    
    // browser
    let nVer = navigator.appVersion;
    let nAgt = navigator.userAgent;
    let browser = navigator.appName;
    let version = "" + parseFloat(navigator.appVersion);
    let majorVersion = parseInt(navigator.appVersion, 10);
    let nameOffset, verOffset, ix;
    
    // Opera
    if ((verOffset = nAgt.indexOf("Opera")) !== -1) {
        browser = "Opera";
        version = nAgt.substring(verOffset + 6);
        if ((verOffset = nAgt.indexOf("Version")) !== -1) {
            version = nAgt.substring(verOffset + 8);
        }
    }
    
    if ((verOffset = nAgt.indexOf("OPR")) !== -1) { // Opera Next
        browser = "Opera";
        version = nAgt.substring(verOffset + 4);
    } else if ((verOffset = nAgt.indexOf("Edge")) !== -1) { // Edge
        browser = "Microsoft Edge";
        version = nAgt.substring(verOffset + 5);
    } else if ((verOffset = nAgt.indexOf("MSIE")) !== -1) {// MSIE
        browser = "Microsoft Internet Explorer";
        version = nAgt.substring(verOffset + 5);
    } else if ((verOffset = nAgt.indexOf("Chrome")) !== -1) {// Chrome
        browser = "Chrome";
        version = nAgt.substring(verOffset + 7);
    } else if ((verOffset = nAgt.indexOf("Safari")) !== -1) {// Safari
        browser = "Safari";
        version = nAgt.substring(verOffset + 7);
        if ((verOffset = nAgt.indexOf("Version")) !== -1) {
            version = nAgt.substring(verOffset + 8);
        }
    } else if ((verOffset = nAgt.indexOf("Firefox")) !== -1) {// Firefox
        browser = "Firefox";
        version = nAgt.substring(verOffset + 8);
    } else if (nAgt.indexOf("Trident/") !== -1) {// MSIE 11+
        browser = "Microsoft Internet Explorer";
        version = nAgt.substring(nAgt.indexOf("rv:") + 3);
    } else if ((nameOffset = nAgt.lastIndexOf(" ") + 1) < (verOffset = nAgt.lastIndexOf("/"))) {// Other browsers
        browser = nAgt.substring(nameOffset, verOffset);
        version = nAgt.substring(verOffset + 1);
        if (browser.toLowerCase() === browser.toUpperCase()) {
            browser = navigator.appName;
        }
    }
    // trim the version string
    if ((ix = version.indexOf(";")) !== -1) {
        version = version.substring(0, ix);
    }
    if ((ix = version.indexOf(" ")) !== -1) {
        version = version.substring(0, ix);
    }
    if ((ix = version.indexOf(")")) !== -1) {
        version = version.substring(0, ix);
    }
    
    majorVersion = parseInt("" + version, 10);
    if (isNaN(majorVersion)) {
        version = "" + parseFloat(navigator.appVersion);
        majorVersion = parseInt(navigator.appVersion, 10);
    }
    
    // mobile version
    let mobile = /Mobile|mini|Fennec|Android|iP(ad|od|hone)/.test(nVer);
    
    // cookie
    let cookieEnabled = (navigator.cookieEnabled);
    
    if (typeof navigator.cookieEnabled === "undefined" && !cookieEnabled) {
        document.cookie = "testcookie";
        cookieEnabled = (document.cookie.indexOf("testcookie") !== -1);
    }
    
    // system
    let os: string = unknown;
    let clientStrings = [
        {s: "Windows 10", r: /(Windows 10.0|Windows NT 10.0)/},
        {s: "Windows 8.1", r: /(Windows 8.1|Windows NT 6.3)/},
        {s: "Windows 8", r: /(Windows 8|Windows NT 6.2)/},
        {s: "Windows 7", r: /(Windows 7|Windows NT 6.1)/},
        {s: "Windows Vista", r: /Windows NT 6.0/},
        {s: "Windows Server 2003", r: /Windows NT 5.2/},
        {s: "Windows XP", r: /(Windows NT 5.1|Windows XP)/},
        {s: "Windows 2000", r: /(Windows NT 5.0|Windows 2000)/},
        {s: "Windows ME", r: /(Win 9x 4.90|Windows ME)/},
        {s: "Windows 98", r: /(Windows 98|Win98)/},
        {s: "Windows 95", r: /(Windows 95|Win95|Windows_95)/},
        {s: "Windows NT 4.0", r: /(Windows NT 4.0|WinNT4.0|WinNT|Windows NT)/},
        {s: "Windows CE", r: /Windows CE/},
        {s: "Windows 3.11", r: /Win16/},
        {s: "Android", r: /Android/},
        {s: "Open BSD", r: /OpenBSD/},
        {s: "Sun OS", r: /SunOS/},
        {s: "Linux", r: /(Linux|X11)/},
        {s: "iOS", r: /(iPhone|iPad|iPod)/},
        {s: "Mac OS X", r: /Mac OS X/},
        {s: "Mac OS", r: /(MacPPC|MacIntel|Mac_PowerPC|Macintosh)/},
        {s: "QNX", r: /QNX/},
        {s: "UNIX", r: /UNIX/},
        {s: "BeOS", r: /BeOS/},
        {s: "OS/2", r: /OS\/2/},
        {s: "Search Bot", r: /(nuhk|Googlebot|Yammybot|Openbot|Slurp|MSNBot|Ask Jeeves\/Teoma|ia_archiver)/}
    ];
    
    for (let id in clientStrings) {
        if (clientStrings.hasOwnProperty(id)) {
            let cs = clientStrings[id];
            if (cs.r.test(nAgt)) {
                os = cs.s;
                break;
            }
        }
    }
    
    let osVersion: string = unknown;
    
    let regExp: RegExpExecArray | null
    
    if (/Windows/.test(os)) {
        regExp = /Windows (.*)/.exec(os);
        if (regExp) {
            osVersion = regExp[1];
        }
        os = "Windows";
    }
    
    switch (os) {
        case "Mac OS X":
            regExp = /Mac OS X (10[._\d]+)/.exec(nAgt);
            if (regExp) {
                osVersion = regExp[1];
            }
            break;
        
        case "Android":
            regExp = /Android ([._\d]+)/.exec(nAgt);
            if (regExp) {
                osVersion = regExp[1];
            }
            break;
        
        case "iOS":
            let osVersionArray = /OS (\d+)_(\d+)_?(\d+)?/.exec(nVer);
            if (osVersionArray) {
                osVersion = osVersionArray[1] + "." + osVersionArray[2] + "." + (osVersionArray[3] ? osVersionArray[3] : 0);
            }
            break;
    }
    
    return {
        screen: screenSize,
        browser: browser,
        browserVersion: version,
        browserMajorVersion: majorVersion,
        mobile: mobile,
        os: os,
        osVersion: osVersion,
        cookies: cookieEnabled,
    };
}

export interface DeviceName {
    stringId: string;
    browser: string;
    os: string;
    osVersion: string
}

export function GetDefaultDeviceName(): DeviceName {
    let dev: DeviceProps = deviceInformation();
    let text = "device.name.default";
    if (dev.mobile) {
        text = "device.name.default.mobile";
    }
    return {
        browser: dev.browser, os: dev.os, osVersion: dev.osVersion, stringId: text
    };
}
