import React from "react";

import { Field, Form, FormProps, FormRenderProps } from "react-final-form";
import { FormattedMessage, useIntl } from "react-intl";
import { Typography } from "@mui/material";

import { FormErrorPlaceholder, InputAdapter } from "components/form-adapters";
import {
    DesktopOnly,
    PageButtonsContainer,
    PageContainer,
    PageContentContainer,
    PageHeaderContainer,
    PageLogoContainer,
    PageNotificationContainer,
    PageTitleContainer
} from "components/controls";
import { Button, LoadingContent, Spacer } from "tm-controls";
import { PageMetadata } from "components/page-metadata";
import { PageFooter } from "components/page-footer";

import { SignInFormStyles } from "./sign-in-form-styles";
import { SignInFormProps } from "./sign-in-form-props";
import { RecaptchaBadge } from "components/recaptcha-badge";
import { Page } from "components/page";

const CreateForm = Form as React.FC<FormProps>;

export const SignInForm = ({
    disableActiveControls,
    handleSubmit,
    inputPasswordEl,
    keyPassword,
    keyEmail,
    showIncorrectCredentialsNotification,
    showServerErrorNotification,
    showProgress,
    handleForgotPasswordButton,
    handleBiometryButton,
    onControlCreate,
    onControlDestroy
}: SignInFormProps) => {

    React.useEffect(() => {
        onControlCreate?.();
        return () => {
            onControlDestroy?.()
        };
    }, [onControlCreate, onControlDestroy]);

    const intl = useIntl();
    const classes = SignInFormStyles();
    const submitFormID = "signinDialogForm";
    const logoPath: string = intl.formatMessage({
        id: "application.logo"
    });

    const formRootClass = [classes.form];

    const renderForm = ({handleSubmit}: FormRenderProps): JSX.Element => {
        return (
            <PageContentContainer>
                <div className={classes.contentContainer}>
                <form
                    className={formRootClass.join(" ")}
                    data-testid="form"
                    id={submitFormID}
                    onSubmit={handleSubmit}
                    noValidate
                >
                    <Field
                        type="email"
                        inputmode="email"
                        autoComplete="username webauthn"
                        data-testid="email-field"
                        component={InputAdapter}
                        defaultValue=""
                        disabled={disableActiveControls}
                        label={<FormattedMessage id="signin.page.email.input.label" />}
                        helperText=""
                        name={keyEmail}
                        variant="outlined"
                        classes={{
                            root: classes.textField
                        }}
                    />
                    <Field
                        autoComplete="current-password webauthn"
                        data-testid="password-field"
                        component={InputAdapter}
                        defaultValue=""
                        disabled={disableActiveControls}
                        helperText=""
                        label={<FormattedMessage id="signin.page.password.input.label" />}
                        type="password"
                        name={keyPassword}
                        variant="outlined"
                        classes={{
                            root: classes.textField
                        }}
                        inputRef={(input?: any) => {
                            inputPasswordEl.current = input;
                        }}
                    />
                </form>

                <div className={classes.forgotPasswordContainer}>
                    <Spacer />
                    <Button
                        data-testid="forgot-password-button"
                        disabled={disableActiveControls}
                        onClick={handleForgotPasswordButton}
                        color="primary"
                        variant="Link"
                    >
                        <FormattedMessage id="signin.page.forgot.password.button.label" />
                    </Button>
                </div>
                {showIncorrectCredentialsNotification &&
                        <PageNotificationContainer >
                            <FormattedMessage id="signin.page.form.notification.message.normal"/>
                            <div className={`${classes.notificationEmphasis} ${classes.notificationLink}`}  onClick={handleForgotPasswordButton} >
                                <FormattedMessage  id="signin.page.form.notification.message.emphasis"  values={{nbsp: <>&nbsp;</>}}/>
                            </div>
                        </PageNotificationContainer>
                }
                {showServerErrorNotification &&
                        <PageNotificationContainer>
                            <FormattedMessage id="signin.page.form.notification.error.message.normal" />
                        </PageNotificationContainer>                    
                }
                {!showServerErrorNotification && !showIncorrectCredentialsNotification &&
                    <DesktopOnly>
                        <div className={classes.placeholdersContainer}>
                            <FormErrorPlaceholder name={keyEmail} />
                            <FormErrorPlaceholder name={keyPassword} />
                            <div className={classes.signInPlaceholder} />
                        </div>
                    </DesktopOnly>
                }
                </div>
            </PageContentContainer>
        );
    };

    return (
        <Page>
            <PageMetadata />
                <PageContainer>
                    <PageHeaderContainer>
                        <PageLogoContainer>
                            <img data-testid="application-logo" height="28px" src={logoPath} alt="logo" />
                        </PageLogoContainer>
                        <PageTitleContainer>
                            <Typography data-testid="sign-in-page-title" variant="h5">
                                <FormattedMessage id="signin.page.title" />
                            </Typography>
                        </PageTitleContainer>
                    </PageHeaderContainer>
                    <CreateForm
                        onSubmit={handleSubmit}
                        render={renderForm}
                    />
                    <PageButtonsContainer marginLeft={true}>
                        <Button
                            disabled={disableActiveControls}
                            data-testid="webauthn-button"
                            onClick={handleBiometryButton}
                            color="primary"
                            variant="Text"
                        >
                            <FormattedMessage id="signin.page.webauthn.button.label" />
                        </Button>
                        <DesktopOnly>
                            <Spacer />
                        </DesktopOnly>
                        <Button
                            data-testid="submit-button"
                            disabled={disableActiveControls}
                            form={submitFormID}
                            type="submit"
                            variant="Filled"
                            color="primary"
                        >
                            <LoadingContent loading={showProgress}>
                                <FormattedMessage
                                    id={"signin.page.submit.button.label"}
                                />
                            </LoadingContent>
                        </Button>
                    </PageButtonsContainer>
                </PageContainer>
            <RecaptchaBadge />
            <PageFooter />
        </Page>
    )
}
