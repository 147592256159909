export class HandledError<T extends Error> extends Error {
	public static Name = "Handled error";

	public static isHandledError(error: any){	
		if(!error)	{
			return false;
		}
	 	return (error.name === HandledError.Name);
	}

	constructor(
		public error: T
	) {
		super();
		this.name = HandledError.Name;
	}
}

export function processUnhandledError(handler: (error: Error) => void): (error: Error) => void {
	return function (error: Error): void {
		if (!HandledError.isHandledError(error)) {
			handler(error);
		}
	}
}

export function convertUnhandledError(error: any, convertFn: (error: any) => Error): Error {
	if (HandledError.isHandledError(error)) {
		return error;
	}
	return convertFn(error);
}