import { ListItemButton } from "tm-controls";
import { StringMethods } from "utils";
import { UsersListItemProps } from "./users-list-item-props";
import { UserEmailRoot, UserItemRoot, UserItemText, UserNameRoot, usersListItemClasses, UserItemIconContainer } from "./users-list-item-styles";
import { Button, Popover } from "@mui/material";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import React from 'react';
import { MenuItem } from "tm-controls/menu-item";
import { FormattedMessage } from "react-intl";
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';

export const UsersListItem = (props: UsersListItemProps) => {
  const fullUserName = [props.userListItem.firstName, props.userListItem.surname].filter(StringMethods.isNotEmpty).join(" ");
  const userEmail = props.userListItem.email;
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClick = () => {
    if (props.onClick) {
      props.onClick();
    }
  }

  const showMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    event.preventDefault();
    event.stopPropagation();
  }

  const onClosePopover = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    closeMenu();
  }

  const closeMenu = () => {
    setAnchorEl(null);
  }

  const onDelete = () => {
    closeMenu();
    if (props.onRemove) {
      props.onRemove();
    }
  }

  const openedMenu = Boolean(anchorEl);
  return (
    <ListItemButton
      data-testid="users-list-item"
      divider={true}
      onClick={handleClick}
      disableGutters={true}
    >
      <UserItemRoot className={usersListItemClasses.root}>
        <UserItemText className={usersListItemClasses.text}>
          <UserNameRoot className={usersListItemClasses.userName}>{fullUserName}</UserNameRoot>
          <UserEmailRoot className={usersListItemClasses.userEmail}>{userEmail}</UserEmailRoot>
        </UserItemText>
        <Button
          onClick={showMenu}
        >
          <UserItemIconContainer
            className={
              openedMenu
                ? [usersListItemClasses.root, usersListItemClasses.pressed].join(" ")
                : usersListItemClasses.root
            }
          >
            <MoreVertIcon
              color="secondary"
            />
          </UserItemIconContainer>
        </Button>
        <Popover
          open={openedMenu}
          anchorEl={anchorEl}
          onClose={onClosePopover}
          anchorOrigin={{
            horizontal: 52,
            vertical: 54
          }}
          transformOrigin={{
            horizontal: "right",
            vertical: "top"
          }}
        >
          <MenuItem
            onClick={onDelete}
            width="224px"
          >
            <PersonRemoveIcon
              style={{
                marginRight: "12px"
              }}
            />
            <FormattedMessage id="remove.user.from.list.item" />
          </MenuItem>
        </Popover>
      </UserItemRoot>
    </ListItemButton>
  );
}