export const bufferToBase64 = (buffer: ArrayBuffer): string => {
    let binary: string = '';
    let bytes: Uint8Array = new Uint8Array(buffer);
    let len: number = bytes.byteLength;
    for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
}

export const base64ToBuffer = (base64: string): ArrayBuffer => {
    let binary_string = window.atob(base64);
    const len = binary_string.length;
    let bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
        bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
}

export const base64UrlToBase64 = function (input: string) {
    // Replace non-url compatible chars with base64 standard chars
    input = input
        .replace(/-/g, '+')
        .replace(/_/g, '/');

    // Pad out with standard base64 required padding characters
    var pad = input.length % 4;
    if (pad) {
        if (pad === 1) {
            throw new Error('InvalidLengthError: Input base64url string is the wrong length to determine padding');
        }
        input += new Array(5 - pad).join('=');
    }

    return input;
}

export const stringToBuffer = (str: string): ArrayBuffer => {
    return Uint8Array.from(str, c => c.charCodeAt(0)).buffer;
}