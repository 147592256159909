import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const RecaptchaBadgeStyles = makeStyles((theme: Theme) => ({
    root: {
        visibility: "hidden",
        fontFamily: "Roboto",
        fontSize: 12,
        lineHeight: "18px",
        letterSpacing: 0.4,
        textAlign: "center",
        padding: "12px 24px",
        margin: 0,
        borderTopWidth: "1px",
        borderTopStyle: "solid",
        borderTopColor: theme.palette.common?.borderColor,
        [theme.breakpoints.down("sm")]: {
            visibility: "visible"
        }
    }
}));
