import { makeStyles } from "@mui/styles";
import { Theme } from "tm-controls";

export const DialogStyles = makeStyles((theme: Theme) => ({
    dialogPaper: {
        width: "312px",
        borderRadius: "28px !important",
        background: "linear-gradient(0deg, rgba(0,93,183,0.11), rgba(0,93,183,0.11)), #FDFBFF"
    },
    dialogTitleContainer: {},
    dialogContent: {},
    dialogContentContainer: {
        padding: "24px 24px 0 24px",
        display: "flex",
        gap: "16px",
        flexDirection: "column"
    },
    dialogActionContainer: {
        display: "flex",
        alignItems: "flex-end",
        flexDirection: "column",
        padding: "0px",
        marginTop: "auto"
    },
    dialogHeroIcon: {
        display: "flex",
        justifyContent: "center",
        height: "29px"
    },
    dialogButtonContainer: {
        padding: "24px 24px 24px 8px",
        gap: "8px",
        flexDirection: "row",
        alignItems: "center",
        display: "flex",
    }
}));
