import React, { PropsWithChildren } from 'react';
import { PageNotificationContainerStyles } from "./page-notification-container-styles";


export const PageNotificationContainer = ({children}: PropsWithChildren<{}>) => {
    const classes = PageNotificationContainerStyles();
    return (
        <div className={classes.pageNotification}>
            <div className={classes.pageNotificationMessage}>{children}</div>
        </div>
    )
}
