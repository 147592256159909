import * as React from 'react';

import { MenuItem as MUIMenuItem } from '@mui/material';
import { MenuItemProps } from './menu-item-props';

export const MenuItem = ({ onClick, children, width }: MenuItemProps) => {

    const handleClick = (event:React.MouseEvent ) => {
        event.stopPropagation();
        event.preventDefault();
        if (onClick) {
            onClick();
        } 
    }

    return (
        <MUIMenuItem
            onClick={handleClick}
            sx={{
                width:width
            }}
        >
            {children} 
        </MUIMenuItem>
    );
}