import { UserListItem } from "data";
import React from "react";

export interface DeviceUserListContextInterface {
	deviceUserList: UserListItem[];
	updateDeviceUserList: () => Promise<void>;
	removeDeviceUserFromList: (deviceUser: UserListItem) => Promise<void>;
}

export const DeviceUserListContext: React.Context<DeviceUserListContextInterface> = React.createContext<DeviceUserListContextInterface>({
	deviceUserList: [],
	updateDeviceUserList: () => Promise.resolve(),
	removeDeviceUserFromList: (deviceUser: UserListItem) => Promise.resolve()
});