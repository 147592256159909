import React from "react";
import { SnackbarContext } from "./snackbar-context";

export const SnackbarProvider = (props: React.PropsWithChildren<{}>) => {
	const [currentSnackbar, setCurrentSnackbar] = React.useState<string>("");

	return (
		<SnackbarContext.Provider value={{
			activeSnackbar: currentSnackbar,
			showSnackbar: (id: string) => { setCurrentSnackbar(id); },
			hideSnackbar: (id: string) => { if (currentSnackbar === id) { setCurrentSnackbar(""); } }
		}}>
			{props.children}
		</SnackbarContext.Provider>
	);
}