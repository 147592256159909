import { InputProps } from "./input-props";
import { TextField as MuiTextField } from '@mui/material';

export const Input = ({
                          variant,
                          type,
                          disabled,
                          onChange,
                          value,
                          label,
                          error,
                          helperText,
                          autoFocus,
                          ...rest
                      }: InputProps) => {
    
    let oVariant: 'filled' | 'outlined' | 'standard' | undefined = undefined;
    if (variant === 'filled') {
        oVariant = 'filled';
    } else if (variant === 'outlined') {
        oVariant = 'outlined';
    } else if (variant === 'standard') {
        oVariant = 'standard';
    }
    
    return (
        <MuiTextField
            {...rest}
            onChange={onChange}
            value={value}
            label={label}
            error={error}
            helperText={helperText}
            autoFocus={autoFocus}
            variant={oVariant}
            disabled={disabled}
            type={type}
            fullWidth={true}
        />
    )
}
