import React, { PropsWithChildren } from 'react';
import { PageButtonsContainerStyles } from "./page-buttons-container-styles";
import { PageButtonsContainerProps } from './page-buttons-container-props';
export const PageButtonsContainer = ({children, marginLeft, marginRight, longAction }: PropsWithChildren<PageButtonsContainerProps>) => {
    const classes = PageButtonsContainerStyles();
 
    let divClasses=[classes.buttonsContainer];
    

    if (marginLeft) {
        divClasses.push(classes.marginLeft);
    }

     if ( marginRight) {
        divClasses.push(classes.marginRight);
    } 
    
     if (longAction) {
        divClasses.push(classes.buttonsContainerLong);
    } else {
        divClasses.push(classes.buttonsContainerShort);
    }

    return (
        <div className={divClasses.join(" ")}>
            {children}
        </div>
    );
}
