import React from "react";

import { Field } from "react-final-form";
import { getFormError } from "components/form-adapters";

import { FormErrorPlaceholderStyles } from "./form-error-placeholder-styles";
import { FormErrorPlaceholderProps } from "./form-error-placeholder-props";

export const FormErrorPlaceholder = ({ name }: FormErrorPlaceholderProps) => {
    const classes = FormErrorPlaceholderStyles();
    
    return (
        <Field
            name={name}>
            {({meta}) => {
                const error = getFormError(meta);
                if(!error) {
                    return (<div className={classes.errorPlaceholder}/>);
                }
                return null;
            }}
        </Field>
    )
}
