import React, { PropsWithChildren } from 'react';
import { PageSubtitleContainerStyles } from "./page-subtitle-container-styles";


export const PageSubtitleContainer = ({children}: PropsWithChildren<{}>) => {
    const classes = PageSubtitleContainerStyles();
    return (
        <div className={classes.subtitleContainer}>
            {children}
        </div>
    )
}