import React from "react";
import { FatalErrorContext } from "contexts/fatal-error-context"
import { InternalServerErrorDialog } from "components/dialogs/internal-server-error-dialog";
import { FatalErrorGuardProps } from "./fatal-error-guard-props";



export const FatalErrorGuard = (props: React.PropsWithChildren<FatalErrorGuardProps>) => {
	const fatalErrorContext = React.useContext(FatalErrorContext);
	if (fatalErrorContext.serverError) {
		return (
		<InternalServerErrorDialog acklowledgeError={props.acknowledgeInternalServerError} />
		);
	}
	return (<>{props.children}</>);
}