import React from "react";
import { useIntl } from "react-intl";
import { InformationDialogProps } from "./information-dialog-props";

import { PageMetadata } from "components/page-metadata";
import { PageFooter } from "components/page-footer";
import {
	PageButtonsContainer,
	PageContainer,
	PageContentContainer,
	PageHeaderContainer,
	PageLogoContainer,
	PageTitleContainer,
	PageSubtitleContainer
} from "components/controls";
import { Page } from "components/page";

export const InformationDialog = (
	{
		informationButtonContainer,
		informationTitleContainer,
		informationContentContainer,
		informationSubtitleContainer,
		longAction,
		marginLeft,
		marginRight
	}: InformationDialogProps
) => {
	const intl = useIntl();
	const logoPath: string = intl.formatMessage({
		id: "application.logo"
	});

	return (
		<Page>
			<PageMetadata />
			<PageContainer>
				<PageHeaderContainer>
					<PageLogoContainer>
						<img data-testid="application-logo" height="28px" src={logoPath} alt="logo" />
					</PageLogoContainer>
					<PageTitleContainer>
						{informationTitleContainer}
					</PageTitleContainer>
					{
						informationSubtitleContainer &&
						<PageSubtitleContainer>
							{informationSubtitleContainer}
						</PageSubtitleContainer>
					}
				</PageHeaderContainer>
				<PageContentContainer>
					{informationContentContainer}
				</PageContentContainer>
				<PageButtonsContainer longAction={longAction} marginLeft={marginLeft} marginRight={marginRight}>
					{informationButtonContainer}
				</PageButtonsContainer>
			</PageContainer>
			<PageFooter />
		</Page>
	);
}
