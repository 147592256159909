export class Links {
    static readonly baseUrl: string = "/";
    // Controls page
    static readonly baseControlPageUrl: string = "/tm-controls";
    // Snackbar page
    static readonly baseSnackbarPageUrl: string = "/tm-snackbar";
    // OLD page
    static readonly baseOldPageUrl: string = "/";
    // Reset password
    static readonly baseResetPassword: string = "/reset-password";
    // Finish registration
    static readonly baseFinishRegistration: string = "/accept-invitation";
    
    public static getBaseUrlLink(): string {
        return this.baseUrl;
    }
    
    public static getControlsPageDefaultLink(): string {
        return this.baseControlPageUrl;
    }
    
    public static getSnackbarPageDefaultLink(): string {
        return this.baseSnackbarPageUrl;
    }
    
    public static getOldPageDefaultLink(): string {
        return this.baseOldPageUrl;
    }
    
    public static getBaseResetPasswordDefaultLink(): string {
        return this.baseResetPassword;
    }
    
    public static getBaseFinishRegistrationDefaultLink(): string {
        return this.baseFinishRegistration;
    }
}
