export const ServerResponse = {
    Ok: 'ok',
    Failed: 'failed',
}

export type ResponseStatus = (typeof ServerResponse)[keyof typeof ServerResponse];

export const LoginServerStatus = {
    ...ServerResponse,
    RequestedPinCode: "requested_pin_code",
    PinCodeCheckRequired: "pin_code_recheck_required",
    PinCodeCheckMandatory: "pin_code_recheck_mandatory"
}
export type LoginStatus = (typeof LoginServerStatus)[keyof typeof LoginServerStatus];

export enum Reasons {
    InvalidCredentials           = "invalid_credentials",
    MissingPinCode               = "missing_pin_code",
    WrongPinCode                 = "wrong_pin_code",
    PinCodeRequired              = "pin_code_required",
    EmailNotVerified             = "email_not_verified",
    AccountDisabled              = "account_disabled",
    PinCodeMailFailed            = "pin_code_mail_failed",
    AppError                     = "app_error",
    MissingEmail                 = "missing_email",
    IncorrectEmail               = "incorrect_email",
    CredentialsRequired          = "credentials_required",
    Blocked                      = "blocked",
    WrongToken                   = "wrong_token",
    MissingToken                 = "missing_token",
    WeakPassword                 = "weak_password",
    PasswordsDoNotMatch          = "passwords_do_not_match",
    PasswordRequired             = "password_required",
    PasswordVerificationRequired = "password_verification_required",
    EmailRequired                = "email_required",
    UsernameRequired             = "username_required",
    MissingRequiredParameter     = "missing_parameter",
    InvalidParameter             = "invalid_parameter"
}

export const PinCodeStatus = {...ServerResponse};
export const RegisterDeviceStatus = {...ServerResponse};
export const ForgotPasswordRequestStatus = {...ServerResponse};
export const TokenStatus = {...ServerResponse};
export const ResetPasswordStatus = {...ServerResponse};
export const RegisterUserStatus = {...ServerResponse};
export const DeviceUserListStatus = { ...ServerResponse };
export const WebauthnServerResponse = { ...ServerResponse };
