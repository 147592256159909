import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";

export const PageTitleContainerStyles = makeStyles((theme: Theme) => ({
    titleContainer: {
        display: "flex",
        padding: "16px 0 0 0",
        justifyContent: "center"
    },
}));
