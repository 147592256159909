import { Theme } from "@mui/material/styles/createTheme";
import { makeStyles } from "@mui/styles";

export const SignInFormStyles = makeStyles((theme: Theme) => ({
    form: {        
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        alignSelf: "stretch",
        flexGrow: 0,
        gap: "16px"
    },
    textField: {
        flexBasis: "49%",
        flexShrink: 1
    },
    notificationEmphasis: {
        fontWeight: "700",
        cursor: "pointer"
    },
    notificationLink:{
        textDecoration:"underline"
    },
    pageTitleButtonContainer: {
        display: "flex",
        justifyContent: "center",
        marginTop: "20px"
    },
    rememberedUserName: {
        fontWeight: "500",
        fontSize: "14px",
        fontFamily: "Roboto",
        textAlign: "center",
        letterSpacing: "0.1px",
        lineHeight: "20px",
        textOverflow: "ellipsis",
        overflow: "hidden"
    },
    signInPlaceholder: {
        height: "32px"
    },
    signInRememberedUserBiometricPlaceholder: {
        height: "78px"
    },
    contentContainer: {
        display: "flex",
        flexDirection: "column",
        gap: 8
    },
    forgotPasswordContainer: {
        display: "flex", 
        marginRight:"-12px"
    },
    placeholdersContainer:{
        display: "flex",
        flexDirection: "column",
        gap:0
    }
}));
