import { Link, Typography, useTheme } from "@mui/material";
import GlobalStyles from "@mui/material/GlobalStyles";
import { FormattedMessage } from "react-intl"
import { RecaptchaBadgeStyles } from "./recaptcha-badge-styles";
import React from "react";
import { RecaptchaContext, RecaptchaContextInterface } from "contexts/recaptcha-context";

export const RecaptchaBadge = () => {
	const recaptchaContext: RecaptchaContextInterface = React.useContext(RecaptchaContext);
	const classes = RecaptchaBadgeStyles();
    const theme = useTheme();

	if(recaptchaContext.isActive !== true){
		return null;
	}

    const grecaptchaBadgeStyle = {
        ".grecaptcha-badge": {
            visibility: "visible",
            [theme.breakpoints.down("sm")]: {
                visibility: "hidden"
            }
        }
    };

    return (
        <>
            <GlobalStyles styles={grecaptchaBadgeStyle}/>
            <div className={classes.root}>
                <Typography variant="caption">
                    <FormattedMessage
                        id="recaptcha.badge.message"
                        values={{
                            aprivacy: (chunks: React.ReactNode[]) =>
                                <Link href="https://policies.google.com/privacy">
                                    {chunks}
                                </Link>
                            ,
                            aterms: (chunks: React.ReactNode[]) =>
                                <Link href="https://policies.google.com/terms">
                                    {chunks}
                                </Link>
                        }}
                    />
                </Typography>
            </div>
        </>
	);
}