import { FormattedMessage } from "react-intl";
import { Button } from "tm-controls/button";
import { Snackbar } from "tm-controls/snackbar";
import { Skip2ndFactorSnackbarProps } from "./skip-2nd-factor-snackbar-props";

export const Skip2ndFactorSnackbarID = "application.skip2ndFactorSnackbar";
export const Skip2ndFactorSnackbar = (props: Skip2ndFactorSnackbarProps) => {
	return (<Snackbar
		id={Skip2ndFactorSnackbarID}
		message={<FormattedMessage id="signin.page.snackbar.skipPinCodeRecheck.message" />}
		action={(
			<Button color="inversePrimary" size="small" variant="Text" onClick={props.onAction}>
				<FormattedMessage
					id={"signin.page.snackbar.skipPinCodeRecheck.action"}
				/>
			</Button>
		)}
	/>);
};