import React, { PropsWithChildren } from 'react';
import { PageLogoContainerStyles } from "./page-logo-container-styles";

export const PageLogoContainer = ({children}: PropsWithChildren<{}>) => {
    const classes = PageLogoContainerStyles();
    
    return (
        <div className={classes.logoContainer}>
            {children}
        </div>
    );
}
