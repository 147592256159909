import React from "react";

export interface ServerVersionContextInterface {
    copyright: string;
}

export const defaultCopyrightContext = {
    copyright: ""
}

export const ServerVersionContext: React.Context<ServerVersionContextInterface> =
    React.createContext<ServerVersionContextInterface>(
        defaultCopyrightContext
    );
