import { Theme, ThemeOptions } from "@mui/material";
import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import _ from 'lodash';

declare module "@mui/material/styles/createPalette" {
    interface CommonColors {
        borderColor: string;
    }
    interface Palette {
        outline?: Palette['primary'],
        inversePrimary?: Palette['primary']
    }
    interface PaletteOptions {
        outline?: PaletteOptions['primary']
        inversePrimary?: PaletteOptions['primary']
    }
}

declare module "@mui/material/Button" {
    interface ButtonPropsColorOverrides {
        outline: true;
        inversePrimary: true;
    }
}

const openSansFont = [
    '"Open Sans"',
    "sans-serif",
    'Roboto'
].join(',')

export function CreateSystemTheme(options?: ThemeOptions, createResponsiveFontSizes: boolean = false): Theme {
    let defaultPalette = {
        palette: {
            mode: "light",
            action: {
                disabled: "rgba(32, 33, 36, 0.38)",
                disabledBackground: "rgba(32, 33, 36, 0.12)"
            },
            inversePrimary: {
                main: "rgba(158, 202, 255, 1)"  // M3/sys/light/inverse-primary
            }
        }
    };
    
    let defaultComponents = {
        components: {
            MuiInputLabel: {
                styleOverrides: {
                    root: {
                        fontFamily: 'Roboto',
                        fontWeight: 500,
                        letterSpacing: "0.5px",
                        ":not(.Mui-disabled):not(.Mui-focused)": {
                            fontWeight: 400,
                            ":not(.Mui-error)": {
                                color: options?.palette?.text?.secondary
                            }
                        }
                    }
                },
            },
            MuiButtonBase: {
                defaultProps: {
                    disableRipple: true,
                }
            },
            MuiButton: {
                styleOverrides: {
                    root: {
                        fontSize: '14px',
                        fontWeight: 600,
                        textTransform: "none",
                        letterSpacing: "0.12px",
                        lineHeight: "20px",
                        padding: "10px 24px 10px 24px"
                    },
                    contained: (params: any) => {
                        return {
                            boxShadow:"none",
                            "&:hover:not(.Mui-disabled)":{
                                backgroundColor: params.theme.palette[params.ownerState.color].main
                            }
                            
                        };
                    },
                    text: {
                        padding: "10px 12px 10px 12px",
                        "&:hover:not(.Mui-disabled)": {
                            backgroundColor: "transparent"
                        }
                    },
                    outlined: {
                        paddingTop: "9px",
                        paddingBottom: "9px"
                    },
                }
            },
            MuiCssBaseline: {
                styleOverrides:
                    '.grecaptcha-badge { bottom: 56px !important;}'
            },
            MuiOutlinedInput: {
                styleOverrides: {
                    root: {
                        ":hover:not(.Mui-disabled):not(.Mui-focused):not(.Mui-error) .MuiOutlinedInput-notchedOutline": {
                            borderColor: options?.palette?.common?.borderColor,
                        },
                    },
                    input: {
                        fontFamily: 'Roboto',
                        fontSize: "16px",
                        fontWeight: 400,
                        lineHeight: "24px",
                        letterSpacing: "0.5px",
                        color: options?.palette?.text?.primary
                    },
                    notchedOutline: {
                        borderStyle: "solid",
                        borderWidth: "1px",
                        borderColor: options?.palette?.common?.borderColor,
                    }
                }
            },
            MuiCard: {
                styleOverrides: {
                    root: {
                        borderRadius: "8px",
                    }
                }
            },
            MuiFormHelperText: {
                styleOverrides: {
                    root: {
                        marginTop: "4px",
                        fontFamily: 'Roboto',
                        fontWeight: 400,
                        letterSpacing: "0.4px",
                        lineHeight: "16px",
                        fontSize: "12px",
                        "&.Mui-error": {
                            fontFamily: 'Roboto',
                            fontWeight: 400,
                            letterSpacing: "0.4px",
                            lineHeight: "16px",
                            fontSize: "12px"
                        }
                    }
                }
            },
            MuiSnackbar: {
                defaultProps: {
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "center"
                    },
                    disableWindowBlurListener: true
                },
                styleOverrides: {
                    root: {
                        bottom: "24px",
                        right: "24px",
                        left: "24px",
                        '@media(min-width: 600px)': {
                            bottom: "56px",
                        }
                    },
                }
            },
            MuiSnackbarContent: {
                styleOverrides: {
                    root: {
                        fontFamily: 'Roboto',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        fontSize: "14px",
                        lineHeight: "20px",
                        letterSpacing: "0.25px",
                        padding: "0px 16px 0px 16px",
                        display: "flex",
                        flexWrap: "nowrap",
                        flexDirection: "row",
                        alignItems: "center",
                        minHeight: 48,
                        width: 488,
                        color: "rgba(241, 240, 244, 1)",        // M3/sys/light/inverse-primary
                        backgroundColor: "rgba(47, 48, 51, 1)", // M3/sys/light/inverse-surface
                        boxShadow: "0px 4px 8px 3px rgba(0, 0, 0, 0.15), 0px 1px 3px rgba(0, 0, 0, 0.3)",
                    },
                    message: {
                        display: "-webkit-box",
                        WebkitLineClamp: 2,
                        WebkitBoxOrient: "vertical",
                        overflow: "hidden",
                        padding: 0,
                        margin: "14px 0px 14px 0px",
                        flexGrow: 1,
                        flexShrink: 1,
                        maxHeight: 40,
                    },
                    action: {
                        alignItems: "center",
                        flexGrow: 0,
                        flexShrink: 0,
                        padding: 0,
                        marginLeft: "auto",
                        height: "48px",
                        "& .MuiButton-root": {
                            marginLeft: "4px",
                        },
                        "& .MuiButton-root:hover": {
                            backgroundColor: "transparent",
                        },
                        "& .MuiIconButton-root": {
                            marginRight: "-8px",
                        },
                        "& .MuiIconButton-root:hover": {
                            backgroundColor: "transparent",
                        },
                    },
                }
            },
            MuiCheckbox: {
                styleOverrides: {
                    root: {
                        width: "24px",
                        height: "24px"
                    }
                }
            },
            MuiMenuItem: {
                styleOverrides: {
                    root: {
                        paddingleft: "12px",
                        paddingRight: "24px",
                        height: "48px",
                        fontSize: "14px"
                    }
                }
            },
            MuiSvgIcon: {
                styleOverrides: {
                    fontSizeSmall: {
                        fontSize: "18px",
                    }
                }
            }
        }
    };
    
    let defaultTypography = {
        typography: {
            fontFamily: openSansFont,
            button: {
                textTransform: "none",
            },
            subtitle1: {
                fontSize: "14px",
                fontWeight: 400,
                letterSpacing: "0.25px",
                lineHeight: "20px",
                fontFamily: "Roboto"
            },
            subtitle2: {
                fontSize: "14px",
                fontWeight: 400,
                letterSpacing: "0.22px",
                lineHeight: "24px",
                textAlign: "justify",
            },
            h5: {
                fontWeight: 600,
                fontSize: "24px",
                lineHeight: "30px"
            },
            h6: {
                fontWeight: 400,
                fontSize: "24px",
                lineHeight: "32px",
                fontFamily: "Roboto",
                fontStyle: "normal"
            }
        }
    };
    
    let compiledOptions = _.merge(defaultPalette, defaultComponents, defaultTypography, options);
    let theme = createTheme(compiledOptions);
    
    if (!createResponsiveFontSizes) {
        return theme;
    }
    return responsiveFontSizes(theme);
}
