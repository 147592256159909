import React, { useCallback, useEffect, useRef, useState } from "react";
import { DelayerProps } from "./delayer-props";

export const Delayer = ({delay, children}: DelayerProps) => {
    const _timer = useRef<NodeJS.Timeout>();
    
    const [childrenDelayed, setChildrenDelayed] = useState(false);
    
    const delayChildren = useCallback((delay: number = 0) => {
        if (_timer.current) {
            clearTimeout(_timer.current);
            _timer.current = undefined;
        }
        
        if (delay > 0) {
            setChildrenDelayed(true);
            
            _timer.current = setTimeout(() => {
                setChildrenDelayed(false);
            },delay);
        } else {
            setChildrenDelayed(false);
        }
    }, []);
    
    useEffect( () => {
        delayChildren(delay);
    },[delay, delayChildren]);
    
    if (childrenDelayed) {
        return null;
    } else {
        return (
            <>
                { children }
            </>
        );
    }
}
