import { makeStyles } from "@mui/styles";

export const AppStyles = makeStyles({
    "@global": {
        ".grecaptcha-badge": {
            visibility: "hidden"
        }
    },
    root: {
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        flexGrow:1
    }
});
