import { RecaptchaControllerInterface } from "data";
import { Context, createContext } from "react";

export interface RecaptchaContextInterface extends RecaptchaControllerInterface {
}

export const defaultRecaptchaContext = {
    getToken: (action: string): Promise<string> => Promise.resolve(""),
    initializeRecaptcha: (): Promise<boolean> => Promise.resolve(false),
    isActive: undefined
}

export const RecaptchaContext: Context<RecaptchaContextInterface> = createContext<RecaptchaContextInterface>(defaultRecaptchaContext);
