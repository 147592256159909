import { styled } from "@mui/material/styles";

export const userForgotPasswordFormClasses = {
	form: "form"
}

export const UserFormRoot = styled('form')(() => ({
	[`&.${userForgotPasswordFormClasses.form}`]: {		
		display: "flex",
		flexDirection: "column",
		alignItems: "flex-start",
		alignSelf: "stretch",
		flexGrow: 0
	}
}))

