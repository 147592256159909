import { Theme, styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { Card } from '@mui/material';
import { useContext } from "react";
import { ThemeContext } from "tm-controls";

const PREFIX = 'CardContent';
export const contentClasses = {
    cardContents: `${PREFIX}-cardContents`,
    cardPaddingHeight: `${PREFIX}-cardPaddingHeight`,
    cardPaddingWidth: `${PREFIX}-cardPaddingWidth`
};

export const PageContainerStyles = makeStyles((theme: Theme) => ({
    root: {
        display: "flex",
        flexGrow: 1,
        marginTop: 24,
        marginBottom: 24,
        [theme.breakpoints.down("sm")]: {
            marginTop: 0,
            marginBottom: 0
        }
    }
}));

export const CardStyle = styled(Card)((styledOptions) => {
    const { theme: { common } } = useContext(ThemeContext);
    return {
        display: "flex",
        flexDirection: "column",
        width: "488px",
        marginLeft: "auto",
        marginRight: "auto",
        marginBottom: "auto",
        marginTop: "auto",
        "&.MuiPaper-outlined": {
            borderWidth: "1px",
            borderStyle: "solid",
            borderColor: common?.borderColor,
            [styledOptions.theme.breakpoints.down("sm")]: {
                borderWidth: 0
            }
        },
        [styledOptions.theme.breakpoints.down("sm")]: {            
            width: "100%",
            margin: 0                
        }
    }
})

export const CardContents = styled('div')((styledOptions) => ({
    [`&.${contentClasses.cardContents}`]: {
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
    },
    [`&.${contentClasses.cardPaddingHeight}`]: {
        paddingTop: 56,
        paddingBottom: 56,
        [styledOptions.theme.breakpoints.down("sm")]: {
            paddingTop: 24,
            paddingBottom: 24
        }
    },
    [`&.${contentClasses.cardPaddingWidth}`]: {
        paddingRight: 40,
        paddingLeft: 40,
        [styledOptions.theme.breakpoints.down("sm")]: {
            paddingRight: 24,
            paddingLeft: 24
        }
    },
}))
