export enum BiometryAction {
	CreateCredentials = "Create credentials",
	GetCreateRequestParams = "Get create request params",
	ProcessCreateCredentials = "Process create credentials",
	GetCredentials = "Get credentials",
	GetCreateGetRequestParams = "Get get request params",
	ProcessCreateGetCredentials = "Process get credentials",
	SetOfferWebauthnState = "Set offer webauthn state",
	GetOfferWebauthnState = "Get offer webauthn state"
}

export enum BiometryErrorReasons {
	WebAuthenticationNotAvailable = "Web authentication not available",
	UserIdNotAvailable = "User Id not available",
	OptionsNotAvailable = "Options not available",
	InvalidCredentials = "Invalid credentials",
	CredentialsNotAvailable = "Credentials not available",
	InternalServerError = "Internal server error",
	AccountDisabled = "Account disabled",
	FailedToLogin = "Failed to login",
	WebAuthenticationCanceled = "Web authentication was canceled",
}

export class BiometryError extends Error {

	constructor(
		public action: BiometryAction,
		public reason: any
	) {
		super(action);
	}
}