import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";

export const PageSubtitleContainerStyles = makeStyles((theme: Theme) => ({
    subtitleContainer : {
       fontWeight:"600",
       fontSize:"16px",
       lineHeight:"30px",
       display:"flex",
       justifyContent:"center",
       marginTop:"16px"
    }
}));
