import { CSSProperties, makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";

const columnLayout: CSSProperties = {
    gap: 16,
    flexDirection: "column-reverse"
};

export const PageButtonsContainerStyles = makeStyles((theme: Theme) => ({
    buttonsContainer: {
        display: "flex",
        marginTop: "48px",
        [theme.breakpoints.down("sm")]: {
            marginTop: "32px"
        }
    },
    buttonsContainerShort: {
        [theme.breakpoints.down("sm")]: {
            ...columnLayout
        }
    },
    buttonsContainerLong: {
        ...columnLayout
    },
    marginLeft: {
        marginLeft: "-12px",
        [theme.breakpoints.down("sm")]: {
            marginLeft: 0
        }
    },
    marginRight: {
        marginRight: "-12px",
        [theme.breakpoints.down("sm")]: {
            marginRight: 0
        }
    }
}));
