import { useSnackbars } from "tm-controls/snackbar-provider";
import { SnackbarProps } from "./snackbar-props"
import { SnackbarStyle, SnackbarLongStyle } from "./snackbar-styles";
import React, { SyntheticEvent } from "react";
import { SnackbarCloseReason } from "@mui/material";

export const Snackbar = ({ children, message, longAction, id, ...rest }: SnackbarProps) => {
    const SnackbarElement = longAction ? SnackbarLongStyle : SnackbarStyle;
    const context = useSnackbars();

    const onClose = (event: SyntheticEvent | Event, reason: SnackbarCloseReason) => {
        if (reason === "timeout") {
            context.hideSnackbar(id);
        }
    };

    return (
        <SnackbarElement
            {...rest}
            open={context.activeSnackbar === id}
            message={message ?? children}
            onClose={onClose}
        />
    );
}
