import { Typography } from "@mui/material";
import { InformationDialog } from "components/dialogs/information-dialog";
import {
	PairDeviceWithAccountDialogProps
} from "components/dialogs/pair-device-with-account-dialog/pair-device-with-account-dialog-props";
import React from "react";
import { FormattedMessage } from "react-intl";
import { Button, LoadingContent, Spacer } from "tm-controls";
import { DesktopOnly } from 'components/controls';

export const PairDeviceWithAccountDialog = ({onClickPairDevice, onClickSkip, disableActiveControls, showProgress}: PairDeviceWithAccountDialogProps) => {
	
	const dialogHeaderElement = (): JSX.Element => (
		<Typography data-testid="pair-device-with-account-dialog-page-title"
		            variant="h5">
			<FormattedMessage id="pair.device.with.account.dialog.page.title"/>
		</Typography>
	);
	const dialogContentElement = (): JSX.Element => (
		<Typography data-testid="pair-device-with-account-dialog-page-description"
		            variant="subtitle2">
			<FormattedMessage
				id="pair.device.with.account.dialog.page.message"
				values={{ nbsp: <>&nbsp;</> }}
			/>
		</Typography>
	);

    const disabled = disableActiveControls ?? false;
    const loading = showProgress ?? false;

	const dialogFooterElement = (): JSX.Element => (
		<>
			<Button
				data-testid="skip-button"
				onClick={onClickSkip}
				color="primary"
				variant="Text"
				disabled={disabled}
			>
				<FormattedMessage
					id={"pair.device.with.account.dialog.page.skip.button"}
				/>
			</Button>
			<DesktopOnly>
				<Spacer />
			</DesktopOnly>
			<Button
				data-testid="pair-device-button"
				onClick={onClickPairDevice}
				variant="Filled"
				color="primary"
				disabled={disabled}
			>
				<LoadingContent loading={loading}>
					<FormattedMessage
						id="pair.device.with.account.dialog.page.pair.device.button"
					/>
				</LoadingContent>
			</Button>
		</>
	);
	
	return (
		<InformationDialog
			informationTitleContainer={dialogHeaderElement()}
			informationContentContainer={dialogContentElement()}
			informationButtonContainer={dialogFooterElement()}
			marginLeft={true}
		/>
	);
}
