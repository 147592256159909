import { Dialog as MuiDialog } from "@mui/material";
import { DialogProps } from "./dialog-props";
import { DialogStyles } from "./dialog-styles";

export const Dialog = ({
                           isOpen,
                           dialogContent,
                           dialogTitle,
                           dialogIcon,
                           onClose,
                           dialogActions,
                           ...rest
                       }: DialogProps) => {
    const classes = DialogStyles();
    
    return (
        <MuiDialog
            {...rest}
            open={isOpen}
            onClose={onClose}
            classes={
                {
                    paper: classes.dialogPaper
                }
            }
        >
            <div
                className={classes.dialogContentContainer}
            >
                {dialogIcon &&
                    <div className={classes.dialogHeroIcon}>
                        {dialogIcon}
                    </div>
                }
                <div className={classes.dialogTitleContainer}>
                    {dialogTitle}
                </div>
                <div className={classes.dialogContent}>
                    {dialogContent}
                </div>
            </div>
            <div
                className={classes.dialogActionContainer}
            >
                <div className={classes.dialogButtonContainer}>
                    {dialogActions}
                </div>
            </div>
        </MuiDialog>
    )
}
