import { FormattedMessage } from "react-intl";
import { Button } from "tm-controls/button";
import { Snackbar } from "tm-controls/snackbar"; 

export const NetworkErrorSnackbarID = "application.networkProblemSnackbar";
export const NetworkProblemSnackbar = () => {
    return (<Snackbar
        id={NetworkErrorSnackbarID}
        message={<FormattedMessage id="application.snackbar.networkProblem.message" />}
        action={(
            <Button
                color="inversePrimary"
                size="small"
                variant="Text"
                onClick={() => { window.location.reload(); }}>
                <FormattedMessage id="application.snackbar.networkProblem.action" />
            </Button>
        )}
    />);
};