import { Typography } from '@mui/material';

import {
    PageButtonsContainer,
    PageContainer,
    PageContentContainer,
    PageHeaderContainer,
    PageLogoContainer,
    PageTitleContainer
} from "components/controls";
import { PageFooter } from "components/page-footer";
import { PageMetadata } from "components/page-metadata";
import {
    PasskeyRegistrationFormProps
} from "./passkey-registration-form-props";
import { FormattedMessage, useIntl } from "react-intl";
import { Button, LoadingContent } from "tm-controls";
import { Page } from 'components/page';
import { WebauthnNotAvailableSnackbar } from 'components/webauthn-not-available-snackbar';

export const PasskeyRegistrationForm = (
    {
        disableActiveControls,
        handleActivateButton,
        handleAskLaterButton,
        handleDontAskAgainButton,
        showActivateInProgress,
        showAskLaterInProgress,
        showDontAskAgainInProgress
    }: PasskeyRegistrationFormProps) => {
    const intl = useIntl();
    const logoPath: string = intl.formatMessage({ id: "application.logo" });   

    return (
        <Page>
            <PageMetadata />
                <PageContainer>
                    <PageHeaderContainer>
                        <PageLogoContainer>
                            <img data-testid="application-logo" height="28px" src={logoPath} alt="logo" />
                        </PageLogoContainer>
                        <PageTitleContainer>
                            <Typography data-testid="passkey-registration-form-title" variant="h5">
                                <FormattedMessage id="passkey.registration.form.title" />
                            </Typography>
                        </PageTitleContainer>
                    </PageHeaderContainer>
                    <PageContentContainer>
                        <Typography data-testid="passkey-registration-form-description" variant="subtitle2">
                            <FormattedMessage id="passkey.registration.form.content" />
                        </Typography>
                    </PageContentContainer>
                    <PageButtonsContainer longAction={true}>
                        <Button
                            variant="Text"
                            data-testid="passkey-registration-form-dontAskAgain-button"
                            disabled={disableActiveControls}
                            color="primary"
                            onClick={handleDontAskAgainButton}
                        >
                            <LoadingContent loading={showDontAskAgainInProgress ?? false}>
                                <FormattedMessage id="passkey.registration.form.dontAskAgain.button" />
                            </LoadingContent>
                        </Button>
                        <Button
                            variant="Outlined"
                            data-testid="passkey-registration-form-askLater-button"
                            disabled={disableActiveControls}
                            color="primary"
                            onClick={handleAskLaterButton}
                        >
                            <LoadingContent loading={showAskLaterInProgress ?? false}>
                                <FormattedMessage id="passkey.registration.form.askLater.button" />
                            </LoadingContent>
                        </Button>
                        <Button
                            variant="Filled"
                            data-testid="passkey-registration-form-activate-button"
                            disabled={disableActiveControls}
                            color="primary"
                            onClick={handleActivateButton}
                        >
                            <LoadingContent loading={showActivateInProgress ?? false}>
                                <FormattedMessage id="passkey.registration.form.activate.button" />
                            </LoadingContent>
                        </Button>
                    </PageButtonsContainer>
            </PageContainer>
            <WebauthnNotAvailableSnackbar/>
            <PageFooter />
        </Page>
    )
}
