import { GeneratedTokenConfirmationDialog, PairDeviceWithAccountDialog, WrongTokenDialog } from "components/dialogs";
import { WrongTokenVariant } from "components/dialogs/wrong-token-dialog/wrong-token-dialog-props";
import { LoaderGuard } from "components/loader-guard";
import { PasskeyRegistrationForm } from "components/pages/passkey-registration-form";
import { SetPasswordForm } from "components/pages/shared/set-password-form";
import { UserForgotPasswordForm } from "components/pages/user-forgot-password-form";
import { RecaptchaContext, RecaptchaContextInterface } from "contexts";
import { WelcomeForm } from "./welcome-form";
import {
    BiometryAction,
	BiometryController,
	BiometryControllerInterface,
	BiometryErrorReasons,
	ForgotPasswordRequestStatus,
	Reasons,
	RegisterDeviceStatus,
	RegisterUserDeviceResponse,
	ResetPasswordStatus,
	ServerAdapter,
	ServerAdapterInterface,
	TokenController,
	TokenControllerInterface,
	TokenInfoData,
	TokenInfoResponse,
	TokenStatus,
	UserController,
	UserControllerInterface,
	UserForgotPasswordData,
	UserForgotPasswordResponse,
	UserRegisterData, 
	UserResetPasswordResponse,
    WebauthnServerResponse
} from "data";
import { GetBiometryOfferResponse } from "data/biometry-controller/biometry-controller";
import { FormApi } from "final-form";
import React, { useContext, useState, useRef } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate, useSearchParams } from 'react-router-dom';
import { DeviceName, GetDefaultDeviceName, Links, WebAuthCheck } from "utils";
import { FatalErrorContext } from "contexts/fatal-error-context";
import { processUnhandledError } from "data/handled-error";
import { useSnackbars } from "tm-controls/snackbar-provider";
import { WebauthnNotAvailableSnackbarID } from "components/webauthn-not-available-snackbar";

export const FinishRegistrationPage = () => {
	const navigate = useNavigate();
	const intl = useIntl();
	const [searchParams] = useSearchParams();
	const fatalErrorContext = React.useContext(FatalErrorContext);
	
	const keyPassword1: string = "password1";
	const keyPassword2: string = "password2";
	const keyUserEmail: string = "userEmail";
	
	const [showWelcomeForm, setShowWelcomeForm] = useState(true);
	const [redirectToHomePage, setRedirectToHomePage] = useState(false);
	const [showWrongTokenForm, setShowWrongTokenForm] = useState(false);
	const [showPairDeviceWithAccountForm, setShowPairDeviceWithAccountForm] = useState(false);
	const [showGeneratedTokenConfirmationForm, setShowGeneratedTokenConfirmationForm] = useState(false);
	const [showUserForgotPasswordForm, setShowUserForgotPasswordForm] = useState(false);
	const [showServerErrorNotification, setServerErrorNotification] = useState(false);
	const [showProgress, setShowProgress] = useState(false);
	const [disableActiveControls, setDisableActiveControls] = useState(false);
	const [token] = useState(searchParams.get("token"));
	const [showOfferBiometryForm, setShowOfferBiometryForm] = useState(false);
	const [redirectPath] = useState(searchParams.get("redirect"));
	
	let serverAdapter = useRef<ServerAdapterInterface>(new ServerAdapter(fatalErrorContext.handleHttpError));
	const tokenController: TokenControllerInterface = new TokenController(serverAdapter.current);
	const userController: UserControllerInterface = new UserController(serverAdapter.current);

	const recaptchaContext: RecaptchaContextInterface = useContext(RecaptchaContext);
	const biometryController = useRef<BiometryControllerInterface>(new BiometryController(serverAdapter.current, recaptchaContext));
	const snackbarContext = useSnackbars();

	const validationMessage = (messageId: string) => {
		return (
			<FormattedMessage
				id={messageId}
				values={{nbsp: <>&nbsp;</>}}
			/>
		);
	}
	
	const verifyTokenInfo = async (): Promise<void> => {
		try {
			const TokenInfo: string = "ApiTokenInfo";
			const recaptchaToken = await recaptchaContext.getToken(TokenInfo);
			const tokenInfoData: TokenInfoData = {
				token         : token,
				recaptchaToken: recaptchaToken
			}
			const response: TokenInfoResponse = await tokenController.getTokenInfo(tokenInfoData);
			if (response.status !== TokenStatus.Ok) {
				// display WrongTokenForm for MissingToken or WrongToken
				if (response.reasons.includes(Reasons.MissingToken) ||
					response.reasons.includes(Reasons.WrongToken))
				{
					setShowWrongTokenForm(true);
				} else {
					// display WrongTokenForm for other/unknown errors
					setShowWrongTokenForm(true);
				}
			}
		}
		catch (e: any) {
			processUnhandledError(() => {
				setShowWrongTokenForm(true);
			})(e);
		}
	}
	
	const initializeRecaptcha = async (): Promise<void> => {
		const response: boolean = await recaptchaContext.initializeRecaptcha();
		
		if (!response) {
			console.warn("Unable to initialize recaptcha");
		}
	}
	
	const initialize = async (): Promise<void> => {
		let promises: Promise<void>[] = [
			initializeRecaptcha().then(verifyTokenInfo)
		];
		await Promise.all(promises);
	}
	const [initializePromise] = useState<Promise<void>>(initialize);
	
	const redirectToApplication = () => {
		window.location.href = redirectPath ? redirectPath : Links.getOldPageDefaultLink();
	}

	const WrongTokenForm = (): JSX.Element => {
		const handleClickClose = () => {
			setShowWrongTokenForm(false);
			navigate(Links.getBaseUrlLink());
		}
		
		const handleClickGenerateToken = () => {
			setShowWrongTokenForm(false);
			setShowUserForgotPasswordForm(true);
		}
		
		return (
			<WrongTokenDialog
				onClose={handleClickClose}
				onGenerateNewToken={handleClickGenerateToken}
				variant={WrongTokenVariant.FinishRegistration}
			/>
		);
	}
	
	const submitForm = async (values: any): Promise<any> => {
		let password: string | undefined = values[keyPassword1];
		let password2: string | undefined = values[keyPassword2];
		
		setDisableActiveControls(true);
		setShowProgress(true);
		
		let results: Reasons[] = [];
		try {
			const tokenInfo: string = "ApiUserRegister";
			const recaptchaToken = await recaptchaContext.getToken(tokenInfo);
			
			const registerUserData: UserRegisterData = {
				token         : token,
				password      : password,
				password2     : password2,
				recaptchaToken: recaptchaToken
			}
			
			const response: UserResetPasswordResponse = await userController.registerUser(registerUserData);
			if (response.status === ResetPasswordStatus.Ok) {
                setDisableActiveControls(false);
                setShowProgress(false);
                setShowPairDeviceWithAccountForm(true);
			} else if (response.status === ResetPasswordStatus.Failed) {
				results = response.reasons;
				setDisableActiveControls(false);
				setShowProgress(false);
			}
		}
		catch (err: any) {
			processUnhandledError(() => setRedirectToHomePage(true))(err);
		}
		if (results.includes(Reasons.AppError)) {
			fatalErrorContext.setServerError();
		} else if (results.includes(Reasons.WrongToken) || results.includes(Reasons.MissingToken)) {
			setShowWrongTokenForm(true);
		} else {
			let pwd1: JSX.Element | undefined;
			let pwd2: JSX.Element | undefined;
			if (results.includes(Reasons.PasswordRequired)) {
				pwd1 = validationMessage("set.password.form.password1.required");
			} else if (results.includes(Reasons.WeakPassword)) {
				pwd1 = validationMessage("set.password.form.password1.weak");
			} else if (results.includes(Reasons.PasswordVerificationRequired)) {
				pwd2 = validationMessage("set.password.form.password2.required");
			} else if (results.includes(Reasons.PasswordsDoNotMatch)) {
				pwd2 = validationMessage("set.password.form.password2.match");
			}
			return {
				password1: pwd1,
				password2: pwd2
			}
		}
	}
	const RegistrationForm = (): JSX.Element => {
		return (
			<LoaderGuard loadingInitFunction={initializePromise}>
				<SetPasswordForm
					handleSubmitForm={submitForm}
					disableActiveControls={disableActiveControls}
					showProgress={showProgress}
					keyPassword1={keyPassword1}
					keyPassword2={keyPassword2}
				/>
			</LoaderGuard>
		);
	}
	const GeneratedTokenConfirmationForm = (): JSX.Element => {
		const handleClickProceed = () => {
			setShowGeneratedTokenConfirmationForm(false);
			navigate(Links.getBaseUrlLink());
		}
		return (
			<GeneratedTokenConfirmationDialog onClickProceed={handleClickProceed}/>
		);
	}

    const offerBiometryRegistration = () => {
		if (WebAuthCheck()) {
			biometryController.current.getOfferWebauthnRegistration(false)//no recaptcha
                .then((value: GetBiometryOfferResponse) => {
                    if (value.status === WebauthnServerResponse.Ok && value.offerWebauthnRegistration) {
						setDisableActiveControls(false);
						setShowOfferBiometryForm(true);
					}
					else {
						redirectToApplication();
					}
				})
				.catch(redirectToApplication);
		} else {
			//Redirect to old application
			redirectToApplication();
		}
	};

	const PairDeviceWithAccountForm = (): JSX.Element => {
		const onDeviceRegisterError = (err?: any) => {
			console.warn("Failed to register device", err);
			setDisableActiveControls(false);
			setShowProgress(false);
		}
		const handleSkipButton = () => {
			setShowPairDeviceWithAccountForm(false);
			offerBiometryRegistration();
		}
		const handlePairDevice = () => {
			setDisableActiveControls(true);
			setShowProgress(true);

			// Register Device
			const defaultDeviceName: DeviceName = GetDefaultDeviceName();
			const deviceName = intl.formatMessage(
				{
					id: defaultDeviceName.stringId
				},
				{
					browser: defaultDeviceName.browser,
					os: defaultDeviceName.os,
					osVersion: defaultDeviceName.osVersion
				}
			);
			userController.registerDevice(deviceName)
				.then((response: RegisterUserDeviceResponse) => {
					const status = response.status;
					if (status === RegisterDeviceStatus.Ok) {
						setDisableActiveControls(false);
						setShowProgress(false);
						setShowPairDeviceWithAccountForm(false);
						offerBiometryRegistration();
					} else if (status === RegisterDeviceStatus.Failed && response.reasons.includes(Reasons.AppError)) {
						fatalErrorContext.setServerError();
					} else {
						onDeviceRegisterError(response);
					}
				})
				.catch(processUnhandledError(err => onDeviceRegisterError(err)));
		}
		return (
			<PairDeviceWithAccountDialog
				onClickSkip={handleSkipButton}
				onClickPairDevice={handlePairDevice}
				disableActiveControls={disableActiveControls}
				showProgress={showProgress}
			/>
		);
	}
	
	const handleForgotPasswordRequestSubmissionError = (err: any, formApi: FormApi) => {
		console.log("Failed to send user forgot password email with error: ", err);
		setServerErrorNotification(true);
		formApi.change(keyUserEmail, "");
	}
	const handleForgotPasswordSuccessRequest = () => {
		setDisableActiveControls(false);
		setShowProgress(false);
		setShowUserForgotPasswordForm(false);
		setShowGeneratedTokenConfirmationForm(true);
	}
	const handleForgotPasswordRequestFailed = () => {
		setDisableActiveControls(false);
		setShowProgress(false);
	}
	const submitUserForgotForm = async (values: any, formApi: FormApi): Promise<any> => {
		let userEmail: string | undefined = values[keyUserEmail];
		let results: Reasons[] = [];
		
		try {
			setDisableActiveControls(true);
			setShowProgress(true);
			
			const userForgotPassword = "ApiUserForgotPassword";
			const recaptchaToken: string = await recaptchaContext.getToken(userForgotPassword);
			const userData: UserForgotPasswordData = {
				email         : userEmail,
				recaptchaToken: recaptchaToken
			}
			const response: UserForgotPasswordResponse = await userController.processUserForgotPasswordRequest(userData)
			const status = response.status;
			if (status === ForgotPasswordRequestStatus.Ok) {
				handleForgotPasswordSuccessRequest();
			} else if (status === ForgotPasswordRequestStatus.Failed) {
				results = response.reasons;
				handleForgotPasswordRequestFailed();
			} else {
				handleForgotPasswordRequestSubmissionError(response.reasons, formApi);
			}
		}
		catch (err:any) {
			processUnhandledError((error) => { handleForgotPasswordRequestSubmissionError(error, formApi); })(err);
		}
		
		if (results.includes(Reasons.IncorrectEmail)) {
			return {
				userEmail: validationMessage("user.forgot.password.page.input.errorMessage.invalid.email")
			}
		} else if (results.includes(Reasons.MissingEmail)) {
			return {
				userEmail: validationMessage("user.forgot.password.page.input.errorMessage.validate.email")
			}
		}
	}
	const ForgotPasswordForm = (): JSX.Element => {
		const handleSignInButtonClick = () => {
			setShowUserForgotPasswordForm(false)
			navigate(Links.getBaseUrlLink());
		}
		
		const formTitleText: string = intl.formatMessage({id: "generated.token.forgot.password.page.title"});
		const formMessageText: string = intl.formatMessage({id: "generated.token.forgot.password.page.content"});
		const formCancelButtonText: string = intl.formatMessage({id: "generated.token.forgot.password.page.close.button"});
		const formProceedButtonText: string = intl.formatMessage({id: "generated.token.forgot.password.page.proceed.button"});
		
		return (
			<LoaderGuard loadingInitFunction={initializeRecaptcha()}>
				<UserForgotPasswordForm
					handleSignInButtonClick={handleSignInButtonClick}
					handleSubmitForm={submitUserForgotForm}
					keyUserEmail={keyUserEmail}
					disableActiveControls={disableActiveControls}
					showProgress={showProgress}
					showServerErrorNotification={showServerErrorNotification}
					formMessageText={formMessageText}
					formTitleText={formTitleText}
					cancelButtonText={formCancelButtonText}
					proceedButtonText={formProceedButtonText}
				/>
			</LoaderGuard>
		);
	}
	
	const handleWebAuthenticationUnavailableError = (error: any) => {
		console.error(error);
		setDisableActiveControls(false);
	}
	const onRegisterBiometric = () => {
		biometryController.current
			.registerMyBiometry()
			.then(() => {
				redirectToApplication();
			}).catch(processUnhandledError((error: any) => {
                if (error?.action === BiometryAction.CreateCredentials) {
                    if (error?.reason === BiometryErrorReasons.InternalServerError) {
                        fatalErrorContext.setServerError();
                        return;
					} else if (error?.reason === BiometryErrorReasons.WebAuthenticationNotAvailable) {
						setDisableActiveControls(false);
						snackbarContext.showSnackbar(WebauthnNotAvailableSnackbarID);
						return;
					}
                }
				handleWebAuthenticationUnavailableError(error);
			}));
    }

    const OfferBiometryForm = (): JSX.Element => {
		const handleActivateButton = () => {
			setDisableActiveControls(true);
			onRegisterBiometric();
		}

		const handleAskLaterButton = () => {
			redirectToApplication();
		}

		const handleDontAskAgainButton = () => {
			setDisableActiveControls(true);
			biometryController.current.setOfferWebauthnRegistration(false, false) //no recaptcha
				.then(redirectToApplication)
				.catch(redirectToApplication);
		}

		return (
			<PasskeyRegistrationForm
				disableActiveControls={disableActiveControls}
				handleActivateButton={handleActivateButton}
				handleAskLaterButton={handleAskLaterButton}
				handleDontAskAgainButton={handleDontAskAgainButton}
			/>
		);
	}

 	const renderWelcomeForm = (): JSX.Element => {
		const handleSignInButtonClick = () => {
			setShowWelcomeForm(false)
		};
		return (
			<WelcomeForm handleClick={handleSignInButtonClick} />
		) 
	};

	if (showWelcomeForm) {
		return renderWelcomeForm();
	} else if (redirectToHomePage) {
		navigate(Links.getBaseUrlLink());
		return null;
	} else if (showWrongTokenForm) {
		return WrongTokenForm();
	} else if (showPairDeviceWithAccountForm) {
		return PairDeviceWithAccountForm();
	} else if (showGeneratedTokenConfirmationForm) {
		return GeneratedTokenConfirmationForm();
	} else if (showUserForgotPasswordForm) {
		return ForgotPasswordForm();
	} else if (showOfferBiometryForm) {
		return OfferBiometryForm();
	} else {
		return RegistrationForm();
	}
}
