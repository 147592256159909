import { Context, createContext } from "react";

import { Theme } from "tm-controls";

export interface ThemeContextInterface {
    theme: Theme;
}

export const ThemeContext: Context<ThemeContextInterface> = createContext<ThemeContextInterface>({
    theme: {
        primary: {main: "#005db7"},
        secondary: {main: "#565F71"},
        error: {main: "#BA1B1B"},
        mode: "light"
    }
});
