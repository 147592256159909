export interface RecaptchaInfo {
    siteKey: string;
    javaScriptUri: string;
}

export const loadRecaptchaScripts = (javascriptUri: string): Promise<boolean> => {
    return new Promise<boolean>(
        function (
            resolve: (status: boolean) => void,
            reject: any
        ) {
            const jsScript = document.createElement("script");
            jsScript.addEventListener("load", () => {
                resolve(true);
            });
            jsScript.addEventListener("error", () => {
                reject();
            });
            jsScript.src = javascriptUri;
            jsScript.async = true;
            document.body.appendChild(jsScript);
        }
    );
}

export const readGoogleRecaptchaToken = (siteKey: string, action: string): Promise<string> => {
    return new Promise<string>(
        function (resolve: (token: string) => void, reject: any) {
            grecaptcha.enterprise.ready(function () {
                    grecaptcha.enterprise.execute(
                        siteKey,
                        {
                            action: action
                        }
                    ).then(function (token: string) {
                        resolve(token);
                    });
                }
            )
        });
}
