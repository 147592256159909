import { makeStyles } from "@mui/styles";
import { ellipses, Theme } from "tm-controls";

export const ControlPageStyles = makeStyles((theme: Theme) => ({
    dialogButton: {
        lineHeight: "20px !important"
    },
    listItem: {
        display: "flex",
        flexDirection: "column",
        padding: "12px 16px 12px 16px"
    },
    listItemName: {
        fontFamily: "'Open Sans'",
        fontWeight: "600",
        fontSize: "14px",
        lineHeight: "24px",
        letterSpacing: "0.22px",
        color: theme.text?.primary,
        maxWidth: "358px",
        ...ellipses
    },
    listItemEmail: {
        fontFamily: "Roboto",
        fontWeight: "400",
        fontSize: "12px",
        lineHeight: "16px",
        letterSpacing: "0.4px",
        color: theme.text?.secondary,
        maxWidth: "358px",
        ...ellipses
    },
}))
