import React from "react";
import { Context, createContext } from "react";

export interface SnackbarContextInterface {
	activeSnackbar: string;
	showSnackbar: (id: string) => void;
	hideSnackbar: (id: string) => void;
}

export const SnackbarContext: Context<SnackbarContextInterface>
	= createContext<SnackbarContextInterface>({
		activeSnackbar: "",
		showSnackbar: () => { },
		hideSnackbar: () => { }
	});

export const useSnackbars: () => SnackbarContextInterface
	= () => {
		return React.useContext(SnackbarContext);
	};