import { AxiosError, AxiosResponse } from "axios";

import { Action, ActionMethod, BiometryAction, BiometryError, ContentType, BiometryErrorReasons, ServerAdapterInterface } from "data";
import { Reasons, WebauthnServerResponse } from "data/api";

import { base64ToBuffer, bufferToBase64, stringToBuffer, WebAuthCheck, base64UrlToBase64 } from "utils";

import { RecaptchaContextInterface } from "contexts";
import { convertUnhandledError } from "data/handled-error";

export interface BiometryControllerInterface {
    registerMyBiometry(): Promise<void>;
    
    signInByBiometric(userId: number|undefined, conditional:boolean): Promise<void>;
    abortSignInByBiometry():void;

    setOfferWebauthnRegistration(state: boolean, useRecaptcha: boolean, userId?: number): Promise<SetBiometryOfferResponse>;
    getOfferWebauthnRegistration(useRecaptcha: boolean, userId?: number): Promise<GetBiometryOfferResponse>;
}

export interface CreateRequestParams {
    attestation: AttestationConveyancePreference;
    attestationFormats: Array<string>;
    authenticatorAttachment: AuthenticatorAttachment;
    challenge: string;
    COSEAlgorithmIdentifiers: Array<COSEAlgorithmIdentifier>;
    excludeCredentials: Array<string>;
    relyingPartyId: string;
    relyingPartyName: string;
    requireResidentKey: boolean;
    residentKey: string;
    timeout: number;
    userDisplayName: string;
    userId: string;
    userName: string;
    userVerification: UserVerificationRequirement;
}

export interface CreateRequestParamsResponse {
    createStructure: CreateRequestParams;
    status: string;
    reasons?: string[];
}

export interface ProcessCreateCredentialsResponse {
    status: string;
    reasons?: string[];
}

export interface GetCreateStructureParams {
    allowCredentials: Array<string>;
    relyingPartyId: string;
    userVerification: UserVerificationRequirement;
    challenge: string;
    timeout: number;
}

export interface GetStructureResponse {
    getStructure: GetCreateStructureParams;
    timeout: number;
    status: string;
    reasons?: string[];
}


export interface ProcessGetResponse {    
    status: string;
    reasons?: string[];
    email?: string;
}

export interface SetBiometryOfferResponse {
    status: string;
    reasons?: string[];
}

export interface GetBiometryOfferResponse {
    offerWebauthnRegistration: boolean;
    status: string;
    reasons?: string[];
}

export enum WebauthnExceptions {
    InvalidStateError = "InvalidStateError"
}

export interface PublicKeyCredentialCreationOptionsV2 extends PublicKeyCredentialCreationOptions {
    attestationFormats?: string[];
}

export class BiometryController implements BiometryControllerInterface {
    private getCreateRequestParamsAction: Action = {
        href: "/api/webauthn/create",
        method: ActionMethod.Get,
        accept: ContentType.Json
    };

    private processCreateCredentialsAction: Action = {
        href: "/api/webauthn/create",
        method: ActionMethod.Post,
        accept: ContentType.Json
    };
    
    private getRequestStructureAction: Action = {
        href: "/api/webauthn/get",
        method: ActionMethod.Get,
        accept: ContentType.Json
    }
    
    private processGetCredentialAction: Action = {
        href: "/api/webauthn/get",
        method: ActionMethod.Post,
        accept: ContentType.Json
    }

    private setOfferWebauthnState: Action = {
        href: "/api/webauthn/offer",
        method: ActionMethod.Post,
        accept: ContentType.Json
    };

    private getOfferWebauthnState: Action = {
        href: "/api/webauthn/offer",
        method: ActionMethod.Get,
        accept: ContentType.Json
    };

    private abortController: AbortController|null = null;
    private autocompleteTimer: number|undefined = undefined;

    private convertCreateRequestParamsToPKOptions(createParams: CreateRequestParams): PublicKeyCredentialCreationOptions {
        let authenticatorSelection: AuthenticatorSelectionCriteria = {
            requireResidentKey: createParams.requireResidentKey,
            userVerification: createParams.userVerification
        };
        if (createParams.authenticatorAttachment) {
            authenticatorSelection.authenticatorAttachment = createParams.authenticatorAttachment;
        }

        let converted: PublicKeyCredentialCreationOptionsV2 = {
            attestation: createParams.attestation,
            attestationFormats: createParams.attestationFormats,
            authenticatorSelection: authenticatorSelection,
            challenge: base64ToBuffer(createParams.challenge),
            pubKeyCredParams: createParams.COSEAlgorithmIdentifiers.map(item => (
                {
                    type: "public-key",
                    alg: item
                })
            ),
            rp: {
                id: createParams.relyingPartyId,
                name: createParams.relyingPartyName
            },
            timeout: createParams.timeout,
            user: {
                displayName: createParams.userDisplayName,
                id: stringToBuffer(createParams.userId),
                name: createParams.userName
            }
        };

        if (createParams.excludeCredentials?.length > 0) {

            converted.excludeCredentials = createParams.excludeCredentials.map(item => (
                {
                    id: base64ToBuffer(item),
                    type: "public-key"
                }));
        }

        return converted;
    }

    public async signInByBiometric(userId: number|undefined, conditional:boolean): Promise<void> {
        let webAuth: boolean = WebAuthCheck();
        if (!webAuth) {
            throw new BiometryError(BiometryAction.GetCredentials, BiometryErrorReasons.WebAuthenticationNotAvailable);            
        }
        
        //we want to propagate exception to the caller -> no catch here 
        let getResponse: GetStructureResponse = await this.getCreateGetStructure(userId);


        if (getResponse.status === "not found") {
            throw new BiometryError(BiometryAction.GetCredentials, BiometryErrorReasons.CredentialsNotAvailable);
        } else if (getResponse.status === WebauthnServerResponse.Failed) {
            if (getResponse.reasons?.includes(Reasons.AppError)) {
                throw new BiometryError(BiometryAction.GetCredentials, BiometryErrorReasons.InternalServerError);
            }
        } else if (getResponse.status !== WebauthnServerResponse.Ok) {
            throw new BiometryError(BiometryAction.GetCredentials, BiometryErrorReasons.InternalServerError);
        }
       
        //convert parameters
        let getOptions: PublicKeyCredentialRequestOptions = this.convertGetCreateRequestParamsResponse(getResponse.getStructure);

        let credential: Credential | null = null;
        let credentialRequestOptions: CredentialRequestOptions = { publicKey: getOptions };

        this.abortSignInByBiometry();
        this.abortController = new AbortController();
        credentialRequestOptions.signal = this.abortController.signal;
        if (conditional) {
            //TODO: -- 'hack' because of old typescript -> replace when new typescript will support "conditional"
            //credentialRequestOptions.mediation = "conditional";
            credentialRequestOptions.mediation = "conditional" as CredentialMediationRequirement;
            //TODO: -- end of 'hack'

            let autocompleteTimeout: number = getResponse.timeout;
            if (autocompleteTimeout > 0) {
                this.autocompleteTimer = window.setTimeout(() => {
                    if (document.activeElement instanceof HTMLInputElement) {
                        let activeElement = document.activeElement as HTMLInputElement;
                        if (activeElement?.blur && activeElement?.getAttribute("autocomplete")?.includes("webauthn")) {
                            activeElement.blur();
                        }
                    }
                    this.abortSignInByBiometry();
                }, autocompleteTimeout * 1000);
            }
        }

        try {
            credential = await window.navigator.credentials.get(credentialRequestOptions);
        } catch (error: any) {
            if (error?.name === "NotAllowedError")
                throw new BiometryError(BiometryAction.GetCredentials, BiometryErrorReasons.WebAuthenticationCanceled);
            throw new BiometryError(BiometryAction.GetCredentials, error);
        }

        if (credential && ((credential as PublicKeyCredential).response instanceof AuthenticatorAssertionResponse)) {
            try {
                let procesGetResponse: ProcessGetResponse = await this.processGetCredentials(credential as PublicKeyCredential);
                                
                if (procesGetResponse.status === WebauthnServerResponse.Failed) {
                    if (procesGetResponse.reasons?.includes(Reasons.EmailNotVerified)) {
                        throw new BiometryError(BiometryAction.GetCredentials, BiometryErrorReasons.InternalServerError);
                    } else if (procesGetResponse.reasons?.includes(Reasons.InvalidCredentials)) {
                        throw new BiometryError(BiometryAction.GetCredentials, BiometryErrorReasons.FailedToLogin);
                    } else if (procesGetResponse.reasons?.includes(Reasons.AccountDisabled)) {
                        throw new BiometryError(BiometryAction.GetCredentials, { reason: BiometryErrorReasons.AccountDisabled, email: procesGetResponse.email });
                    } else if (procesGetResponse.reasons?.includes(Reasons.MissingRequiredParameter)) {
                        throw new BiometryError(BiometryAction.GetCredentials, BiometryErrorReasons.FailedToLogin);
                    } else if (procesGetResponse.reasons?.includes(Reasons.InvalidParameter)) {
                        throw new BiometryError(BiometryAction.GetCredentials, BiometryErrorReasons.FailedToLogin);
                    }                   
                }
                if (procesGetResponse.status !== WebauthnServerResponse.Ok) {
                    throw new BiometryError(BiometryAction.GetCredentials, BiometryErrorReasons.InternalServerError);
                }              

                return;
            } catch (error: any) {
                throw error;
            }
        } else {
            throw new BiometryError( BiometryAction.GetCredentials, BiometryErrorReasons.InvalidCredentials);
        }
    };

    public abortSignInByBiometry():void{
        if(this.abortController){
            this.abortController.abort();
            this.abortController=null;
        }
        
        if(this.autocompleteTimer){
            window.clearTimeout(this.autocompleteTimer);
            this.autocompleteTimer = undefined;
        }
    }

    private getCreateRequestParams(): Promise<CreateRequestParamsResponse> {
        return new Promise<CreateRequestParamsResponse>(async (
            resolve: (value: CreateRequestParamsResponse) => void,
            reject: (error: Error) => void
        ) => {
            this.serverAdapter.doAction<CreateRequestParamsResponse>(this.getCreateRequestParamsAction)
                .then((value: AxiosResponse<CreateRequestParamsResponse>) => {
                    resolve(value.data);
                })
                .catch((error: AxiosError<any>) => {
                    reject(convertUnhandledError(error, (error)=>(new BiometryError(BiometryAction.GetCreateRequestParams, error))));
                });
        }
        );
    }
    
    private convertGetCreateRequestParamsResponse(getParams:  GetCreateStructureParams): PublicKeyCredentialRequestOptions {
        let converted: PublicKeyCredentialRequestOptions = {
            challenge: base64ToBuffer(getParams.challenge),
            timeout: getParams.timeout,
            rpId: getParams.relyingPartyId,
            userVerification: getParams.userVerification
        };

        if (getParams.allowCredentials?.length > 0) {
            converted.allowCredentials = getParams.allowCredentials.map(item => (
                {                   
                    id: base64ToBuffer(base64UrlToBase64(item)),
                    type: "public-key"
                }
            ));
        }

        return converted;
    }

    private processCreateCredentials(credential: PublicKeyCredential): Promise<ProcessCreateCredentialsResponse> {
        const secureContextResponse: AuthenticatorAttestationResponse = credential.response as AuthenticatorAttestationResponse;

        const data = {
            type: credential.type,
            id: credential.id,
            rawId: bufferToBase64(credential.rawId),
            response: {
                attestationObject: bufferToBase64(secureContextResponse.attestationObject),
                clientDataJSON: bufferToBase64(secureContextResponse.clientDataJSON),
                transports: secureContextResponse.getTransports?secureContextResponse.getTransports():[]
            }
        };

        return new Promise<ProcessCreateCredentialsResponse>(async (
            resolve: (value: ProcessCreateCredentialsResponse) => void,
            reject: (error: Error) => void
        ) => {
            this.serverAdapter.doAction<ProcessCreateCredentialsResponse>(this.processCreateCredentialsAction, data)
                .then((value: AxiosResponse<ProcessCreateCredentialsResponse>) => {
                    resolve(value.data);
                })
                .catch((error: AxiosError<any>) => {
                    reject(convertUnhandledError(error, (error) => (new BiometryError(BiometryAction.ProcessCreateCredentials, error))));
                });
        }
        );
    }
    
    private processGetCredentials(credential: PublicKeyCredential): Promise<any> {
        const encodedCredentialResponse: AuthenticatorAssertionResponse = credential.response as AuthenticatorAssertionResponse;
        
        const encodedCredential = {
            id: credential.id,
            type: credential.type,
            rawId: bufferToBase64(credential.rawId),
            response: {
                clientDataJSON: bufferToBase64(encodedCredentialResponse.clientDataJSON),
                authenticatorData: bufferToBase64(encodedCredentialResponse.authenticatorData),
                signature: bufferToBase64(encodedCredentialResponse.signature),
                userHandle: encodedCredentialResponse.userHandle? bufferToBase64(encodedCredentialResponse.userHandle) : null,            }
        };
        
        return new Promise<any>( async (
            resolve: (value: any) => void,
            reject: (error: Error) => void
        ) => {

            const action = Object.assign({}, this.processGetCredentialAction);

            //need to wait to be protected by recaptcha, error shoud be propagated
            await this.assignRecaptchaToken(action, "ApiWebAuthnGetPost");

            this.serverAdapter.doAction<any>(action, encodedCredential)
                .then((value: AxiosResponse<any>) => {
                    resolve(value.data);
                })
                .catch((error: AxiosError<any>) => {
                    reject(convertUnhandledError(error, (error)=>(new BiometryError(BiometryAction.ProcessCreateGetCredentials, error))));
                });
        })
    }
     
    constructor(
        private serverAdapter: ServerAdapterInterface,
        private recaptchaContext?: RecaptchaContextInterface) {
    }

    public setOfferWebauthnRegistration(state: boolean, useRecaptcha: boolean, userId?: number): Promise<SetBiometryOfferResponse> {
        return new Promise<SetBiometryOfferResponse>(async (
            resolve: (response: SetBiometryOfferResponse) => void,
            reject: (error: Error) => void
        ) => {
            let postData:any =
            {                
                offerWebauthnRegistration: state
            };

            if (userId) {
                postData["userId"] = userId;
            }

            const action = Object.assign({}, this.setOfferWebauthnState);
            if (useRecaptcha) {
                await this.assignRecaptchaToken(action, "ApiWebAuthnOffer");
            }

            this.serverAdapter.doAction<SetBiometryOfferResponse>(action, postData)
                .then((value: AxiosResponse<SetBiometryOfferResponse>) => {
                    resolve(value.data);                    
                })
                .catch((error: Error) =>{
                    reject(convertUnhandledError(error, (error)=>(new BiometryError(BiometryAction.SetOfferWebauthnState, error))));
                });
        });
    }

    public getOfferWebauthnRegistration(useRecaptcha: boolean, userId?: number): Promise<GetBiometryOfferResponse> {
        return new Promise<GetBiometryOfferResponse>(async(
            resolve: (offerWebauthnRegistration: GetBiometryOfferResponse) => void,
            reject: (error: Error) => void
        ) => { 
            const action = Object.assign({}, this.getOfferWebauthnState);
            if (userId) {
                action.href += "?userId=" + userId.toString();
            }
            
            if (useRecaptcha) {
                await this.assignRecaptchaToken(action, "ApiWebAuthnOffer");                
            }
            this.serverAdapter.doAction<GetBiometryOfferResponse>(action)
                .then((value: AxiosResponse<GetBiometryOfferResponse>) => {
                        resolve(value.data);
                })
                .catch((error: AxiosError<any>) => {
                    reject(convertUnhandledError(error, (error) => (new BiometryError(BiometryAction.GetOfferWebauthnState, error))));
                });
        });
    }

    public async registerMyBiometry(): Promise<void> {
        let webAuth: boolean = WebAuthCheck();
        if (!webAuth) {
            throw new BiometryError( BiometryAction.CreateCredentials, BiometryErrorReasons.WebAuthenticationNotAvailable);            
        }

        let creationOptions: PublicKeyCredentialCreationOptions | undefined = undefined;

        try {
            const response: CreateRequestParamsResponse = await this.getCreateRequestParams();
            if (response.status === WebauthnServerResponse.Ok) {
                creationOptions = this.convertCreateRequestParamsToPKOptions(response.createStructure);
            } else if (response.reasons?.includes(Reasons.AppError)) {
                throw new BiometryError(BiometryAction.CreateCredentials, BiometryErrorReasons.InternalServerError);
            } else {
                // throw error on other failures
                throw new BiometryError(BiometryAction.CreateCredentials, BiometryErrorReasons.InternalServerError);
            }
        } catch (error: any) {
            throw error;
        }

        if (!creationOptions) {
            throw new BiometryError( BiometryAction.CreateCredentials, BiometryErrorReasons.OptionsNotAvailable);            
        }

        const credOptions: CredentialCreationOptions = {
            publicKey: creationOptions
        };

        let credential: Credential | null = null;

        try {
            credential = await window.navigator.credentials.create(credOptions);
        } catch (error: any) {
            // if user has already registered its credentials, do nothing (resolve, return no error)
            if (error.name === WebauthnExceptions.InvalidStateError) {
                try {
                    const value: SetBiometryOfferResponse = await this.setOfferWebauthnRegistration(false, false);//no recaptcha
                    if (value.status === WebauthnServerResponse.Ok) {
                        return;
                    }
                    else {
                        //in any other cases when status is WebauthnServerResponse.Failed or .Forbidden
                        //and any reasons: Reasons.InvalidParameter or Reasons.AppError
                        //we always want to proceed as InternalServerError
                        throw new BiometryError(BiometryAction.CreateCredentials, BiometryErrorReasons.InternalServerError);
                    }
                } catch (error) {
                    throw convertUnhandledError(error, (error) => (new BiometryError(BiometryAction.SetOfferWebauthnState, undefined)));
                }
            }

            throw new BiometryError(BiometryAction.CreateCredentials, error);            
        }

        if (credential && ((credential as PublicKeyCredential).response instanceof AuthenticatorAttestationResponse)) {
            try {
                const response: ProcessCreateCredentialsResponse = await this.processCreateCredentials(credential as PublicKeyCredential);
                if (response.status === WebauthnServerResponse.Ok)
                    return;

                if (response.reasons?.includes(Reasons.AppError)) {
                    throw new BiometryError(BiometryAction.CreateCredentials, BiometryErrorReasons.InternalServerError);
                }

                // throw error on other failures
                throw new BiometryError(BiometryAction.CreateCredentials, BiometryErrorReasons.InternalServerError);
            } catch (error: any) {
                throw error;
            }
        } else {
            throw new BiometryError(BiometryAction.CreateCredentials, BiometryErrorReasons.InvalidCredentials);            
        }
    };

    private assignRecaptchaToken(action: Action, actionId: string): Promise<void> {
        return new Promise<void>(async (
            resolve: () => void,
            reject: (error: AxiosError<any>) => void
        )=> {
            this.recaptchaContext?.getToken(actionId).then((recaptchaToken: string) => {
                action.recaptchaToken = recaptchaToken;
                resolve();
            }).catch((error: AxiosError<any>) => {
                reject(error);
            });
        });
    }
    
    private getCreateGetStructure(userId?: number): Promise<GetStructureResponse > {
        return new Promise<GetStructureResponse>(async (
            resolve: (value: GetStructureResponse) => void,
            reject: (error: Error) => void
        ) => {
            const action = Object.assign({}, this.getRequestStructureAction);
            if(userId){
                action.href+= "?userId=" + userId.toString();
            }

            this.serverAdapter.doAction<GetStructureResponse>(action)
                .then((value: AxiosResponse<GetStructureResponse>) => {                    
                        resolve(value.data);    
                }).catch((error: AxiosError<any>) => {
                    reject(convertUnhandledError(error, (error) => (new BiometryError(BiometryAction.GetCreateGetRequestParams, error))));
                })
        });
    }
}
