import { Typography } from '@mui/material';
import { InformationDialog } from "components/dialogs/information-dialog";
import React from 'react';

import { FormattedMessage } from "react-intl";
import { Button } from "tm-controls";

import { WrongTokenDialogProps, WrongTokenVariant } from "./wrong-token-dialog-props";

export const WrongTokenDialog = ({ onGenerateNewToken, onClose, variant }: WrongTokenDialogProps) => {
	let titleString: string = "reset.password.expired.token.page.title";
	let contentString: string = "reset.password.expired.token.page.message";
	let closeButtonString: string = "generic.action.signIn";
	let generateTokenString: string = "reset.password.expired.token.page.generate.token.button";
	
	if (variant === WrongTokenVariant.FinishRegistration) {
		titleString = "finish.registration.expired.token.page.title";
		contentString = "finish.registration.expired.token.page.message";
		closeButtonString = "finish.registration.expired.token.page.close.button";
		generateTokenString = "finish.registration.expired.token.page.generate.token.button";
	}
	
	const dialogHeaderElement = (): JSX.Element => (
		<Typography data-testid="expired-token-dialog-page-title" variant="h5">
			<FormattedMessage id={titleString}/>
		</Typography>
	);
	const dialogContentElement = (): JSX.Element => (
		<Typography data-testid="expired-token-dialog-page-description" variant="subtitle2">
			<FormattedMessage id={contentString}/>
		</Typography>
	);
	const dialogFooterElement = (): JSX.Element => (
		<>
			<Button
				data-testid="close-button"
				onClick={onClose}
				color="primary"
				variant="Link"
			>
				<FormattedMessage
					id={closeButtonString}
				/>
			</Button>
			<Button
				data-testid="generate-token-button"
				onClick={onGenerateNewToken}
				variant="Filled"
				color="primary"
			>
				<FormattedMessage
					id={generateTokenString}
				/>
			</Button>
		</>
	);
	
	return (
		<InformationDialog
		 	longAction={true}
			informationTitleContainer={dialogHeaderElement()}
			informationContentContainer={dialogContentElement()}
			informationButtonContainer={dialogFooterElement()}
		/>
	);
}
