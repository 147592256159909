import React, { useContext } from 'react';
import { footerClasses, FooterCopyrightInfo, FooterRoot } from "./page-footer-styles";
import { Typography } from "@mui/material";
import { ServerVersionContext, ServerVersionContextInterface } from "contexts";

export const PageFooter = () => {
    const { copyright }: ServerVersionContextInterface = useContext(ServerVersionContext);

    return (
        <>
            <FooterRoot className={footerClasses.root}>
                <FooterCopyrightInfo className={footerClasses.copyrightInfo}>
                    <Typography color="textSecondary" data-testid={'copyright-message'} variant="subtitle2">
                        {copyright}
                    </Typography>
                </FooterCopyrightInfo>
            </FooterRoot>
        </>
    );
}
