import { ListItemButton as MuiListItemButton } from "@mui/material"
import { ListItemButtonProps } from "./list-item-button-props";
import { SxProps } from "@mui/system";
import { Theme } from "@mui/material/styles";
import { useContext } from "react";
import { ThemeContext } from "tm-controls";

export const ListItemButton = ({onClick, children, disableGutters, divider, ...rest}: ListItemButtonProps) => {
    const {theme: { common }} = useContext(ThemeContext);
    
    let oSx: SxProps<Theme> | null = {
        display: "flex",
        padding: "0"
    }
    
    if (divider) {
        oSx={
            ...oSx,
            borderBottomWidth: "1px",
            borderBottomStyle: "solid",
            borderBottomColor: common?.borderColor,
        }
    }
    
    return (
        <MuiListItemButton
            {...rest}
            sx={oSx}
            divider={divider}
            disableGutters={disableGutters}
            onClick={onClick}
        >
            {children}
        </MuiListItemButton>
    );
}
