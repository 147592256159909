import React from "react";
import { CircularProgress } from "@mui/material";
import { LoadingContentStyles } from "./loading-content-styles";
import { LoadingContentProps } from "./loading-content-props";

export const LoadingContent = ({loading, children}: LoadingContentProps) => {
    const classes = LoadingContentStyles();
    
    let childrenContainerStyle = "";
    if (loading) {
        childrenContainerStyle = classes.text;
    }
    
    return (
        <div>
            {loading && (
                <CircularProgress
                    color="inherit"
                    classes={{root: classes.progress}}
                    size={18}
                />
            )}
            <div className={childrenContainerStyle}>
                {children}
            </div>
        
        </div>
    )
}
