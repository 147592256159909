import { FieldMetaState } from "react-final-form";

export function getFormError<T>(meta: FieldMetaState<T>):any{
    let error = meta.error;
    if (!meta.touched) {
        error = false;
    }
    
    if (!error && meta.submitError && !meta.modifiedSinceLastSubmit) {
        error = meta.submitError;
    }
    return error;
}
