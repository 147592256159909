import React from "react";
import { useSnackbars } from "tm-controls/snackbar-provider";

export const SnackbarHider = () => {
	const snackbars = useSnackbars();
	
	React.useEffect(()=>{
		snackbars.showSnackbar("");
		// eslint-disable-next-line react-hooks/exhaustive-deps
	},[]);
	return null;
}