import React from "react";
import { Grid, Typography } from '@mui/material';
import { FormattedMessage, useIntl } from "react-intl";

import { PageFooter } from "components/page-footer";
import { PageMetadata } from "components/page-metadata";
import { RecaptchaBadge } from 'components/recaptcha-badge';
import {
    DesktopOnly,
    PageButtonsContainer,
    PageContainer,
    PageContentContainer,
    PageHeaderContainer,
    PageLogoContainer,
    PageNotificationContainer,
    PageTitleContainer
} from "components/controls";
import { Button, LoadingContent, Spacer } from "tm-controls";
import { Field, Form, FormProps, FormRenderProps } from "react-final-form";
import { FormErrorPlaceholder, InputAdapter } from "components/form-adapters";

import { userForgotPasswordFormClasses, UserFormRoot } from "./user-forgot-password-form-styles";
import { UserForgotPasswordFormProps } from "./user-forgot-password-form-props";
import { Page } from "components/page";

const CreateForm = Form as React.FC<FormProps>;

export const UserForgotPasswordForm = ({
                                           handleSignInButtonClick,
                                           handleSubmitForm,
                                           keyUserEmail,
                                           disableActiveControls,
                                           showProgress,
                                           showServerErrorNotification,
                                           formMessageText, formTitleText, cancelButtonText, proceedButtonText
                                       }: UserForgotPasswordFormProps) => {
    const intl = useIntl();
    
    const submitFormID: string = "UserForgotPasswordForm";
    
    const logoPath: string = intl.formatMessage({
                                                   id: "application.logo"
                                               });
    
    const renderForm = ({handleSubmit}: FormRenderProps): JSX.Element => {
        return (
            <PageContentContainer>
                <UserFormRoot
                    className={userForgotPasswordFormClasses.form}
                    autoComplete="off"
                    data-testid="form"
                    id={submitFormID}
                    onSubmit={handleSubmit}
                    noValidate
                >
                    <Grid container rowSpacing={'16px'} direction={"column"}>
                        <Grid item>
                            <Typography data-testid="user-forgot-password-page-description" variant="subtitle2">
                                {formMessageText}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Field
                                type="email"
                                inputmode="email"
                                data-testid="email-field"
                                component={InputAdapter}
                                defaultValue=""
                                disabled={disableActiveControls}
                                label={<FormattedMessage id="user.forgot.password.page.input.label" />}
                                helperText=""
                                name={keyUserEmail}
                                variant="outlined"
                                sx={{flexBasis: "49", flexShrink: 1}}
                            />
                        </Grid>
                        {showServerErrorNotification &&
                            <Grid item>
                                <PageNotificationContainer>
                                    <FormattedMessage id="user.forgot.password.page.notification.server.error" />
                                </PageNotificationContainer>
                            </Grid>
                        }
                    </Grid>
                </UserFormRoot>
                {!showServerErrorNotification &&
                    <DesktopOnly>
                        <FormErrorPlaceholder name={keyUserEmail} />
                    </DesktopOnly>
                }
            </PageContentContainer>
        );
    }
    
    return (
        <Page>
            <PageMetadata />
                <PageContainer>
                    <PageHeaderContainer>
                        <PageLogoContainer>
                            <img data-testid="application-logo" height="28px" src={logoPath} alt="logo"/>
                        </PageLogoContainer>
                        <PageTitleContainer>
                            <Typography data-testid="user-forgot-password-title" variant="h5">
                                {formTitleText}
                            </Typography>
                        </PageTitleContainer>
                    </PageHeaderContainer>
                    <CreateForm
                        onSubmit={handleSubmitForm}
                        render={renderForm}
                    />
                    <PageButtonsContainer marginLeft={true}>
                        <Button
                            variant="Link"
                            color="primary"
                            data-testid="sign-in-button"
                            disabled={disableActiveControls && !showServerErrorNotification}
                            onClick={handleSignInButtonClick}
                        >
                            {cancelButtonText}
                        </Button>
                        <DesktopOnly>
                            <Spacer />
                        </DesktopOnly>
                        <Button
                            variant="Filled"
                            data-testid="submit-button"
                            disabled={disableActiveControls}
                            form={submitFormID}
                            type="submit"
                            color="primary"
                        >
                            <LoadingContent loading={showProgress && !showServerErrorNotification}>
                                {proceedButtonText}
                            </LoadingContent>
                        </Button>
                    </PageButtonsContainer>
                </PageContainer>
            <RecaptchaBadge />
            <PageFooter />
        </Page>
    );
    
}
