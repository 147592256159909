import { AxiosResponse } from "axios";
import { Action, ActionMethod, ContentType, Reasons, ResponseStatus, ServerAdapterInterface } from "data";

export interface TokenInfoResponse {
	status: ResponseStatus;
	reasons: Reasons[];
	username: string;
	email: string;
}

export interface TokenInfoData {
	token: string | null;
	recaptchaToken?: string;
}

export interface TokenControllerInterface {
	getTokenInfo(tokenData: TokenInfoData): Promise<TokenInfoResponse>
}

export class TokenController implements TokenControllerInterface {
	constructor(private serverAdapter: ServerAdapterInterface) {
	}
	
	private getTokenInfoAction: Action = {
		href: "/api/token/info",
		method: ActionMethod.Get,
		accept: ContentType.Json
	}
	
	getTokenInfo(tokenData: TokenInfoData): Promise<TokenInfoResponse> {
		return new Promise<TokenInfoResponse>(
			(
				resolve: (response: TokenInfoResponse) => void,
				reject: (reason?: any) => void
			) => {
				const action = Object.assign({}, this.getTokenInfoAction);
				if (tokenData.token) {
					action.href += "?token=" + tokenData.token;
				}
				action.recaptchaToken = tokenData.recaptchaToken;
				
				this.serverAdapter.doAction<TokenInfoResponse>(action)
				    .then((response: AxiosResponse<TokenInfoResponse>) => {
					    if (response.data.status) {
						    resolve(response.data);
					    } else {
						    reject();
					    }
				    })
				    .catch(reject)
			}
		)
	}
}
