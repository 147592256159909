import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";

export const PageNotificationContainerStyles = makeStyles((theme: Theme) => ({
    pageNotification: {
        padding: "11px 15px 11px 15px",
        backgroundColor: theme.palette.info.main,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        borderWidth: "1px",
        borderStyle: "solid",
        borderColor: theme.palette.info.contrastText,
        borderRadius: "4px",
        gap: "10px",
        flexGrow: 0,
        width: "100%"
    },
    pageNotificationMessage: {
        textAlign: "center",
        lineHeight: "24px",
        letterSpacing: "0.22px",
        fontFamily: "'Open Sans'",
        fontSize: "14px",
        fontWeight: 400,
    }
}));
