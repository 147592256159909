import { BlockedAccountDialog, ForgotPasswordConfirmationDialog } from "components/dialogs";

import { LoaderGuard } from "components/loader-guard";

import {
    SecondFactorAuthenticationForm,    
    SignInForm,
    SignInRememberedUserForm,
    UserForgotPasswordForm,
    UsersListForm
} from "components/pages";
import {
    CurrentUserContext,
    CurrentUserContextInterface,
    DeviceUserListContext,
    DeviceUserListContextInterface,
    PinCodeAttemptsContext,
    PinCodeAttemptsInfo,
    PinCodeAttemptsInterface,
    RecaptchaContext,
    RecaptchaContextInterface
} from "contexts";
import {
    BiometryAction,
    BiometryController,
    BiometryControllerInterface,
    CurrentUser,
    BiometryErrorReasons,
    ForgotPasswordRequestStatus,
    GetPinCodeAttemptsResponse,
    LoginServerStatus,
    PinCodeStatus,
    Reasons,
    RegisterDeviceStatus,
    RegisterUserDeviceResponse,
    ServerAdapter,
    UserController,
    UserControllerInterface,
    UserForgotPasswordData,
    UserForgotPasswordResponse,
    UserListItem,
    UserLoginData,
    UserPinCodeResponse,
    WebauthnServerResponse,
    SkipUserPinCodeResponse,
    ServerResponse
} from "data";
import { FormApi } from "final-form";
import React, { useContext, useEffect, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ConditionalMediationAvailableCheck, DeviceName, GetDefaultDeviceName, Links, StringMethods, WebAuthCheck } from "utils";
import { PasskeyRegistrationForm } from "components/pages/passkey-registration-form";

import { Snackbar, defaultSnackbarAutohideDuration } from "tm-controls/snackbar";
import { Button } from "tm-controls/button";
import { FatalErrorContext } from "contexts/fatal-error-context";
import { useSnackbars } from "tm-controls/snackbar-provider";
import { GetBiometryOfferResponse, SetBiometryOfferResponse } from "data/biometry-controller/biometry-controller"
import { processUnhandledError } from "data/handled-error";
import { WebauthnNotAvailableSnackbar, WebauthnNotAvailableSnackbarID } from "components/webauthn-not-available-snackbar";
import { Skip2ndFactorSnackbar, Skip2ndFactorSnackbarID } from "components/skip-2nd-factor-snackbar";

enum DisplayedComponent {      
    UnspecifiedLoginForm, // login form needs to be specified according to content of user list
    LoginForm,   
    BlockedAccountDialog,
    DisabledAccountDialog,
    ResetPasswordConfirmationDialog,
    UserForgotPasswordForm,   
    RememberedUsersList,     
    RememberedUserForm,
    PinCodeForm,
    PasskeyRegistrationForm
};

enum DisplayedSnackbar {
    WebauthnOfferInform = "SignInPage.WebauthnOfferInform",
    WebauthnOfferActivate = "SignInPage.WebauthnOfferActivate",
    FailedToSendPincodeMail = "SignInPage.FailedToSendPincodeMail",
    WebauthnLoginFailed = "SignInPage.WebauthnLoginFailed"    
};

export const SignInPage = () => {
    const intl = useIntl();
    const fatalErrorContext = React.useContext(FatalErrorContext);
    const snackbarContext = useSnackbars();
    const [disableActiveControls, setDisableActiveControls] = useState(false);
    const [displayedComponent, setDisplayedComponent] = useState<DisplayedComponent>(DisplayedComponent.UnspecifiedLoginForm);

    const blockedUserEmail = useRef<string>("");
    const keyEmail: string = "email";
    const keyPassword: string = "password";
    const keyPinCode = "pincode";
    const keyCheckbox = "registerDevice";
    const keyUserEmail: string = "userEmail";
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
        
    let serverAdapter = useRef(new ServerAdapter(fatalErrorContext.handleHttpError));
    let userController: UserControllerInterface = new UserController(serverAdapter.current);
    const recaptchaContext: RecaptchaContextInterface = useContext(RecaptchaContext);
    let biometryController = useRef<BiometryControllerInterface>(new BiometryController(serverAdapter.current, recaptchaContext));
    
    const currentUserContext: CurrentUserContextInterface = useContext(CurrentUserContext);
    const pinCodeAttemptsContext: PinCodeAttemptsInterface = useContext(PinCodeAttemptsContext);
    const deviceUserListContext: DeviceUserListContextInterface = useContext(DeviceUserListContext);   
    
    const inputPasswordEl = useRef<HTMLInputElement | null>(null);
    const [showIncorrectCredentialsNotification, setShowIncorrectCredentialsNotification] = useState(false);
    const [showServerErrorNotification, setServerErrorNotification] = useState(false);
    const [showProgress, setShowProgress] = useState(false);
    const redirectPath = searchParams.get("redirect");

    const turnOnAutofill = React.useCallback((userId?: number) => {
        if (!WebAuthCheck()) {
            return;
        }
        ConditionalMediationAvailableCheck().then((isCMA: boolean) => {
            if (!isCMA) {
                return;
            }
            // call biometry controller with conditional sing-in
            biometryController.current
                .signInByBiometric(userId, true)
                .then(() => onLoginSuccess(false))
                .catch(processUnhandledError((error: any) => {
                    // handle unspecified (non BiometryAction.GetCredentials) errors
                    if (error?.action !== BiometryAction.GetCredentials) {
                        return;
                    }
                    // handle BiometryAction.GetCredentials errors
                    if (error?.reason?.name === "AbortError") {
                        // this is normal error when autofill was aborted
                        return;
                    } else if (error?.reason === BiometryErrorReasons.CredentialsNotAvailable) {
                        // this is expected when user hasn't webauthn credential created yet
                        return;
                    } else if (error?.reason === BiometryErrorReasons.InternalServerError) {
                        fatalErrorContext.setServerError();
                    } else if (error?.reason === BiometryErrorReasons.FailedToLogin) {
                        snackbarContext.showSnackbar(DisplayedSnackbar.WebauthnLoginFailed);
                    } else if (error?.reason?.reason === BiometryErrorReasons.AccountDisabled) {
                        handleDisabledUser(error?.reason?.email);
                    } else if (error?.reason === BiometryErrorReasons.WebAuthenticationNotAvailable) {                        
                        snackbarContext.showSnackbar(WebauthnNotAvailableSnackbarID);
                    }
                }));
        });
        // eslint-disable-next-line
    }, []);

    const turnOffAutofill = React.useCallback(() => {
        biometryController.current.abortSignInByBiometry();
    }, []);
    
    const changeDisplayedComponent = React.useCallback((newComponent: DisplayedComponent, userId: number | undefined = undefined) => {    
        const usersList: UserListItem[] = deviceUserListContext.deviceUserList;
        if (usersList.length > 0) {
            if (newComponent === DisplayedComponent.UnspecifiedLoginForm) {
                const lastUserItem: UserListItem = usersList[0]; // last element
                setRememberedUser(lastUserItem);
                changeDisplayedComponent(DisplayedComponent.RememberedUserForm, lastUserItem?.userId);
                return;
            }
        } else {
            if (newComponent === DisplayedComponent.UnspecifiedLoginForm || newComponent === DisplayedComponent.RememberedUsersList) {
                setRememberedUser(undefined);
                changeDisplayedComponent(DisplayedComponent.LoginForm, undefined);
                return;
            }
        }

        if (newComponent === displayedComponent) {
            return;
        }

        if (newComponent === DisplayedComponent.RememberedUserForm) {
            turnOnAutofill(userId);
        } else if (newComponent === DisplayedComponent.LoginForm) {
            turnOnAutofill(userId);
        } else {
            turnOffAutofill();
        }

        setDisplayedComponent(newComponent);
    }, [displayedComponent, turnOnAutofill, turnOffAutofill, deviceUserListContext.deviceUserList]);

    const handleBlockedUser = (email: string | undefined) => {
        blockedUserEmail.current = email ? email : "";
        changeDisplayedComponent(DisplayedComponent.BlockedAccountDialog);
    }
    
    const handleDisabledUser = (email: string | undefined) => {
        blockedUserEmail.current = email ? email : "";
        changeDisplayedComponent(DisplayedComponent.DisabledAccountDialog);
    }
    
    const [showBiometricUnavailableDialog, setShowBiometricUnavailableDialog] = useState(false);
    
    const onUserLoginFailed = () => {
        let newUser = currentUserContext.user;
        if (newUser) {
            newUser.authenticated = false;
        } else {
            newUser = { authenticated: false, language: intl.locale };
        }
        currentUserContext.setUser(
            newUser
        );

        setShowProgress(false);
        setDisableActiveControls(false);
    }
    
    const onGeneralError = (err?: any) => {
        console.log("An error occured: ", err);
        fatalErrorContext.setServerError();
    }
    
    const onLoginRequestPinCode = async (username: string, formApi: FormApi) => {
        //store email
        sessionStorage.setItem("tempInfo_email", username);
        try {
            const response: GetPinCodeAttemptsResponse = await userController.getPinCodeAttempts();
            if (response.status === PinCodeStatus.Ok) {
                const result: PinCodeAttemptsInfo = {
                    pin_code_remaining_attempts: response.pin_code_remaining_attempts,
                    pin_code_remaining_silent_attempts: response.pin_code_remaining_silent_attempts
                }
                pinCodeAttemptsContext.setRemainingAttempts(result);
                // show User pin code request page
                setShowProgress(false);
                setDisableActiveControls(false);
                changeDisplayedComponent(DisplayedComponent.PinCodeForm);
            } else if (response.reasons.includes(Reasons.AppError)) {
                fatalErrorContext.setServerError();
            } else {
                onGeneralError(response);
            }
        } catch (e: any) {
            processUnhandledError((error) => { throw new Error("error getting attempts: ", error) })(e);
        }
    }

    const redirectToApplication = React.useCallback(() => {
        //Redirect to old application
        window.location.href = redirectPath ? redirectPath : Links.getOldPageDefaultLink();
    }, [redirectPath]);

    const onLoginSuccess = React.useCallback((checkOfferBiometry: boolean) => {
        let user: CurrentUser | null = {
            authenticated: true,
            language: currentUserContext?.user?.language ?? intl.locale
        };

        currentUserContext.setUser(user);

        if (checkOfferBiometry && WebAuthCheck()) {
            biometryController.current.getOfferWebauthnRegistration(false)//no recaptcha
                .then((value: GetBiometryOfferResponse) => {
                    if (value.status === WebauthnServerResponse.Ok && value.offerWebauthnRegistration) {
                        setDisableActiveControls(false);
                        changeDisplayedComponent(DisplayedComponent.PasskeyRegistrationForm);
                    }
                    else {
                        //in any other cases when status is WebauthnServerResponse.Failed 
                        //and any reasons: Reasons.InvalidParameter or Reasons.AppError
                        //we always want to redirect to application
                        redirectToApplication();
                    }
                })
                .catch(processUnhandledError(redirectToApplication));
        }
        else {
            redirectToApplication();
        }
    }, [redirectToApplication, currentUserContext, intl, changeDisplayedComponent]);
    
    const onLoginError = (formApi: FormApi, err?: any) => {
        setShowProgress(false);
        setServerErrorNotification(true);
        
        formApi.reset();
    }
    
    const submitForm = async (formApi: FormApi, showNotification: boolean, email?: string, password?: string): Promise<any> => {
        setDisableActiveControls(true);
        setShowProgress(true);
        const UserLogIn: string = "ApiUserLogin";
        let results: Reasons[] = [];
        let emailFromResponse: string | undefined = undefined;
        
        const enforceSavePassword = ()=>{
            window.history.replaceState(undefined, "");
        }

        try {
            const recaptchaToken = await recaptchaContext.getToken(UserLogIn);
            const userData: UserLoginData = {
                username      : email!,
                password      : password!,
                recaptchaToken: recaptchaToken
            };
            const response = await userController.processUserLoginRequest(userData);
            const status = response.status;
            if (status === LoginServerStatus.Ok) {
                enforceSavePassword();
                onLoginSuccess(true);
            } else if (status === LoginServerStatus.RequestedPinCode) {
                enforceSavePassword();
                onLoginRequestPinCode(userData.username, formApi);
            } else if (
                status === LoginServerStatus.PinCodeCheckRequired ||
                status === LoginServerStatus.PinCodeCheckMandatory
            ) {
                enforceSavePassword();
                if (status === LoginServerStatus.PinCodeCheckRequired) {
                    setPeriodicPinRecheck("optional");
                } else {
                    setPeriodicPinRecheck("required");
                }
                onLoginRequestPinCode(userData.username, formApi);
            } else if (status === LoginServerStatus.Failed) {
                onUserLoginFailed();
                results = response.reasons;
                emailFromResponse = response.email;
            } else {
                onLoginError(formApi, response);
            }
        }
        catch (err: any) {
            processUnhandledError((error: any) => onLoginError(formApi, error))(err);
        }
        if (results.includes(Reasons.AppError)) {
            fatalErrorContext.setServerError();
        } else if (results.includes(Reasons.PasswordRequired) || results.includes(Reasons.UsernameRequired)) {
            return {
                email: requiredField(valueMap[keyEmail], "signin.page.form.requiredField", email),
                password: requiredField(valueMap[keyPassword], "signin.page.form.requiredField", password)
            }
        } else if (results.includes(Reasons.AccountDisabled)) {
            handleDisabledUser(emailFromResponse ?? email);
        } else if (results.includes(Reasons.Blocked)) {
            handleBlockedUser(email);
        } else if (results.includes(Reasons.InvalidCredentials)) {
            if (showNotification) {
                setShowIncorrectCredentialsNotification(true);

                if (inputPasswordEl.current) {
                    inputPasswordEl.current.focus();
                }
                formApi.change(keyPassword, "");
            } else {
                return {
                    password: validationMessage(valueMap[keyPassword], "signin.page.form.remembered.user.invalid.password")
                }
            }
        } else if (results.includes(Reasons.PinCodeMailFailed)) {
            snackbarContext.showSnackbar(DisplayedSnackbar.FailedToSendPincodeMail);
        }
    }
    
    const handleSubmitSignInForm = async (values: any, formApi: FormApi): Promise<any> => {
        
        let email: string | undefined = values[keyEmail];
        let password: string | undefined = values[keyPassword];
        let showNotification: boolean = true;
        
        return handleSubmit(email, password, formApi, showNotification);
    };
    
    const handleSubmit = async (email: string | undefined, password: string | undefined, formApi: FormApi, showNotification: boolean): Promise<any> => {
        setShowIncorrectCredentialsNotification(false);
    
        return await submitForm(formApi, showNotification, email, password);
    };
    
    const valueMap: Record<string, string> = {
        "email"    : "signin.page.form.validate.email",
        "password" : "signin.page.form.validate.password",
        "pincode"  : "2fa.page.form.validate.pincode",
        "userEmail": "user.forgot.password.page.input.errorMessage.validate.email"
    };
    
    const validationMessage = (name: string, messageId: string) => {
        return (
            <FormattedMessage
                id={name}
            >
                {
                    translatedFieldName => {
                        return (
                            <FormattedMessage values={{ valueName: translatedFieldName }} id={messageId} />
                        );
                    }
                }
            </FormattedMessage>
        );
    }
    
    const requiredField = (name: string, messageId: string, value?: string) => {
        if (StringMethods.isNotEmpty(value)) return undefined;
        
        return validationMessage(name,messageId);
    };
    
    const initializeRecaptcha = async (): Promise<void> => {
        const response: boolean = await recaptchaContext.initializeRecaptcha();
        
        if (!response) {
            console.warn("Unable to initialize recaptcha");
        }
    }
    
    const handleForgotPasswordButton = () => {
        setShowIncorrectCredentialsNotification(false);
        changeDisplayedComponent(DisplayedComponent.UserForgotPasswordForm);
    }
    
    const LoginInForm = (): JSX.Element => {
        return (
            <LoaderGuard loadingInitFunction={initializeRecaptcha()}>
                <SignInForm
                    keyEmail={keyEmail}
                    keyPassword={keyPassword}
                    disableActiveControls={disableActiveControls}
                    inputPasswordEl={inputPasswordEl}
                    handleSubmit={handleSubmitSignInForm}
                    showIncorrectCredentialsNotification={showIncorrectCredentialsNotification}
                    showServerErrorNotification={showServerErrorNotification}
                    showProgress={showProgress}
                    handleForgotPasswordButton={handleForgotPasswordButton}
                    handleBiometryButton={() => { onUseBiometry(undefined); }}
                />
                {renderSnackbars()}
            </LoaderGuard>
        );
    }
    const DisabledAccountForm = (): JSX.Element => {
        const onClickUseAnotherAccount = () => {
            changeDisplayedComponent(DisplayedComponent.LoginForm);
            navigate(Links.getBaseUrlLink());
        }
        return (
            <BlockedAccountDialog
                onClickUseAnotherAccount={onClickUseAnotherAccount}
                userEmail={blockedUserEmail.current}
                showProgress={false}
                disableActiveControls={false}
            />
        )
    }
    const BlockAccountForm = (): JSX.Element => {
        const onClickUseAnotherAccount = () => {
            changeDisplayedComponent(DisplayedComponent.LoginForm);
            navigate(Links.getBaseUrlLink())
        }

        const handleError = (err: any) => {
            console.warn("Failed to send user forgot password email with error: ", err);
            changeDisplayedComponent(DisplayedComponent.UnspecifiedLoginForm);
            navigate(Links.getBaseUrlLink());
        }
        
        const handleClickResetUserPasswordButton = () => {
            const userForgotPassword = "ApiUserForgotPassword";
            setDisableActiveControls(true);
            setShowProgress(true);
            recaptchaContext.getToken(userForgotPassword)
                            .then((recaptchaToken: string) => {
                                const userData: UserForgotPasswordData = {
                                    email         : blockedUserEmail.current,
                                    recaptchaToken: recaptchaToken
                                };
                
                                userController.processUserForgotPasswordRequest(userData)
                                              .then((response: UserForgotPasswordResponse) => {
                                                  const status = response.status;
                                                  if (status === ForgotPasswordRequestStatus.Ok) {
                                                      setDisableActiveControls(false);
                                                      setShowProgress(false);
                                                      changeDisplayedComponent(DisplayedComponent.ResetPasswordConfirmationDialog);
                                                  } else {
                                                      handleError(response.reasons)
                                                  }
                                              }).catch(processUnhandledError(handleError));
                            })
                            .catch(handleError);
        }
        
        return (
            <BlockedAccountDialog
                onClickUseAnotherAccount={onClickUseAnotherAccount}
                userEmail={blockedUserEmail.current}
                onClickResetPassword={handleClickResetUserPasswordButton}
                showProgress={showProgress}
                disableActiveControls={disableActiveControls}
            />
        )
    }
    const ForgotPasswordConfirmationDialogForm = (): JSX.Element => {
        const handleClickProceed = () => {
            changeDisplayedComponent(DisplayedComponent.UnspecifiedLoginForm);
            navigate(Links.getBaseUrlLink());
        }
        return (
            <ForgotPasswordConfirmationDialog onClickProceed={handleClickProceed} />
        )
    }
    const handleSubmitRememberedUser = async (values: any, formApi: FormApi): Promise<any> => {
        let password: string | undefined = values[keyPassword];
        const email = rememberedUser?.loginName ? rememberedUser?.loginName : "";
        const showNotification: boolean = false;
    
        return await submitForm(formApi, showNotification, email, password);
    }
    
    const [rememberedUser, setRememberedUser] = useState<UserListItem | undefined>(undefined);
    const [periodicPinRecheck, setPeriodicPinRecheck] = useState<"no" | "optional" | "required">("no");
    
    const handleClickBackArrow = () => {
        setShowIncorrectCredentialsNotification(false);
        setServerErrorNotification(false);
        changeDisplayedComponent(DisplayedComponent.RememberedUsersList);
    }
    
    const handleWebAuthenticationUnavailableError = (error: any) => {
        setDisableActiveControls(false);
        if (error && error.action && error.error) {
            if (error.action === BiometryAction.CreateCredentials) {
                if (error.error === BiometryErrorReasons.WebAuthenticationNotAvailable) {
                    // show dialog
                    setShowBiometricUnavailableDialog(true);
                }
            }
        }
        console.error(error);
    }

    const handleOfferBiometrySnackbars = (userId?: number) => {
        if (userId) {
            biometryController.current.getOfferWebauthnRegistration(true, userId).then((value: GetBiometryOfferResponse) => { //yes recaptcha
                if (value.status === WebauthnServerResponse.Ok) {
                    if (value.offerWebauthnRegistration) {
                        snackbarContext.showSnackbar(DisplayedSnackbar.WebauthnOfferInform);
                    }
                    else {
                        snackbarContext.showSnackbar(DisplayedSnackbar.WebauthnOfferActivate);
                    }
                } else {
                    //in any other cases when status is WebauthnServerResponse.Failed 
                    //and any reasons: Reasons.InvalidParameter or Reasons.AppError
                    //we always want to display login failed snaack bar
                    snackbarContext.showSnackbar(DisplayedSnackbar.WebauthnLoginFailed);
                }
            }).catch(processUnhandledError(()=>{})); //just ignore errors 
        }
    };

    const onUseBiometry = (userId?: number) => {
        turnOffAutofill();
        biometryController.current
            .signInByBiometric(userId, false)
            .then(()=>onLoginSuccess(false))
            .catch((error: any) => {
                processUnhandledError((error: any) => {
                    let errorHandled: boolean = false;
                    if (error && error.action && error.reason) {
                        if (error.action === BiometryAction.GetCredentials) {
                            if (error.reason === BiometryErrorReasons.CredentialsNotAvailable ||
                                error.reason === BiometryErrorReasons.WebAuthenticationCanceled) {
                                handleOfferBiometrySnackbars(userId);
                                errorHandled = true;
                            } else if (error?.reason === BiometryErrorReasons.InternalServerError) {
                                errorHandled = true;
                                fatalErrorContext.setServerError();
                            } else if (error?.reason === BiometryErrorReasons.FailedToLogin) {
                                errorHandled = true;
                                snackbarContext.showSnackbar(DisplayedSnackbar.WebauthnLoginFailed);
                            } else if (error?.reason?.reason === BiometryErrorReasons.AccountDisabled) {
                                errorHandled = true;
                                handleDisabledUser(error?.reason?.email);
                            } else if (error?.reason === BiometryErrorReasons.WebAuthenticationNotAvailable) {
                                errorHandled = true;
                                snackbarContext.showSnackbar(WebauthnNotAvailableSnackbarID);
                            }
                        }
                    }
                    if (!errorHandled) {
                        handleWebAuthenticationUnavailableError(error);
                    }
                })(error);
                turnOnAutofill(userId);
            });
    }
    
    const handleCloseBiometricUnavailableDialog = () => {
        setShowBiometricUnavailableDialog(false);
    }

    const handleActionOfferBiometryButton = (userId?: number) => {
        if (userId) {
            snackbarContext.hideSnackbar(DisplayedSnackbar.WebauthnOfferActivate);
            biometryController.current.setOfferWebauthnRegistration(true, true, userId).then((value: SetBiometryOfferResponse) => { //yes recaptcha
                if (value.status === WebauthnServerResponse.Ok) {
                    snackbarContext.showSnackbar(DisplayedSnackbar.WebauthnOfferInform);
                } else {
                     //in any other cases when status is WebauthnServerResponse.Failed or .Forbidden
                     //and any reasons: Reasons.InvalidParameter or Reasons.AppError
                     //we always want to proceed as InternalServerError
                    fatalErrorContext.setServerError();
                }
            }).catch(processUnhandledError(fatalErrorContext.setServerError));
        }       
    };


    const setOfferBiometryAction = (
        <Button color="inversePrimary" size="small" variant="Text" onClick={() => handleActionOfferBiometryButton(rememberedUser?.userId)}>
            <FormattedMessage
                id={"signin.page.snackbar.action.webauthn.offer.button"}
            />            
        </Button>
    );

    const skipPinCodeRecheck = () => {
        userController.skipUserPinCode()
            .then((response: SkipUserPinCodeResponse) => {
                if (response.status === ServerResponse.Ok) {
                    redirectToApplication();
                } else {
                    //actually it reloads application as user is not signed yet
                    redirectToApplication();
                }
            })
            .catch(processUnhandledError((error: any) => {
                //actually it reloads application as user is not signed yet
                redirectToApplication();
            }));
    }

    const renderSnackbars = () => {
        return [
            <Snackbar
                id={DisplayedSnackbar.WebauthnOfferInform}
                key={"SnackbarBiometryWillBeOfferedId"}
                message={intl.formatMessage({ id: "signin.page.snackbar.inform.webauthn.offer.title" })}
                autoHideDuration={defaultSnackbarAutohideDuration}
            />,
            <Snackbar
                id={DisplayedSnackbar.WebauthnOfferActivate}
                key={"SnackbarActivateBiometryOfferId"}
                message={intl.formatMessage({ id: "signin.page.snackbar.activate.webauthn.offer.title" })}
                action={setOfferBiometryAction}
                autoHideDuration={defaultSnackbarAutohideDuration}
            />,
            <Snackbar
                id={DisplayedSnackbar.FailedToSendPincodeMail}
                key={"FailedToSendPincodeMailSnackbarId"}
                message={intl.formatMessage({ id: "signin.page.snackbar.failedToSendPincodeMail.message" })}
                autoHideDuration={defaultSnackbarAutohideDuration}

            />,
            <Snackbar
                id={DisplayedSnackbar.WebauthnLoginFailed}
                key={"SnackbarBiometryLoginFailedId"}
                message={intl.formatMessage({ id: "signin.page.snackbar.inform.webauthn.failedToLogin.title" })}
                autoHideDuration={defaultSnackbarAutohideDuration}
            />,
            <WebauthnNotAvailableSnackbar key="SnackbarWebauthnNotAvailableId"/>
        ];
    };
    
    const LoginRememberedUserForm = () => (        
        <LoaderGuard loadingInitFunction={initializeRecaptcha()}>
            <SignInRememberedUserForm
                onBackArrow={handleClickBackArrow}
                onUseBiometry={() => onUseBiometry(rememberedUser?.userId)}
                onResetPassword={handleForgotPasswordButton}
                handleSubmit={handleSubmitRememberedUser}
                userName={rememberedUser?.email}                
                handleCloseBiometricUnavailableDialog={handleCloseBiometricUnavailableDialog}
                keyPassword={keyPassword}
                disableActiveControls={disableActiveControls}
                inputPasswordEl={inputPasswordEl}
                showIncorrectCredentialsNotification={showIncorrectCredentialsNotification}
                showServerErrorNotification={showServerErrorNotification}
                showProgress={showProgress}
                showBiometricUnavailableDialog={showBiometricUnavailableDialog}
            />
            {renderSnackbars()}
        </LoaderGuard>
    );
    
    const RememberedUsersListForm = (): JSX.Element => {
        const usersList: UserListItem[] = deviceUserListContext.deviceUserList.slice(0, 3);
        
        const handleUseAnotherAccount = () => {
            changeDisplayedComponent(DisplayedComponent.LoginForm);
            navigate(Links.getBaseUrlLink());
        }
        const handleItemClick = (userItem: UserListItem) => {           
                setRememberedUser(userItem);
                changeDisplayedComponent(DisplayedComponent.RememberedUserForm, userItem.userId);
        }

        return (
            <LoaderGuard loadingInitFunction={initializeRecaptcha()}>
                <UsersListForm
                    onClickUserListItem={handleItemClick}
                    onClickUseAnotherAccount={handleUseAnotherAccount}
                    usersList={usersList}
                    onRemoveUserListItem={deviceUserListContext.removeDeviceUserFromList}
                />
            </LoaderGuard>
        );
    };

    const onRegisterBiometric = () => {        
        biometryController.current
            .registerMyBiometry()
            .then(() => {
                redirectToApplication();
            }).catch(processUnhandledError((error: any) => {
                if (error?.action === BiometryAction.CreateCredentials) {
                    if (error?.reason === BiometryErrorReasons.InternalServerError) {
                        fatalErrorContext.setServerError();
                        return;
                    } else if (error?.reason === BiometryErrorReasons.WebAuthenticationNotAvailable) {
                        setDisableActiveControls(false);
                        snackbarContext.showSnackbar(WebauthnNotAvailableSnackbarID);
                        return;
                    }
                }
                handleWebAuthenticationUnavailableError(error);
            }));
    }

    const PasskeyForm = (): JSX.Element => {
        const handleActivateButton = () => {
            setDisableActiveControls(true);
            onRegisterBiometric();           
        }

        const handleAskLaterButton = () => {            
            redirectToApplication();
        }

        const handleDontAskAgainButton = () => {
            setDisableActiveControls(true);
            biometryController.current.setOfferWebauthnRegistration(false, false) //no recaptcha
                .then((value: SetBiometryOfferResponse) => { //yes recaptcha
                    if (value.status === WebauthnServerResponse.Ok) {
                        redirectToApplication();
                    } else {
                        //in any other cases when status is WebauthnServerResponse.Failed or .Forbidden
                        //and any reasons: Reasons.InvalidParameter or Reasons.AppError
                        //we always want to proceed as InternalServerError
                        fatalErrorContext.setServerError();
                    }
                })
                .catch(processUnhandledError(fatalErrorContext.setServerError));
        }

        return (
            <PasskeyRegistrationForm
                disableActiveControls={disableActiveControls}
                handleActivateButton={handleActivateButton}
                handleAskLaterButton={handleAskLaterButton}
                handleDontAskAgainButton={handleDontAskAgainButton}
            />
        );
    }
    
    const handleReadingPinCodeError = (err: any) => {
        console.warn(" Error reading pin codes ", err);
        changeDisplayedComponent(DisplayedComponent.UnspecifiedLoginForm);
        navigate(Links.getBaseUrlLink());
    }
    
    const readPinCodeAttempts = async (): Promise<void> => {
        try {
            if (pinCodeAttemptsContext.pinCodeAttemptsInfo) {
                return;
            }
            
            const response: GetPinCodeAttemptsResponse = await userController.getPinCodeAttempts();
            if (response.status === PinCodeStatus.Ok) {
                const result: PinCodeAttemptsInfo = {
                    pin_code_remaining_attempts: response.pin_code_remaining_attempts,
                    pin_code_remaining_silent_attempts: response.pin_code_remaining_silent_attempts
                }
                pinCodeAttemptsContext.setRemainingAttempts(result);

            } else if (response.reasons.includes(Reasons.AppError)) {
                fatalErrorContext.setServerError();
            } else {
                console.log("Failed to get number of attempts with error: ", response)
            }
        } catch (err: any) {
            processUnhandledError(error => { handleReadingPinCodeError(error) })(err);
        }
    }

    const offerBiometryRegistration = () => {
            if (WebAuthCheck()) {
                biometryController.current.getOfferWebauthnRegistration(false)//no recaptcha
                    .then((value: GetBiometryOfferResponse) => {
                        if (value.status === WebauthnServerResponse.Ok && value.offerWebauthnRegistration) {
                            setDisableActiveControls(false);
                            changeDisplayedComponent(DisplayedComponent.PasskeyRegistrationForm);
                        }
                        else {
                            //in any other cases when status is WebauthnServerResponse.Failed 
                            //and any reasons: Reasons.InvalidParameter or Reasons.AppError
                            //we always want to redirect to application
                            redirectToApplication();
                        }
                    })
                    .catch(processUnhandledError(redirectToApplication));
            } else {
                //Redirect to old application
                redirectToApplication();
            }        
    };
    
    const onDeviceRegisterError = (err?: any) => {
        setDisableActiveControls(false);
        console.warn("Device registration failed with error", err);
        
        throw Error("Device registration error");
    }
    
        const onDeviceRegistrationSuccess = () => {
            offerBiometryRegistration();
        };
    
    const onVerificationSuccess = (registerDevice: boolean) => {
        //TODO: get user info directly from second-factor-authentication response
        let user: CurrentUser | null = {
            authenticated: true,
            language: currentUserContext?.user?.language ?? intl.locale
        };
        
        // Set currentUserContext
        currentUserContext.setUser(user);
        
        if (registerDevice) {
            const defaultDeviceName: DeviceName = GetDefaultDeviceName();
            const deviceName = intl.formatMessage(
                {
                    id: defaultDeviceName.stringId
                },
                {
                    browser  : defaultDeviceName.browser,
                    os       : defaultDeviceName.os,
                    osVersion: defaultDeviceName.osVersion,
                }
            );
            // Register device
            userController.registerDevice(deviceName)
                          .then((response: RegisterUserDeviceResponse) => {
                              const status = response.status;
                
                              if (status === RegisterDeviceStatus.Ok) {
                                  //TODO: user info should be get directly from second-factor-authentication response
                                  userController.getCurrentUserInfo()
                                                .then((currentUser: CurrentUser | null) => {
                                                    currentUserContext.setUser(currentUser);
                                                    onDeviceRegistrationSuccess();
                                                })
                                                .catch(processUnhandledError(()=>{})); //silently handle exception
                              } else if (status === RegisterDeviceStatus.Failed && response.reasons.includes(Reasons.AppError)) {
                                  fatalErrorContext.setServerError();
                              } else {
                                  onDeviceRegisterError(response);
                              }
                          })
                          .catch(processUnhandledError(err => onDeviceRegisterError(err)));
            
        } else {
            offerBiometryRegistration();//no recaptcha
        }
    }
    
    const onVerificationFailed = () => {
        setDisableActiveControls(false);
    }
    
    const onVerificationError = (err?: any) => {
        setDisableActiveControls(false);
        changeDisplayedComponent(DisplayedComponent.UnspecifiedLoginForm);
        navigate(Links.getBaseUrlLink());
    }
    
    const handleSubmitPinCode = async (values: any): Promise<any> => {
        let pincode: string | undefined = values[keyPinCode];
        let registerDevice: boolean = values[keyCheckbox] === 'true';
        let results: Reasons[] = [];
    
        try {
            setDisableActiveControls(true);
            const response: UserPinCodeResponse = await userController.verifyUserPinCodeRequest(pincode);
            const status = response.status;
            if (status === PinCodeStatus.Ok) {
                onVerificationSuccess(registerDevice)
            } else if (status === PinCodeStatus.Failed) {
                const result: PinCodeAttemptsInfo = {
                    pin_code_remaining_attempts       : response.pin_code_remaining_attempts,
                    pin_code_remaining_silent_attempts: response.pin_code_remaining_silent_attempts
                }
                pinCodeAttemptsContext.setRemainingAttempts(result);
                results = response.reasons;
                onVerificationFailed();
            } else {
                onVerificationError(response);
            }
        } catch (err: any) {
            processUnhandledError((error) => { onVerificationError(error); })(err);
        }

        if (results.includes(Reasons.AppError)) {
            fatalErrorContext.setServerError();
        } else if (results.includes(Reasons.WrongPinCode)) {
            return {
                pincode: validationMessage(valueMap[keyPinCode], "2fa.page.form.invalid.pincode")
            }
        } else if (results.includes(Reasons.CredentialsRequired)) {
            onVerificationError();
        } else if (results.includes(Reasons.Blocked)) {
            setDisableActiveControls(false);
            changeDisplayedComponent(DisplayedComponent.UnspecifiedLoginForm);
    
            const email = sessionStorage.getItem("tempInfo_email");
            if (email !== null) {
                handleBlockedUser(email);
            }
        } else if (results.includes(Reasons.PinCodeRequired)) {
            return {
                pincode: validationMessage(valueMap[keyPinCode], "2fa.page.form.requiredField")
            }
        }
    }
    
    const PinCodeForm = () => {
        let attempts: number = 0;
        if (pinCodeAttemptsContext.pinCodeAttemptsInfo) {
            if (pinCodeAttemptsContext.pinCodeAttemptsInfo.pin_code_remaining_silent_attempts <= 0) {
                attempts = pinCodeAttemptsContext.pinCodeAttemptsInfo.pin_code_remaining_attempts;
            }
        }

        return (
            <LoaderGuard loadingInitFunction={readPinCodeAttempts()}>
                <SecondFactorAuthenticationForm
                    attemptsRemaining={attempts}
                    disableActiveControls={disableActiveControls}
                    handleSubmit={handleSubmitPinCode}
                    keyCheckbox={keyCheckbox}
                    keyPinCode={keyPinCode}
                    periodicPinRecheck={periodicPinRecheck !== "no"}
                />
                <Skip2ndFactorSnackbar
                    onAction={skipPinCodeRecheck}
                />
            </LoaderGuard>
        );
    }
    
    const handleSubmissionError = (err: any, formApi: FormApi) => {
        console.log("Failed to send user forgot password email with error: ", err);
        setServerErrorNotification(true);
        formApi.change(keyUserEmail, "");
    }
    const handleForgotPasswordSuccessRequest = () => {
        setDisableActiveControls(false);
        setShowProgress(false);
        changeDisplayedComponent(DisplayedComponent.ResetPasswordConfirmationDialog);        
    }
    const handleForgotPasswordRequestFailed = () => {
        setDisableActiveControls(false);
        setShowProgress(false);
    }
    
    const submitUserForgotForm = async (values: any, formApi: FormApi): Promise<any> => {
        let userEmail: string | undefined = values[keyUserEmail];
        let results: Reasons[] = [];
    
        try {
            setDisableActiveControls(true);
            setShowProgress(true);
        
            const userForgotPassword = "ApiUserForgotPassword";
            const recaptchaToken: string = await recaptchaContext.getToken(userForgotPassword);
            const userData: UserForgotPasswordData = {
                email         : userEmail,
                recaptchaToken: recaptchaToken
            }
            const response: UserForgotPasswordResponse = await userController.processUserForgotPasswordRequest(userData)
            const status = response.status;
            if (status === ForgotPasswordRequestStatus.Ok) {
                handleForgotPasswordSuccessRequest();
            } else if (status === ForgotPasswordRequestStatus.Failed) {
                results = response.reasons;
                handleForgotPasswordRequestFailed();
            } else {
                handleSubmissionError(response.reasons, formApi);
            }
        } catch (err: any) {
            processUnhandledError((error) => { handleSubmissionError(error, formApi) })(err);
        }
        
        if (results.includes(Reasons.IncorrectEmail)) {
            return {
                userEmail: validationMessage(valueMap[keyUserEmail], "user.forgot.password.page.input.errorMessage.invalid.email")
            }
        } else if (results.includes(Reasons.MissingEmail)) {
            return {
                userEmail: validationMessage(valueMap[keyUserEmail], "user.forgot.password.page.input.errorMessage.validate.email")
            }
        }
    }
    
    const ForgotPasswordForm = (): JSX.Element => {
        const handleSignInButtonClick = () => {
            changeDisplayedComponent(DisplayedComponent.UnspecifiedLoginForm);
            navigate(Links.getBaseUrlLink());
        }
        
        const formTitleText: string = intl.formatMessage({id: "user.forgot.password.page.title"});
        const formMessageText: string = intl.formatMessage({id: "user.forgot.password.page.content"});
        const formCancelButtonText: string = intl.formatMessage({id: "generic.action.signIn"});
        const formProceedButtonText: string = intl.formatMessage({id: "user.forgot.password.page.submit.button"});
        
        return (
            <LoaderGuard loadingInitFunction={initializeRecaptcha()}>
                <UserForgotPasswordForm
                    handleSignInButtonClick={handleSignInButtonClick}
                    handleSubmitForm={submitUserForgotForm}
                    keyUserEmail={keyUserEmail}
                    disableActiveControls={disableActiveControls}
                    showProgress={showProgress}
                    showServerErrorNotification={showServerErrorNotification}
                    formMessageText={formMessageText}
                    formTitleText={formTitleText}
                    cancelButtonText={formCancelButtonText}
                    proceedButtonText={formProceedButtonText}
                />
            </LoaderGuard>
        );
    }

    useEffect(() => {
        if (displayedComponent === DisplayedComponent.PinCodeForm) {
            if (periodicPinRecheck === "optional") {
                snackbarContext.showSnackbar(Skip2ndFactorSnackbarID);
            } else if (periodicPinRecheck === "required") {
                snackbarContext.hideSnackbar(Skip2ndFactorSnackbarID);
            }
        }
    }, [snackbarContext, periodicPinRecheck, displayedComponent]);
        
    if(displayedComponent === DisplayedComponent.UnspecifiedLoginForm || displayedComponent === DisplayedComponent.RememberedUsersList){
        changeDisplayedComponent(displayedComponent);
    }

    switch (displayedComponent) {        
        case DisplayedComponent.BlockedAccountDialog:
            return BlockAccountForm();
        case DisplayedComponent.DisabledAccountDialog:
            return DisabledAccountForm();
        case DisplayedComponent.ResetPasswordConfirmationDialog:
            return ForgotPasswordConfirmationDialogForm();
        case DisplayedComponent.UserForgotPasswordForm:
            return ForgotPasswordForm();
        case DisplayedComponent.RememberedUsersList:
            return RememberedUsersListForm();
        case DisplayedComponent.RememberedUserForm:
            return LoginRememberedUserForm();
        case DisplayedComponent.PinCodeForm:
            return PinCodeForm();
        case DisplayedComponent.PasskeyRegistrationForm:
            return PasskeyForm();
        case DisplayedComponent.LoginForm:            
            return LoginInForm();
        default:
            return null;         
        }    
}
