import React from "react";

import {
    PageButtonsContainer,
    PageContainer,
    PageContentContainer,
    PageHeaderContainer,
    PageLogoContainer,
    PageTitleContainer
} from "components/controls";
import { PageMetadata } from "components/page-metadata";
import { UsersListFormProps } from "./users-list-form-props";
import { UsersListItem } from "./users-list-item";
import { Typography } from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import { Button, List } from "tm-controls";
import { UsersListFormStyles } from "./users-list-form-styles";
import { PageFooter } from "components/page-footer";
import { RecaptchaBadge } from "components/recaptcha-badge";
import { Page } from "components/page";

export const UsersListForm = (props: UsersListFormProps) => {
    const intl = useIntl();
    const classes = UsersListFormStyles();
    const logoPath: string = intl.formatMessage({
        id: "application.logo"
    });
    const renderListItems = () => {
        return (
            <> 
                {
                    props.usersList.map(
                        (item, index) => (
                            <UsersListItem
                                key={index}
                                userListItem={item}
                                onClick={()=> {props.onClickUserListItem(item); }}
                                onRemove={() => {
                                    if (props.onRemoveUserListItem) {
                                        props.onRemoveUserListItem(item);
                                    }
                                }}
                               
                            />
                        )
                    )
                }
            </>
        );
    }
    return (
        <Page>
            <PageMetadata />
                <PageContainer>
                    <PageHeaderContainer>
                        <PageLogoContainer>
                            <img data-testid="application-logo" height="28px" src={logoPath} alt="logo" />
                        </PageLogoContainer>
                        <PageTitleContainer>
                            <Typography data-testid="users-list-form-title" variant="h5">
                                <FormattedMessage id="users.list.form.title" />
                            </Typography>
                        </PageTitleContainer>
                    </PageHeaderContainer>
                    <PageContentContainer>
                        <List data-testid="users-list" className={classes.list}>
                            {renderListItems()}
                        </List>
                    </PageContentContainer>
                    <PageButtonsContainer>
                        <Button
                            color="primary"
                            variant="Text"
                            onClick={props.onClickUseAnotherAccount}
                            data-testid="use-another-account-button"
                        >
                            <FormattedMessage
                                id={"blockedAccount.useAnotherAccount.page.button"}
                            />
                    </Button>
                    </PageButtonsContainer>
                </PageContainer>
            <RecaptchaBadge />
            <PageFooter />
        </Page>
    );
}
