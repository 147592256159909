import { listClasses, ListRoot } from "./list-styles";
import { ListProps } from "./list-props";

export const List = ({ children, className, ...rest}: ListProps) => {
  return (
      <ListRoot
          {...rest}
          className={[listClasses.root, className].join(" ")}
      >
          {children}
      </ListRoot>
  )
}
