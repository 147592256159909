    export class StringMethods {
    public static isNotEmpty(e?: string) {
        if (e && e.trim() !== "") return true
        return false
    };
    public static isEmailValid(e?: string) {
        if (e && StringMethods.isNotEmpty(e)) {
            const regExp: RegExp = /^([a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?)$/i;
            return regExp.test(e);
        }
        return false;
    }
}
