import React, { useState } from 'react';
import { Routes } from 'components/routes';
import { useSearchParams } from "react-router-dom";
import { appTheme as theme, ThemeProvider } from "tm-controls";
import { CssBaseline } from "@mui/material";
import { HelmetProvider } from 'react-helmet-async';

import { TranslationsProvider } from "translations";

import {
    CurrentUserContext,
    defaultCopyrightContext,
    defaultRecaptchaContext,
    DeviceUserListContext,
    PinCodeAttemptsContext,
    PinCodeAttemptsInfo,
    RecaptchaContext,
    RecaptchaContextInterface,
    ServerVersionContext,
    ServerVersionContextInterface
} from "contexts";
import { LoaderGuard } from "components/loader-guard";

import {
    CurrentUser,
    DeviceController,
    DeviceControllerInterface,
    UserListItem,
    RecaptchaController,
    ServerAdapter,
    ServerAdapterInterface,
    ServerController,
    ServerControllerInterface,
    ServerInfoResponse,
    ServerVersionResponse,
    UserController,
    UserControllerInterface
} from "data";

import { AppStyles } from "./app-styles";
import { Links } from "utils";
import { FatalErrorContext } from 'contexts/fatal-error-context';
import { FatalErrorGuard } from 'components/fatal-error-guard';
import { SnackbarProvider, useSnackbars } from 'tm-controls/snackbar-provider';
import { NetworkErrorSnackbarID, NetworkProblemSnackbar } from 'components/network-problem-snackbar';

function AppWithSnackbars() {
    const classes = AppStyles();
    const [searchParams] = useSearchParams();
    const snackbarContext = useSnackbars();

    const [currentUser, setCurrentUser] = useState<CurrentUser | null>(null);
    const [recaptchaController, setRecaptchaController] = useState<RecaptchaContextInterface>(defaultRecaptchaContext);
    const [copyright, setCopyright] = useState<ServerVersionContextInterface>(defaultCopyrightContext);
    const [remainingAttemptsInfo, setRemainingAttemptsInfo] = useState<PinCodeAttemptsInfo | null>(null);
    const [deviceUserList, setDeviceUserList] = useState<UserListItem[]>([]);
    const [serverError, setServerError] = React.useState<boolean>(false);

    const handleServerError: () => void = React.useCallback(() => {
        setServerError(true);
    }, [setServerError]);

    const handleHttpError: (code?: number) => boolean = React.useCallback((code?: number) => {
        if (!code) {
            setServerError(true);
            return true;
        }

        if (code === 408) {
            //display snackbar
            snackbarContext.showSnackbar(NetworkErrorSnackbarID);
            return true;
        }

        if (code >= 400 && code < 500) {
            window.location.reload();
            return true;
        }

        setServerError(true);
        return true;

    }, [snackbarContext]);

    let serverAdapter: ServerAdapterInterface = new ServerAdapter(handleHttpError);
    let serverController: ServerControllerInterface = new ServerController(serverAdapter);
    let userConfigController: UserControllerInterface = new UserController(serverAdapter);
    let deviceController: DeviceControllerInterface = new DeviceController(serverAdapter);
    const [redirectPath] = useState(searchParams.get("redirect"));

    const readDeviceUserList = async (): Promise<void> => {
        try {
            const response: UserListItem[] = await deviceController.getDeviceUserList();
            setDeviceUserList(response);
        } catch (e: any) {
            throw e;
        }
    }

    const removeDeviceUserFromList = async (user: UserListItem): Promise<void> => {
        const users: UserListItem[] = await deviceController.removeDeviceUserFromList(user);
        setDeviceUserList(users);
    }

    const initializeCurrentUser = (): Promise<void> => {
        return new Promise<void>((resolve, reject) => {
            userConfigController.getCurrentUserInfo()
                .then((response: CurrentUser | null) => {
                    if (response) {
                        setCurrentUser(response);
                        if (response.authenticated) {
                            window.location.href = redirectPath ? redirectPath : Links.getOldPageDefaultLink();
                        } else {
                            resolve();
                        }
                    }
                })
                .catch(() => {
                    reject(Error("Invalid user info"));
                });
        });
    };

    const readServerInfo = async (): Promise<void> => {
        try {
            const response: ServerInfoResponse = await serverController.readServerInfo();
            const recaptcha = new RecaptchaController(response.reCaptcha);
            setRecaptchaController(recaptcha);
        } catch (e: any) {
            throw e;
        }
    }

    const readServerVersion = async (): Promise<void> => {
        try {
            const response: ServerVersionResponse = await serverController.readServerVersion();
            setCopyright({
                copyright: response.copyright
            });
        } catch (e: any) {
            throw e;
        }
    }

    const initialize = async (): Promise<void> => {
        let promises: Promise<void>[] = [
            initializeCurrentUser(),
            readServerInfo(),
            readServerVersion(),
            readDeviceUserList()
        ];
        await Promise.all(promises);
    }

    const [initializePromise] = React.useState<Promise<void>>(initialize);

    return (
        <HelmetProvider>
            <ThemeProvider key="theme-provider-default" theme={theme}>
                <CssBaseline />
                <div
                    key="app-root"
                    className={classes.root}
                >
                    <FatalErrorContext.Provider value={{
                        serverError: serverError,
                        setServerError: handleServerError,
                        handleHttpError: handleHttpError
                    }}>
                        <TranslationsProvider
                            locale={
                                currentUser ? currentUser.language : undefined
                            }>
                            <FatalErrorGuard acknowledgeInternalServerError={() => { window.location.reload(); }}>
                                <LoaderGuard
                                    loadingInitFunction={initializePromise}
                                >
                                    <RecaptchaContext.Provider value={recaptchaController}
                                    >
                                        <CurrentUserContext.Provider
                                            value={
                                                {
                                                    user: currentUser,
                                                    setUser: setCurrentUser
                                                }
                                            }
                                        >
                                            <ServerVersionContext.Provider
                                                value={
                                                    copyright
                                                }
                                            >
                                                <PinCodeAttemptsContext.Provider
                                                    value={
                                                        {
                                                            pinCodeAttemptsInfo: remainingAttemptsInfo,
                                                            setRemainingAttempts: setRemainingAttemptsInfo
                                                        }
                                                    }
                                                >
                                                    <DeviceUserListContext.Provider
                                                        value={
                                                            {
                                                                deviceUserList: deviceUserList,
                                                                updateDeviceUserList: readDeviceUserList,
                                                                removeDeviceUserFromList: removeDeviceUserFromList
                                                            }
                                                        }
                                                    >
                                                        <Routes
                                                            key="router"
                                                        />
                                                        <NetworkProblemSnackbar />
                                                    </DeviceUserListContext.Provider>
                                                </PinCodeAttemptsContext.Provider>
                                            </ServerVersionContext.Provider>
                                        </CurrentUserContext.Provider>
                                    </RecaptchaContext.Provider>
                                </LoaderGuard>
                            </FatalErrorGuard>
                        </TranslationsProvider>
                    </FatalErrorContext.Provider>
                </div>
            </ThemeProvider>
        </HelmetProvider>
    );
}

export const App = () => (
    <SnackbarProvider>
        <AppWithSnackbars />
    </SnackbarProvider>
);
