import { Snackbar as MUISnackbar } from "@mui/material"
import { styled } from "@mui/material/styles";

export const SnackbarStyle = styled(MUISnackbar)(() => {
    return {
        ".MuiSnackbarContent-root": {
            flexDirection: "row",
        },
    }
})

export const SnackbarLongStyle = styled(MUISnackbar)(() => {
    return {
        ".MuiSnackbarContent-root": {
            flexDirection: "column",
            "@media(min-width: 600px)": {
                flexDirection: "row",
            }
        },
        ".MuiSnackbarContent-message": {
            marginBottom: "10px",
            alignSelf: "stretch",
            "@media(min-width: 600px)": {
                marginBottom: "14px",
            }
        },
    }
})
